import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { Features } from '../features.enum';
import { initialJobApplyPageState } from '../states';
import { JobApplyPageActions } from '../actions';


export const jobApplyPageReducer = createReducer(
  initialJobApplyPageState,
  immerOn(JobApplyPageActions.jobApplyPageInit, (state) => {
    state.pageActive = true;
  }),
  immerOn(JobApplyPageActions.getVacancyById, (state) => {
    state.loading.vacancy = true;
  }),
  immerOn(JobApplyPageActions.getVacancyByIdSuccess, (state, payload) => {
    state.vacancy = payload;
  }),
  immerOn(JobApplyPageActions.getVacancyByIdError, (state) => {
    state.loading.vacancy = false;
  }),
  immerOn(JobApplyPageActions.getFormByIdSuccess, (state, payload) => {
    state.form = payload;
    state.loading.vacancy = false;
    state.loading.survey = true;
  }),
  immerOn(JobApplyPageActions.getFormByIdError, (state) => {
    state.loading.vacancy = false;
  }),
  immerOn(JobApplyPageActions.getSurveyByIdSuccess, (state, payload) => {
    state.survey = payload;
    state.loading.survey = false;
  }),
  immerOn(JobApplyPageActions.getSurveyByIdError, (state) => {
    state.loading.survey = false;
  }),

  immerOn(JobApplyPageActions.submitForm, (state) => {
    state.loading.submit = true;
  }),
  immerOn(JobApplyPageActions.submitFormSuccess, (state) => {
    state.loading.submit = false;
  }),
  immerOn(JobApplyPageActions.getSurveyByIdError, (state) => {
    state.loading.submit = false;
  }),
  immerOn(JobApplyPageActions.jobApplyPageDestroy, (state) => {
    state.pageActive = false;
  }),
);

export const jobApplyPageFeature = createFeature({
  name: Features.JobApply,
  reducer: jobApplyPageReducer,
});
