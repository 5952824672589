import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
} from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { ImageModule } from 'primeng/image';

import { NewsFeedDialogComponent } from '../news-feed-dialog';
import { NewsFeed } from '@shared/interfaces';

@Component({
  selector: 'app-news-feed-card',
  standalone: true,
  imports: [
    NgClass,
    AvatarModule,
    ImageModule,
    NewsFeedDialogComponent,
    NgTemplateOutlet,
  ],
  templateUrl: './news-feed-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsFeedCardComponent {
  data = input.required<NewsFeed>();
  isDialogVisible = signal(false);

  changeDialogVisibility(visible: boolean) {
    this.isDialogVisible.set(visible);
  }
}
