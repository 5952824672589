<div class="grid container">
  <div class="lg:col-4 md:col-5 col-12">
    @if (currentUser()) {
      <app-profile-card/>
    }
    <div [class.mt-3]="currentUser()">
      <app-job-openings-column/>
    </div>
  </div>
  <div class="lg:col-8 md:col-7 col-12 mb-2">
    <app-news-feed/>
  </div>
</div>
