import { ChangeDetectionStrategy, Component, effect, inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SharedModule } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import dayjs from 'dayjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { JobApplyPageActions } from '@store/actions';
import { dataListFeature, jobApplyPageFeature } from '@store/features';

import { ApplicationFormFieldEnum, ApplicationFormSectionEnum, JobListTypeEnum, SalaryType } from '@shared/enums';
import { ApplicationForm, ApplicationFormResponse, Attachment, SelectOption } from '@shared/interfaces';
import {
  AddressComponent,
  CertificatesComponent,
  EducationComponent,
  LanguagesComponent,
  MainFieldsSectionComponent,
  SurveyComponent,
  WorkExperienceComponent,
} from '../../components';
import { Settings } from '@shared/constants';

@Component({
  standalone: true,
  selector: 'app-job-apply',
  templateUrl: './job-apply.page.html',
  styleUrls: ['./job-apply.page.scss'],
  imports: [
    BreadcrumbModule,
    SharedModule,
    TranslateModule,
    NgClass,
    SkeletonModule,
    AsyncPipe,
    MainFieldsSectionComponent,
    AddressComponent,
    WorkExperienceComponent,
    EducationComponent,
    CertificatesComponent,
    LanguagesComponent,
    SurveyComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobApplyPageComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly formBuilder = inject(UntypedFormBuilder);

  breadcrumbs = [
    {label: 'JobOpeningApplyPage.navigation.home', route: '/'},
    {label: 'JobOpeningApplyPage.navigation.jobOpenings', route: '/job-openings'},
    {label: 'name'},
    {label: 'JobOpeningApplyPage.navigation.job_application'},
  ];
  id = this.route.params.pipe(map(params => params['id']));
  vm = this.store.selectSignal(jobApplyPageFeature.selectJobApplyState);
  form = this.store.selectSignal(jobApplyPageFeature.selectForm);
  options = this.store.selectSignal(dataListFeature.selectDataListState);
  dateFormat = Settings.DATE_FORMAT;
  mainForm: UntypedFormGroup = this.formBuilder.group({});
  sectionType = ApplicationFormSectionEnum;
  submitted = false;
  attachmentLoading = false;
  formTemplate: ApplicationForm|undefined;

  setFormEffect = effect(() => {
    if (this.form()) {
      this.setForm(this.form()!);
    }
  });
  private jobListType: JobListTypeEnum = JobListTypeEnum.All;

  ngOnInit() {
    this.id.subscribe(id => this.store.dispatch(JobApplyPageActions.jobApplyPageInit({id})));
    this.setParentBreadcrumb();
  }

  ngOnDestroy() {
    this.store.dispatch(JobApplyPageActions.jobApplyPageDestroy());
  }

  private setParentBreadcrumb() {
    this.jobListType = this.route.snapshot.data['jobListType'];
    this.breadcrumbs[1] = {label: `JobOpeningsPage.navigation.${this.jobListType}`, route: `/${this.jobListType}`};
  }

  private setForm(formTemplate: ApplicationForm) {
    this.formTemplate = this.filterFormTemplate(formTemplate);
    this.formTemplate.sectionSettings.forEach(section => {
      const isGroup = section.type === ApplicationFormSectionEnum.Education
        || section.type === ApplicationFormSectionEnum.WorkExperience
        || section.type === ApplicationFormSectionEnum.Languages
        || section.type === ApplicationFormSectionEnum.Certificates;
      this.mainForm.addControl(section.id.toString(), isGroup ? this.formBuilder.array([]) : this.formBuilder.group({}));
    });
    if (formTemplate.surveyId) {
      this.mainForm.addControl('survey', this.formBuilder.group({}));
    }
  }

  private filterFormTemplate(form: ApplicationForm): ApplicationForm {
    const filteredSectionSettings = form.sectionSettings.map(section => ({
      ...section,
      fieldSettings: section.fieldSettings.filter(field => field.isShow),
    })).filter(section => section.isEnabled && section.fieldSettings.length);
    return {...form, sectionSettings: filteredSectionSettings};
  }

  private getApplicationModel(): ApplicationFormResponse {
    const model: ApplicationFormResponse = {
      applicationFormId: this.vm().form!.id,
      jobOpeningId: this.vm().vacancy!.id,
      applicationFormFields: [],
      surveyResponse: {
        surveyId: this.vm().form!.surveyId!,
        userValues: [],
      },
    };
    if (!this.vm().form!.surveyId) {
      delete model.surveyResponse;
    }
    let groupId = 1;
    for (const [key, value] of Object.entries(this.mainForm.getRawValue())) {
      if (key === 'survey') {
        for (const [itemId, itemValue] of Object.entries(value!)) {
          if (itemValue) {
            if (Array.isArray(itemValue)) {
              itemValue.forEach((val) => {
                if (val) {
                  model.surveyResponse!.userValues!.push({itemId: +itemId, value: this.toJsonIfValueIsNotString(val)});
                }
              });
            } else {
              model.surveyResponse!.userValues!.push({
                itemId: +itemId,
                value: this.toJsonIfValueIsNotString(itemValue),
              });
            }
          }
        }
      } else {
        if (Array.isArray(value)) {
          value.forEach((group) => {
            for (const [fieldType, fieldValue] of Object.entries(group)) {
              switch (fieldType) {
                case 'dateGroup':
                  for (const [dateType, dateValue] of Object.entries(fieldValue!)) {
                    if (dateValue) {
                      model.applicationFormFields.push({
                        type: +dateType,
                        value: dayjs(dateValue as string).format(Settings.DAY_JS_FORMAT),
                        groupId,
                        isSystem: true,
                      });
                    }
                  }
                  break;
                case ApplicationFormFieldEnum.WorkExperienceCountry.toString():
                case ApplicationFormFieldEnum.EducationCountry.toString():
                case ApplicationFormFieldEnum.Degree.toString():
                case ApplicationFormFieldEnum.LanguageLanguage.toString():
                case ApplicationFormFieldEnum.LanguageLevel.toString():
                case ApplicationFormFieldEnum.LanguageWriting.toString():
                case ApplicationFormFieldEnum.LanguageReading.toString():
                case ApplicationFormFieldEnum.LanguageSpeaking.toString():
                case ApplicationFormFieldEnum.LanguageListening.toString():
                  const selectedOptions = fieldValue as SelectOption;
                  if (selectedOptions && selectedOptions.value) {
                    model.applicationFormFields.push({
                      type: +fieldType,
                      value: this.toJsonIfValueIsNotString(selectedOptions.value),
                      groupId,
                      isSystem: true,
                    });
                  }
                  break;
                case ApplicationFormFieldEnum.CertificateAttachments.toString():
                  const attachments = fieldValue as any[];
                  if (attachments && attachments.length) {
                    const attachmentIds = attachments.map((attachment) => attachment.id);
                    model.applicationFormFields.push({
                      type: +fieldType,
                      value: this.toJsonIfValueIsNotString(attachmentIds),
                      groupId,
                      isSystem: true,
                    });
                  }
                  break;
                default:
                  if (fieldValue) {
                    model.applicationFormFields.push({
                      type: +fieldType,
                      value: this.toJsonIfValueIsNotString(fieldValue),
                      groupId,
                      isSystem: true,
                    });
                  }
              }
            }
            groupId++;
          });
        } else {
          for (const [fieldType, fieldValue] of Object.entries(value!)) {
            switch (fieldType) {
              case 'dateGroup':
                for (const [dateType, dateValue] of Object.entries(fieldValue!)) {
                  if (dateValue) {
                    model.applicationFormFields.push({
                      type: +dateType,
                      value: this.toJsonIfValueIsNotString(dateValue),
                      isSystem: true,
                    });
                  }
                }
                break;
              case ApplicationFormFieldEnum.CV.toString():
                if (fieldValue && fieldValue.length) {
                  const cvId = fieldValue[0].id;
                  model.applicationFormFields.push({
                    type: +fieldType,
                    value: this.toJsonIfValueIsNotString(cvId),
                    isSystem: true,
                  });
                }
                break;
              case ApplicationFormFieldEnum.Phone.toString():
                const phoneValue = fieldValue as {countryCode: string, phone: string};
                if (phoneValue) {
                  model.applicationFormFields.push({
                    type: +fieldType,
                    value: phoneValue.countryCode + phoneValue.phone,
                    isSystem: true,
                  });
                }
                break;
              case ApplicationFormFieldEnum.DateOfBirth.toString():
                if (fieldValue) {
                  model.applicationFormFields.push({
                    type: +fieldType,
                    value: dayjs(fieldValue as string).format(Settings.DAY_JS_FORMAT),
                    isSystem: true,
                  });
                }
                break;
              case ApplicationFormFieldEnum.Attachments.toString():
                if (fieldValue && fieldValue.length) {
                  const attachmentIds = fieldValue.map((attachment: Attachment) => attachment.id);
                  model.applicationFormFields.push({
                    type: +fieldType,
                    value: this.toJsonIfValueIsNotString(attachmentIds),
                    isSystem: true,
                  });
                }
                break;
              case ApplicationFormFieldEnum.Salary.toString():
                if (fieldValue) {
                  const value = fieldValue.salaryTypeId === SalaryType.Fixed ?
                    {
                      salaryTypeId: SalaryType.Fixed,
                      fixed: {value: fieldValue.value, currencyId: fieldValue.currencyId.value},
                    } : fieldValue;
                  model.applicationFormFields.push({
                    type: +fieldType,
                    value: this.toJsonIfValueIsNotString(value),
                    isSystem: true,
                  });
                }
                break;
              case ApplicationFormFieldEnum.Gender.toString():
              case ApplicationFormFieldEnum.Source.toString():
              case ApplicationFormFieldEnum.AddressCountry.toString():
                if (fieldValue) {
                  model.applicationFormFields.push({
                    type: +fieldType,
                    value: this.toJsonIfValueIsNotString(fieldValue.value),
                    isSystem: true,
                  });
                }
                break;
              default:
                if (fieldValue) {
                  const allFields = this.formTemplate!.sectionSettings.map(section => section.fieldSettings).flat();
                  const isCustom = allFields.find(field => field.name === fieldType)?.type === ApplicationFormFieldEnum.CustomAttachment;
                  if (isCustom) {
                    const attachmentId = fieldValue[0].id;
                    model.applicationFormFields.push({
                      type: ApplicationFormFieldEnum.CustomAttachment,
                      value: attachmentId,
                      isSystem: false,
                      name: fieldType,
                      groupId: ApplicationFormFieldEnum.CustomAttachment,
                    });
                  } else {
                    model.applicationFormFields.push({
                      type: +fieldType,
                      value: this.toJsonIfValueIsNotString(fieldValue),
                      isSystem: true,
                    });
                  }
                }
            }
          }
        }
      }
    }
    return model;
  }

  private toJsonIfValueIsNotString(value: any) {
    return typeof value === 'string' ? value : JSON.stringify(value);
  }

  onApply() {
    this.submitted = true;
    if (this.mainForm.valid) {
      const model = this.getApplicationModel();
      this.store.dispatch(JobApplyPageActions.submitForm({values: model}));
    }
  }
}
