import { AbstractControl } from '@angular/forms';

import { ValidatorErrorMessage } from '@shared/interfaces';
import { validatorErrorMessage } from '@shared/functions';

export class RequiredValidator {
  public static validate(c: AbstractControl): ValidatorErrorMessage|null {
    return RequiredValidator.isEmpty(c.value) ?
      validatorErrorMessage('required') : null;
  }

  private static isEmpty(value: unknown): boolean {
    return value === undefined || value === null ||
      (typeof value === 'string' && value.trim().length === 0) || (Array.isArray(value) && value.length === 0);
  }
}
