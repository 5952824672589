import { UntypedFormControl, AbstractControl } from '@angular/forms';

export class BirthdayValidator {
  public static futureDateValidate(c: UntypedFormControl): { [key: string]: boolean } | null {
    const dob = c.value;

    if (dob) {
      const nowVal = new Date().setHours(0, 0, 0, 0).valueOf();
      const dobVal = new Date(dob).valueOf();

      return dobVal > nowVal ? { 'date': true } : null;
    }
    return null;
  }

  public static adulthoodValidate(c: UntypedFormControl | AbstractControl): { [key: string]: boolean } | null {
    const adulthoodAge = 16;
    const date = c.value;

    if (date) {
      const birthday = new Date(date);
      const today = new Date();
      let years = today.getFullYear() - birthday.getFullYear();

      // Reset birthday to the current year.
      birthday.setFullYear(today.getFullYear());

      // If the user's birthday has not occurred yet this year, subtract 1.
      if (today < birthday) {
        years--;
      }

      return years < adulthoodAge ? { 'age': true } : null;
    }
    return null;
  }
}
