@if (loginDialogVisible()) {
  <p-dialog header="Social Login"
            [draggable]="false"
            (onHide)="hideDialog()"
            [(visible)]="dialogVisible"
            [style]="{width: '600px'}"
            styleClass="shared-dialog"
            [modal]="true"
            [resizable]="false">
    <div class="access-card">
      <div class="flex flex-column justify-content-center align-items-center">
        @if (companyTheme()?.logoUrl) {
          <p-image [src]="companyTheme()?.logoUrl" alt="Company logo" width="139"/>
        } @else {
          @if (!loading()) {
            <p-image
              src="assets/images/raiser-blue.svg"
              alt="Company logo"
              width="67"
            />
          }
        }
        <p class="text-center font-semibold text-xl line-height-2 mt-3 mb-5">
          {{ "SocialLogin.header" | translate }}
        </p>
      </div>

      @for (provider of socialProviders; track provider) {
        <div
          class="flex align-items-center mb-3 px-3 border-round-lg bg-blue-50 access-card-row"
        >
          <div
            class="surface-card shadow-2 flex justify-content-center align-items-center access-card-img"
          >
            <img
              alt="Google"
              ngSrc="assets/images/{{ provider }}.svg"
              width="25"
              height="25"
            />
          </div>
          <p class="pl-3 font-medium text-base">
            {{ "SocialLogin." + provider | translate }}
          </p>

          <button
            (click)="onLogin(provider)"
            class="border-none surface-card shadow-2 hover:shadow-4 flex justify-content-center align-items-center ml-auto cursor-pointer"
          >
            <span class="icon icon-forward text-lg"></span>
          </button>
        </div>
      }
    </div>
  </p-dialog>
}

