import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FileHolder } from '../file-holder';
import { FileSizePipe } from '@shared/pipes';

@Component({
  standalone: true,
  selector: 'app-file-list-item',
  templateUrl: './file-list-item.component.html',
  styleUrls: ['./file-list-item.component.scss'],
  imports: [FileSizePipe, TranslateModule],
})
export class FileListItemComponent implements OnInit {
  @Input() isAllFilesMoreThanMaxTotalSize = false;
  @Input() showDeleteButton = true;
  @Input() file!: FileHolder;
  @Input() maxFileSize!: number;
  @Input() fileFormats: string[] = [];
  @Input() requiredExtensions: string[] = [];
  @Input() fileTooLargeMessage!: string;
  @Input() incorrectFormatMessage!: string;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() checkByExtension = false;
  @Output() removeButtonClick: EventEmitter<FileHolder> = new EventEmitter<FileHolder>();

  wrongFormat1: string|null = null;
  wrongFormat2: string|null = null;

  ngOnInit() {
    if (this.requiredExtensions && this.requiredExtensions.length) {
      if (this.requiredExtensions.length === 1) {
        this.wrongFormat1 = this.requiredExtensions[0];
      } else {
        const extensions = [...this.requiredExtensions];
        this.wrongFormat2 = extensions.pop()!;
        this.wrongFormat1 = extensions.join(', ');
      }
    }
  }

  deleteFile() {
    this.removeButtonClick.emit(this.file);
  }

  get isWrongFormatFile(): boolean {
    if (this.fileFormats && !!this.fileFormats.length) {
      if (this.checkByExtension) {
        return this.requiredExtensions && this.requiredExtensions.every(ext => !this.file.file.name.includes(ext));
      }
      return !this.fileFormats.includes(this.file.file.type);
    }
    return false;
  }

  get isMoreThanMaxFileSize(): boolean {
    return this.file.file.size > this.maxFileSize * 1024 * 1024;
  }
}
