import { Injectable } from '@angular/core';
import { Auth } from "@angular/fire/auth";
import { Observable, from } from "rxjs";
import { UserCredential, signInWithCustomToken } from "@firebase/auth";

import { ISocialAuthProvider } from "@shared/interfaces";
import { SocialProviderTypeEnum } from "@shared/enums";

@Injectable({
  providedIn: 'root'
})
export class LinkedinAuthService implements ISocialAuthProvider {
  readonly type = SocialProviderTypeEnum.Linkedin;

  constructor(private firebaseAuth: Auth) { }

  signIn(): Observable<UserCredential> {
    const promise = signInWithCustomToken(this.firebaseAuth, 'CUSTOM_LINKEDIN_TOKEN');

    return from(promise);
  }

  signOut() {
    this.firebaseAuth.signOut();
  }
}
