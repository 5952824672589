export const Settings = {
  documentFormats: ['application/pdf', 'image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/tiff',
    'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  documentExtensions: ['.pdf', '.jpeg', '.png', '.tiff', '.gif', '.bmp', '.doc', '.docx', '.xls', 'xlsx', '.ppt', '.pptx'],
  previewFormats: ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/tiff', 'application/pdf'],
  DATE_FORMAT: 'MM/dd/yyyy',
  DAY_JS_FORMAT: 'YYYY-MM-DD',
};
