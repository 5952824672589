<div class="mb-4">
  @if (loading().vacancy) {
    <div class="container">
      <div class="border-round-xl surface-border p-4 surface-card flex justify-content-between">
        <div>
          <div class="flex">
            <div class="mr-3">
              <p-skeleton width="5rem" height="5rem" borderRadius="8px"/>
            </div>
            <div class="flex flex-column justify-content-between p-1">
              <p-skeleton width="10rem" height="1rem" borderRadius="8px"/>
              <p-skeleton width="10rem" height="1rem" borderRadius="8px"/>
              <p-skeleton width="10rem" height="1rem" borderRadius="8px"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  } @else if (vacancyDetail()!) {
    @if ((vacancyDetail()!.fields); as fields) {
      <div class="vacancy-details top-fixed-part" [class.shadow]="setTopBlockShadow">
        <div class="breadcrumb-section p-4">
          <div class="container">
            <p-breadcrumb class="max-w-full" [model]="breadcrumbs">
              <ng-template pTemplate="item" let-item>
                @if (item.route) {
                  <a [routerLink]="item.route" class="p-menuitem-link no-underline">
                    <span [ngClass]="[item.icon ? item.icon : '', 'light-gray-text']"></span>
                    <span class="text-sm light-gray-text">{{ item.label | translate }}</span>
                  </a>
                } @else {
                  <span class="light-black-text text-sm font-medium">
              @if (item.label === 'name') {
                {{ vacancyDetail()!.name }}
              } @else {
                {{ item.label | translate }}
              }
            </span>
                }
              </ng-template>
              <ng-template pTemplate="separator">
                <span class="icon icon-forward"></span>
              </ng-template>
            </p-breadcrumb>
          </div>
        </div>
        <div class="container flex justify-content-between pt-3 align-items-center top-part">
          <div class="col-10 p-0">
            <h1 class="text-4x light-black-text font-semibold capitalize name">{{ vacancyDetail()!.name }}</h1>
            <div class="flex align-items-center mt-2">
              <div class="text-sm light-gray-text pt-2">
                @if (vacancyDetail()!.daysPosted) {
                  {{ "PUBLIC_VACANCIES.labels.posted_date"| translate : {days: vacancyDetail()!.daysPosted} }}
                }
              </div>
              @if ((fields[fieldsEnum.ExpirationDate] && fields[fieldsEnum.OpeningDate])) {
                <span class="dot m-2 mb-0"></span>
              }
              @if (fields[fieldsEnum.ExpirationDate]; as expirationDate) {
                <div class="text-sm light-gray-text pt-2">
                  <span>{{ 'JobOpeningsPage.labels.active_till' | translate }}&nbsp;</span>
                  <span>{{ expirationDate | date: dateFormat }} </span>
                </div>
              }
            </div>
          </div>
          <div class="col-2 align-self-baseline">
            <ul class="vacancy-icons-wrapper flex align-items-center justify-content-end p-0 m-0">
              <li>
                @if (currentUser() && !vacancyDetail()!.isApplied) {
                  <a class="vacancy-icon m-2" (click)="onChangeFollowing(vacancyDetail()!.isFollowing)">
                    <span [ngClass]="vacancyDetail()!.isFollowing ? 'icon-mark-filled' : 'icon-mark'" class="icon">
                    </span>
                  </a>
                }
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container justify-content-between flex mt-4">
        <div class="col-9 pl-0 left-part">
          @if (fields[fieldsEnum.JODescription]; as description) {
            <div [innerHtml]="description | sanitize"></div>
          }
          @if ((fields[fieldsEnum.Skills] | jsonParse); as skills) {
            <div class="description-title font-semibold text-xl light-black-text pt-5 pb-3">
              {{ 'JobOpeningsPage.labels.skills' | translate }}
            </div>
            <div class="chips flex flex-wrap">
              @for (skill of skills; track skill) {
                <div class="chip mr-2 mt-2 capitalize font-medium">{{ skill.name }}</div>
              }
            </div>
          }
          @if (fields[fieldsEnum.Project]) {
            <div> {{ 'JobOpeningsPage.labels.project' | translate }}:</div>
            {{ fields[fieldsEnum.Project] }}
          }
          @if ((fields[fieldsEnum.Attachments] | jsonParse); as attachments) {
            @if (attachments.length) {
              <div class="description-title font-semibold text-xl light-black-text pt-5 pb-3">
                {{ 'JobOpeningsPage.labels.attachments' | translate }}
              </div>
              <div class="flex attachments-wrapper">
                @for (attachment of attachments; track attachment) {
                  <button class="button-attachment mr-4 flex" (click)="onAttachmentClick(attachment)">
                    <span class="icon icon-file mr-2"></span>
                    {{ attachment.userFriendlyFileName }}{{ attachment.extension }}
                  </button>
                }
              </div>
            }
          }
          <div class="organization-info-wrapper mt-7">
            @if (companyInfo()) {
              <div class="flex mb-2 flex-column mt-3">
                <div class="light-black-text  capitalize font-medium">{{ companyInfo()!.name }}</div>
                @if (companyInfo()!.headCount) {
                  <span class="font-base bold-gray-text">
                    {{ 'Enum_headcount.' + companyInfo()!.headCount | translate }} {{ 'Labels.employees' | translate }}
                  </span>
                }
                @if (companyInfo()!.description) {
                  <p class="org-description font-base bold-gray-text line-height-3 mt-4">
                    {{ companyInfo()!.description }}
                  </p>
                }
              </div>
              <div class="flex align-items-center org-info-sec mt-3">
                @if (companyInfo()!.address.fullAddress) {
                  <div class="flex align-items-center mr-4">
                    <span class="icon icon-location mr-2"></span>
                    <span class="font-base bold-gray-text">{{ companyInfo()!.address!.fullAddress }}</span>
                  </div>
                }
                @if (companyInfo()!.telephone) {
                  <div class="flex align-items-center mr-4">
                    <span class="icon icon-phone mr-2"></span>
                    <span class="font-base bold-gray-text">+{{ companyInfo()!.telephone }}</span>
                  </div>
                }
                @if (companyInfo()!.webSite) {
                  <div class="flex align-items-center">
                    <span class="icon icon-web mr-2"></span>
                    <a class="no-underline relative" [href]="companyInfo()!.webSite" target="_blank"><span
                      class="font-base bold-gray-text">{{ companyInfo()!.webSite }}</span></a>
                  </div>
                }
              </div>
            }
          </div>
        </div>
        <div class="col-3 right-part">
          @if (
            fields[fieldsEnum.ProfessionalLevel] ||
            fields[fieldsEnum.EmploymentType] ||
            fields[fieldsEnum.ContractType] ||
            (fields[fieldsEnum.SalaryType]) ||
            (fields[fieldsEnum.Location]) ||
            fields[fieldsEnum.WorkType]
            ) {
            <div class="info-card">
              <div class="info-card-wrapper">
                <div class="info-card-title">{{'Labels.job_details' | translate}}</div>
                @if (fields[fieldsEnum.ProfessionalLevel]; as level) {
                  <div class="info-card-item flex align-items-center">
                    <span class="icon icon-rocket mr-2"></span>
                    <span class="light-black-text font-medium">{{ level }}</span>
                  </div>
                }
                @if ((fields[fieldsEnum.SalaryType] | jsonParse); as salary) {
                  @if (salary.salaryTypeId !== salaryTypeEnum.None) {
                    <div class="info-card-item">
                      <span class="icon icon-dollar mr-2"></span>
                      <span class="light-black-text font-medium">
                        @switch (salary.salaryTypeId) {
                          @case (salaryTypeEnum.Negotiable) {
                            {{ "Enum_Translations.salaryType.negotiable" | translate }}
                          }
                          @case (salaryTypeEnum.Range) {
                            {{ salary.currency.currencySymbol }}{{ salary.salaryFrom }} - {{ salary.currency.currencySymbol }}{{ salary.salaryTo }}
                          }
                          @case (salaryTypeEnum.Fixed) {
                            {{ salary.currency.currencySymbol }}{{ salary.fixedSalary }}
                          }
                        }
                      </span>
                    </div>
                  }
                }
                @if (fields[fieldsEnum.EmploymentType]; as employmentType) {
                  <div class="info-card-item flex align-items-center">
                    <span class="icon icon-clock mr-2"></span>
                    <span class="light-black-text font-medium">{{ employmentType }}</span>
                  </div>
                }
                @if (fields[fieldsEnum.ContractType]; as contractType) {
                  <div class="info-card-item flex align-items-center">
                    <span class="icon icon-job mr-2"></span>
                    <span class="light-black-text font-medium">{{ contractType }}</span>
                  </div>
                }
                @if (fields[fieldsEnum.WorkType]; as workType) {
                  <div class="info-card-item flex align-items-center">
                    <span class="icon icon-laptop mr-2"></span>
                    <span class="light-black-text font-medium">{{ workType }}</span>
                  </div>
                }
                @if (fields[fieldsEnum.Location]; as location) {
                  <div class="info-card-item flex align-items-center mb-2">
                    <span class="icon icon-location mr-2"></span>
                    <span class="light-black-text font-medium">{{ location }}</span>
                  </div>
                }
              </div>
            </div>
          }
          @if (!vacancyDetail()!.isApplied) {
            <button (click)="onApplyClick()" class="button-primary apply-btn text-base mt-3 mb-4">
              {{ 'JobOpeningsPage.labels.apply_for_this_job' | translate }}
            </button>
          }
          <div class="info-card">
            <div>
              @if (!vacancyDetail()!.isApplied) {
                <div class="mb-3 bordered-text text-center relative inline-block gray-text font-normal">
                  <span>{{ 'Labels.tell_your_friends' | translate }}</span>
                </div>
              }
              <div class="share-copy-section flex align-items-center justify-content-between mt-3">
                 <button class="button-visit-web flex copy-btn mr-2"
                         tooltipPosition="bottom"
                         [pTooltip]="'Labels.copy' | translate"
                         (click)="copyLinkToClipboard()">
                  <span [class]="showCopiedIcon ? 'icon icon-checked' :'icon icon-copy-link'" class="m-auto"></span>
                </button>
                <button class="button-visit-web flex fb-btn mr-2"
                        (click)="onShareToSocialPlatform(socialPlatform.Facebook)">
                  <span class="icon icon-Facebook m-auto"></span>
                </button>
                <button class="button-visit-web flex linkedin-btn"
                        (click)="onShareToSocialPlatform(socialPlatform.LinkedIn)">
                  <span class="icon icon-Linkedin m-auto"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  }
</div>
