import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { Features } from '../features.enum';
import { JobDetailsPageActions } from '../actions';
import { initialJobDetailsPageState } from '../states';
import { PublicSettingsFieldsEnum } from '@shared/enums';

export const jobDetailPageReducer = createReducer(
  initialJobDetailsPageState,
  immerOn(JobDetailsPageActions.getVacancyById, (state) => {
    state.loading.vacancy = true;
  }),
  immerOn(JobDetailsPageActions.getVacancyByIdSuccess, (state, payload) => {
    if (payload) {
      const openingDate = payload.fields[PublicSettingsFieldsEnum.OpeningDate];
      const daysPosted = openingDate ? Math.floor((new Date().getTime() - new Date(openingDate).getTime()) / (1000 * 60 * 60 * 24))
        : null;
      state.vacancy = {...payload, daysPosted};
    } else {
      state.vacancy = null;
    }
    state.loading.vacancy = false;
  }),
  immerOn(JobDetailsPageActions.getVacancyByIdError, (state) => {
    state.loading.vacancy = false;
  }),
  immerOn(
    JobDetailsPageActions.vacancyFollowSuccess,
    JobDetailsPageActions.vacancyUnFollowSuccess,
    (state) => {
      state.vacancy!.isFollowing = !state.vacancy!.isFollowing;
    },
  ),
  immerOn(JobDetailsPageActions.jobDetailsPageDestroy, (state) => {
    state.vacancy = null;
  }),
);

export const jobDetailPageFeature = createFeature({
  name: Features.JobDetailPage,
  reducer: jobDetailPageReducer,
});
