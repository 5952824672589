<div class="border-round-sm surface-0 border-round-xl mb-2 p-4 shadow-3">
  <div class="flex">
    <p-avatar
      [image]="data().companyLogo"
      styleClass="mr-2 w-3rem h-3rem"
      shape="circle"
    />
    <div>
      <span class="text-sm font-semibold block">{{ data().title }}</span>
      <span class="font-normal text-xs opacity-70 block">{{
        data().companyInfo
      }}</span>
    </div>
  </div>
  @for(section of data().sections; track section) {
  <p class="text-sm font-normal">
    - {{ section.title }}
    <br />
    @for(tag of section.tags; track tag) {
    <span class="primary-blue-text text-sm">#{{ tag + " " }}</span>
    }
  </p>
  } @if(data().images.length){

  <div class="grid nested-grid">
    <div
      class="col-6"
      [ngClass]="data().images.length > 1 ? 'col-6' : 'col-12'"
    >
      <ng-container
        [ngTemplateOutlet]="imageTemplate"
        [ngTemplateOutletContext]="{ src: data().images[0] }"
      ></ng-container>
    </div>
    @if (data().images.length > 1) {
    <div class="col-6">
      <div class="grid">
        <div class="col-12">
          <ng-container
            [ngTemplateOutlet]="imageTemplate"
            [ngTemplateOutletContext]="{ src: data().images[1] }"
          ></ng-container>
        </div>
        @if (data().images.length > 2) {
        <div class="col-12">
          <ng-container
            [ngTemplateOutlet]="imageTemplate"
            [ngTemplateOutletContext]="{ src: data().images[2] }"
          ></ng-container>

          @if(data().images.length > 3) {
          <div>+{{ data().images.length - 3 }}</div>
          }
        </div>
        }
      </div>
    </div>
    }
  </div>
  }
  <div>
    <div
      class="flex align-items-center border-x-none border-bottom-none border-top-2 border-solid border-200 mt-4 pt-3"
    >
      <div class="flex align-items-center mr-5">
        <span class="mr-1"><span class="icon icon-like blue-text"></span></span>
        <span class="text-xs font-medium text-500">{{
          data().likedCount
        }}</span>
      </div>
      <div class="flex align-items-center">
        <span class="mr-1"><span class="icon icon-send"></span></span>
        <span class="text-xs font-medium text-500">{{
          data().viewsCount
        }}</span>
      </div>
    </div>
  </div>
</div>

@if(isDialogVisible()){
<app-news-feed-dialog
  [images]="data().images"
  [visible]="isDialogVisible()"
  (onHideDialog)="changeDialogVisibility(false)"
/>
}

<ng-template #imageTemplate let-data="src">
  <p-image
    [src]="data"
    alt="Image"
    width="100%"
    height="100%"
    (click)="changeDialogVisibility(true)"
    class="news-feed-img-container"
  />
</ng-template>
