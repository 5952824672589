import { Component, SimpleChanges, OnChanges, inject, input } from '@angular/core';
import { DatePipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';

import { PrimeDateFormatPipe } from '@shared/pipes';
import { ApplicationFormSectionSetting, SelectOption } from '@shared/interfaces';
import { ApplicationFormFieldEnum } from '@shared/enums';
import { RequiredValidator } from '@shared/validators';

@Component({
  standalone: true,
  selector: 'app-education',
  templateUrl: './education.component.html',
  imports: [
    TranslateModule,
    NgIf,
    DialogModule,
    ReactiveFormsModule,
    NgClass,
    NgForOf,
    NgSwitchCase,
    NgSwitch,
    DatePipe,
    CalendarModule,
    DropdownModule,
    NgTemplateOutlet,
    PrimeDateFormatPipe,
  ],
})
export class EducationComponent implements OnChanges {
  sectionSettings = input.required<ApplicationFormSectionSetting>();
  mainForm = input.required<FormGroup>();
  countries = input.required<SelectOption[]>();
  degrees = input.required<SelectOption[]>();
  dateFormat = input.required<string>();

  private readonly formBuilder = inject(FormBuilder);

  formArray!: FormArray;
  form!: FormGroup;
  savedEducations: any[] = [];
  showAddEditDialog = false;
  submitted = false;
  selectedFormIndex!: number | null;
  isEdit = false;
  fieldType = ApplicationFormFieldEnum;
  private isSaving!: boolean;
  private originalFormValue: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mainForm']?.currentValue) {
      this.setForm();
    }
  }

  private setForm() {
    this.formArray = this.mainForm().get(this.sectionSettings().id.toString()) as FormArray;
  }

  onAddEducation() {
    this.formArray.push(this.formBuilder.group({
      dateGroup: this.formBuilder.group({}, {validator: this.validateDateGroup}),
    }));
    this.form = this.formArray.at(this.formArray.length - 1) as FormGroup;
    this.sectionSettings().fieldSettings.forEach(field => {
      switch (field.type) {
        case ApplicationFormFieldEnum.Institution:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, Validators.maxLength(100)] : [Validators.maxLength(100)]),
          );
          break;
        case ApplicationFormFieldEnum.Faculty:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, Validators.maxLength(100)] : [Validators.maxLength(100)]),
          );
          break;
        case ApplicationFormFieldEnum.Degree:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.Specialization:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, Validators.maxLength(100)] : [Validators.maxLength(100)]),
          );
          break;
        case ApplicationFormFieldEnum.EducationCountry:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.EducationCity:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, Validators.maxLength(100)] : [Validators.maxLength(100)]),
          );
          break;
        case ApplicationFormFieldEnum.EducationStartDate:
          (this.form.get('dateGroup') as FormGroup).addControl(
            field.type.toString(),
            // @ts-ignore
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, this.biggerThenCurrentDateValidation] : [this.biggerThenCurrentDateValidation]),
          );
          break;
        case ApplicationFormFieldEnum.EducationEndDate:
          (this.form.get('dateGroup') as FormGroup).addControl(
            field.type.toString(),
            // @ts-ignore
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, this.biggerThenCurrentDateValidation] : [this.biggerThenCurrentDateValidation]),
          );
          break;
      }
    });
    this.selectedFormIndex = this.formArray.length - 1;
    this.isEdit = false;
    this.showAddEditDialog = true;
  }

  onHideAddEditDialog() {
    this.showAddEditDialog = false;
    if (this.savedEducations.length !== this.formArray.length) {
      this.formArray.removeAt(this.selectedFormIndex!);
    }
    if (this.isEdit && !this.isSaving) {
      this.formArray.at(this.selectedFormIndex!).patchValue(this.originalFormValue);
    }
    this.selectedFormIndex = null;
    this.isSaving = false;
    this.isEdit = false;
    this.submitted = false;
  }

  onSaveEducation() {
    this.submitted = true;
    if (this.form.valid) {
      if (this.isEdit) {
        this.isSaving = true;
        this.savedEducations[this.selectedFormIndex!] = this.form.getRawValue();
      } else {
        this.savedEducations.push(this.form.getRawValue());
      }
      this.selectedFormIndex = null;
      this.showAddEditDialog = false;
    }
  }

  onEditEducation(index: number) {
    this.form = this.formArray.at(index) as FormGroup;
    this.originalFormValue = this.form.getRawValue();
    this.selectedFormIndex = index;
    this.isEdit = true;
    this.showAddEditDialog = true;
  }

  onDeleteEducation(index: number) {
    this.formArray.removeAt(index);
    this.savedEducations.splice(index, 1);
  }

  private validateDateGroup(formGroup: UntypedFormGroup): { [key: string]: boolean } | null {
    const start = (<UntypedFormControl>formGroup.controls[ApplicationFormFieldEnum.EducationStartDate.toString()])?.value;
    const end = (<UntypedFormControl>formGroup.controls[ApplicationFormFieldEnum.EducationEndDate.toString()])?.value;
    if (start && end) {
      const startDateVal = new Date(start).valueOf();
      const endDateVal = new Date(end).valueOf();
      if (endDateVal < startDateVal) {
        return {range: true};
      }
    }
    return null;
  }

  private biggerThenCurrentDateValidation(control: UntypedFormControl): { [key: string]: boolean } | null {
    const date = control.value;
    if (date) {
      const dateVal = new Date(date).valueOf();
      const nowVal = new Date().setHours(0, 0, 0, 0).valueOf();
      if (dateVal >= nowVal) {
        return {biggerThenCurrent: true};
      }
    }
    return null;
  }
}
