import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { Features } from '../features.enum';
import { SavedJobsPageActions } from '../actions';
import { initialSavedJobPageState } from '../states';
import { PublicSettingsFieldsEnum } from '@shared/enums';
import { ListModel } from '@shared/models';
import { Vacancy } from '@shared/interfaces';

export const savedJobPageReducer = createReducer(
  initialSavedJobPageState,
  immerOn(SavedJobsPageActions.getAllSavedVacancies, (state) => {
    state.loading = true;
  }),
  immerOn(SavedJobsPageActions.getAllSavedVacanciesSuccess, (state, payload) => {
    const mappedVacancies = payload.items.map((item) => {
      const openingDate = item.fields[PublicSettingsFieldsEnum.OpeningDate];
      const daysPosted = openingDate ? Math.floor((new Date().getTime() - new Date(openingDate).getTime()) / (1000 * 60 * 60 * 24))
        : null;
      return ({...item, daysPosted});
    });
    state.vacancyList = {...payload, items: mappedVacancies};
    state.loading = false;
  }),
  immerOn(SavedJobsPageActions.getAllSavedVacanciesError, (state) => {
      state.loading = false;
    },
  ),
  immerOn(
    SavedJobsPageActions.vacancyFollowSuccess,
    SavedJobsPageActions.vacancyUnFollowSuccess,
    (state, payload) => {
      const currentItem = state.vacancyList.items.findIndex((vacancy) => vacancy.id === payload.id);
      const currentVacancy = state.vacancyList.items[currentItem];
      if (currentVacancy.isFollowing) {
        state.vacancyList.items.splice(currentItem, 1);
      }
    },
  ),
  immerOn(SavedJobsPageActions.savedVacanciesPageDestroy, (state) => {
    state.vacancyList = ListModel.empty<Vacancy>();
  }),
);

export const savedJobsPageFeature = createFeature({
  name: Features.SavedJobs,
  reducer: savedJobPageReducer,
});
