import { ChangeDetectionStrategy, Component, Input, input, output } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { DialogModule } from 'primeng/dialog';
import { GalleriaModule } from 'primeng/galleria';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-news-feed-dialog',
  standalone: true,
  imports: [
    AvatarModule,
    DialogModule,
    GalleriaModule,
    CarouselModule,
    ButtonModule,
  ],
  templateUrl: './news-feed-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsFeedDialogComponent {
  images = input.required<string[]>();
  @Input() visible = false;
  // visible = model<boolean>(false);
  onHideDialog = output<boolean>();

  responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: '991px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  closeDialog(): void {
    this.onHideDialog.emit(false);
  }
}
