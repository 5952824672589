import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  Component,
  inject,
  input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { SkeletonModule } from 'primeng/skeleton';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { Meta } from '@angular/platform-browser';

import { AppActions, JobDetailsPageActions } from '@store/actions';
import { jobDetailPageFeature, appFeature, authFeature } from '@store/features';
import { JobListTypeEnum, PublicSettingsFieldsEnum, SalaryType, SocialPlatform } from '@shared/enums';
import { HtmlSanitizerPipe, JsonParsePipe } from '@shared/pipes';
import { copyToClipboard, throttle } from '@shared/functions';
import { Attachment } from '@shared/interfaces';

@Component({
  standalone: true,
  selector: 'app-job-details-page',
  imports: [SkeletonModule, NgClass, TranslateModule, DatePipe, HtmlSanitizerPipe, BreadcrumbModule, JsonParsePipe],
  templateUrl: './job-details.page.html',
  styleUrls: ['./job-details.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobDetailsPageComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly cd = inject(ChangeDetectorRef);
  private readonly meta = inject(Meta);
  readonly fieldsEnum = PublicSettingsFieldsEnum;
  readonly salaryTypeEnum = SalaryType;
  readonly socialPlatform = SocialPlatform;
  dateFormat = 'dd MMM, y';
  id = input.required<number>();
  vacancyDetail = this.store.selectSignal(jobDetailPageFeature.selectVacancy);
  loading = this.store.selectSignal(jobDetailPageFeature.selectLoading);
  companyInfo = this.store.selectSignal(appFeature.selectCompanyShortInfo);
  currentUser = this.store.selectSignal(authFeature.selectCurrentUser);
  showCopiedIcon = false;
  setTopBlockShadow = false;
  breadcrumbs = [
    {label: 'JobOpeningsPage.navigation.home', route: '/'},
    {label: 'JobOpeningsPage.navigation.job-openings', route: '/job-openings'},
    {label: 'name'},
  ];
  private jobListType: JobListTypeEnum = JobListTypeEnum.All;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.checkScrollPosition();
  }

  ngOnInit() {
    this.store.dispatch(JobDetailsPageActions.jobDetailsPageInit({id: this.id()}));
    this.setParentBreadcrumb();
  }

  ngOnDestroy() {
    this.store.dispatch(JobDetailsPageActions.jobDetailsPageDestroy());
  }

  private checkScrollPosition = throttle(() => {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    this.setTopBlockShadow = scrollPosition > 50;
    this.cdRef.detectChanges();
  }, 200);

  private setParentBreadcrumb() {
    this.jobListType = this.route.snapshot.data['jobListType'];
    this.breadcrumbs[1] = {label: `JobOpeningsPage.navigation.${this.jobListType}`, route: `/${this.jobListType}`};
  }

  onChangeFollowing(isFollowing: boolean) {
    if (isFollowing) {
      this.store.dispatch(JobDetailsPageActions.vacancyUnFollow({id: this.id()}));
    } else {
      this.store.dispatch(JobDetailsPageActions.vacancyFollow({id: this.id()}));
    }
  }

  onApplyClick() {
    this.router.navigate([`/${this.jobListType}`, this.id(), 'job-application']);
  }

  copyLinkToClipboard() {
    copyToClipboard(window.location.href);
    this.showCopiedIcon = true;
    setTimeout(() => {
      this.showCopiedIcon = false;
      this.cd.markForCheck();
    }, 2000);
  }

  onShareToSocialPlatform(platform: SocialPlatform) {
    const url = window.location.href;
    this.setOpenGraphTags(url);
    const shareUrl = platform === SocialPlatform.Facebook
      ? `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
      : `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
    this.openShareWindow(shareUrl);
  }

  setOpenGraphTags(url: string) {
    this.meta.addTags([
      {property: 'og:url', content: url},
      {property: 'og:title', content: this.vacancyDetail()!.name!},
      {property: 'og:description', content: this.vacancyDetail()!.fields[this.fieldsEnum.JODescription] ?? ''},
      {property: 'og:type', content: 'website'},
    ]);
  }

  private openShareWindow(shareUrl: string) {
    const width = 600;
    const height = 400;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    window.open(shareUrl, 'window', `width=${width},height=${height},top=${top},left=${left}`);
  }

  onAttachmentClick(attachment: Attachment) {
    this.store.dispatch(AppActions.getFileDownloadBinary({downloadAccessToken: attachment.downloadAccessToken!}));
  }
}
