import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ListModel } from '@shared/models';
import { Vacancy } from '@shared/interfaces';
import { httpErrorProps } from '@shared/functions';


export const SavedJobsPageActions = createActionGroup({
  source: '[SavedJobs]',
  events: {
    'Saved Vacancies Page init': emptyProps(),
    'Saved Vacancies Page Destroy': emptyProps(),

    'Get All Saved Vacancies': emptyProps(),
    'Get All Saved Vacancies Success': props<ListModel<Vacancy>>(),
    'Get All Saved Vacancies Error': httpErrorProps(),

    'Vacancy Follow': props<{ id: number }>(),
    'Vacancy Follow Success': props<{ id: number }>(),
    'Vacancy Follow Error': httpErrorProps(),

    'Vacancy UnFollow': props<{ id: number }>(),
    'Vacancy UnFollow Success': props<{ id: number }>(),
    'Vacancy UnFollow Error': httpErrorProps(),
  },
});
