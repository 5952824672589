import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { SkeletonModule } from 'primeng/skeleton';

import { NewsFeedCardComponent } from './news-feed-cards';
import { NewsFeedEmptyComponent } from './news-feed-empty';
import { homePageFeature } from '@store/features';

@Component({
  selector: 'app-news-feed',
  standalone: true,
  imports: [NewsFeedCardComponent, NewsFeedEmptyComponent, SkeletonModule],
  templateUrl: './news-feed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsFeedComponent {
  private readonly store = inject(Store);
  newsFeed = this.store.selectSignal(homePageFeature.selectNewsFeed);
  loading = this.store.selectSignal(homePageFeature.selectLoading)();
}
