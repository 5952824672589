import { Vacancy } from '@shared/interfaces';

export type JobDetailsPageState = {
  vacancy: Vacancy | null,
  loading: {
    vacancy: boolean;
  };
};

export const initialJobDetailsPageState: JobDetailsPageState = {
  vacancy: null,
  loading: {
    vacancy: false,
  },
};
