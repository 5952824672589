<div *ngIf="!hideInput">
  <app-file-input
    [id]="id"
    [submitted]="submitted"
    [files]="_files"
    [fileFormats]="requiredFormats"
    [max]="max"
    [singleFile]="singleFile"
    [disableInput]="disableInput || attachmentLoading"
    [noFilePlaceholder]="noFilePlaceholder"
    [requiredError]="requiredError"
    [isForCV]="isForCV"
    [maxCountFilesMessage]="maxCountFilesMessage"
    [disabled]="_disabled"
    [showInput]="showInput"
    [isFileEmpty]="isFileEmpty"
    [required]="required"
    (fileUploaded)="onFileUploaded($event)">
  </app-file-input>
</div>
<app-files-list
  [files]="_files"
  [maxVisibleCount]="maxVisibleCount"
  [maxFileSize]="maxFileSize"
  [attachmentLoading]="attachmentLoading"
  [allFilesTotalSize]="allFilesTotalSize"
  [isAllFilesMoreThanMaxTotalSize]="isAllFilesMoreThanMaxTotalSize"
  [checkByExtension]="checkByExtension"
  [fileFormats]="requiredFormats"
  [requiredExtensions]="requiredExtensions"
  [fileTooLargeMessage]="fileTooLargeMessage"
  [incorrectFormatMessage]="incorrectFormatMessage"
  [disabled]="_disabled"
  [showDeleteButton]="showDeleteButton"
  [isShowOnlyList]="hideInput"
  (removeButtonClick)="removeFile($event)">
</app-files-list>

