export enum ApplicationFormFieldEnum {
  FirstName = 1,
  LastName = 2,
  Email = 3,
  CV = 4,
  Skills = 5,
  Source = 6,
  Phone = 7,
  Attachments = 8,
  Notes = 9,
  Salary = 10,
  LinkedIn = 11,
  Facebook = 12,
  Skype = 13,
  AddressCountry = 14,
  AddressState = 15,
  AddressZip = 16,
  AddressCity = 17,
  AddressStreet = 18,
  CompanyName = 19,
  Position = 20,
  WorkExperienceCountry = 21,
  WorkExperienceCity = 22,
  WorkExperienceStartDate = 23,
  WorkExperienceEndDate = 24,
  Institution = 25,
  Faculty = 26,
  Degree = 27,
  Specialization = 28,
  EducationCountry = 29,
  EducationCity = 30,
  EducationStartDate = 31,
  EducationEndDate = 32,
  Title = 33,
  CertifyingAuthority = 34,
  CertificateNumber = 35,
  ExamCode = 36,
  IssuanceDate = 37,
  ExpirationDate = 38,
  CertificateAttachments = 39,
  SocialServiceNumber = 40,
  LanguageLanguage = 41,
  LanguageLevel = 42,
  LanguageWriting = 43,
  LanguageReading = 44,
  LanguageSpeaking = 45,
  LanguageListening = 46,
  MiddleName = 47,
  DateOfBirth = 48,
  Gender = 49,
  CustomAttachment = 100,
}
