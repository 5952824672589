import { User } from '@shared/interfaces';

export type AuthState = {
  currentUser: User|null;
  loginDialogVisible: boolean;
};

export const initialAuthState: AuthState = {
  currentUser: null,
  loginDialogVisible: false,
};
