<div [formGroup]="form">
  <p-dropdown [options]="states()"
              [showClear]="clearable()"
              [class.invalid]="invalid()"
              inputId="state"
              styleClass="shared-dropdown"
              [autoOptionFocus]="false"
              dropdownIcon="icon-arrow-down"
              panelStyleClass="shared-dropdown-panel"
              formControlName="state"
              optionLabel="label"
              optionValue="value"
              (onHide)="onBlur()"
              [emptyMessage]="'Labels.no_data' | translate"
              [placeholder]="'Labels.select' | translate"/>
</div>
