import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { BaseHttp } from './base';
import { Attachment, HttpResponse, NewsFeed, Vacancy } from '@shared/interfaces';
import { ListModel } from '@shared/models';
import { mockNewsFeed } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class HubService extends BaseHttp {
  getJobOpenings(data?: {
    page: number;
    size: number;
  }): Observable<ListModel<Vacancy>> {
    const url = `${this.baseUrl}/api/jobOpenings`;
    const params = new HttpParams()
      .set('pageNumber', data?.page ?? '')
      .set('pageSize', data?.size ?? '');

    return this.http.get<ListModel<Vacancy>>(url, {
      params,
    });
  }

  getSavedJobOpenings(data?: { page: number; size: number }): Observable<ListModel<Vacancy>> {
    const url = `${this.baseUrl}/api/jobOpenings/saved-jobs`;
    const params = new HttpParams()
      .set('pageNumber', data?.page ?? '')
      .set('pageSize', data?.size ?? '');

    return this.http.get<ListModel<Vacancy>>(url, {params});
  }

  getAppliedJobOpenings(data?: { page: number; size: number }): Observable<ListModel<Vacancy>> {
    const url = `${this.baseUrl}/api/jobOpenings/applied-jobs`;
    const params = new HttpParams()
      .set('pageNumber', data?.page ?? '')
      .set('pageSize', data?.size ?? '');

    return this.http.get<ListModel<Vacancy>>(url, {params});
  }

  vacancyFollow(id: number): Observable<{ isSuccess: boolean }> {
    const url = `${this.baseUrl}/api/JobOpenings/${id}/follow`;
    return this.http.post<{ isSuccess: boolean }>(url, {});
  }

  vacancyUnFollow(id: number): Observable<{ isSuccess: boolean }> {
    const url = `${this.baseUrl}/api/JobOpenings/${id}/unFollow`;
    return this.http.post<{ isSuccess: boolean }>(url, {});
  }

  getVacancyById(id: number): Observable<Vacancy> {
    const url = `${this.baseUrl}/api/jobOpenings/${id}`;
    return this.http.get<Vacancy>(url);
  }

  getNewsFeed(data: {
    page: number;
    size: number;
  }): Observable<ListModel<NewsFeed>> {
    console.log(data)
    // const url = `${this.baseUrl}/api/newsFeed`;
    // const params = new HttpParams()
    //   .set('pageNumber', data.page.toString())
    //   .set('pageSize', data.size.toString());

    // return this.http.get<HttpResponse<NewsFeed[]>>(url, { params, headers });
    return of(ListModel.mock(mockNewsFeed));
  }


  getFileDownloadBinary(downloadToken: string): Observable<HttpResponse<Attachment>> {
    const url = `${this.baseUrl}/api/jobOpenings/secure/preview/${downloadToken}`;
    return this.http.get<HttpResponse<Attachment>>(url);
  }
}
