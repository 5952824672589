import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from '@shared/functions';
import { Attachment, CompanyTheme, OrganizationShortInfo } from '@shared/interfaces';
import { Localization } from '@shared/enums';


export const AppActions = createActionGroup({
  source: '[App]',
  events: {
    'Application init': emptyProps(),

    'Get Company Theme': emptyProps(),
    'Get Company Theme Success': httpSuccessProps<CompanyTheme>(),
    'Get Company Theme Error': httpErrorProps(),

    'Set Language': props<{ systemLanguageId: Localization }>(),

    'Change Language': props<{ systemLanguageId: Localization }>(),
    'Change Language Success': props<{ systemLanguageId: Localization }>(),
    'Change Language Error': httpErrorProps(),

    'Get Company Short Info': emptyProps(),
    'Get Company Short Info Success': props<OrganizationShortInfo>(),
    'Get Company Short Info Error': httpErrorProps(),

    'Get File Download Binary': props<{ downloadAccessToken: string }>(),
    'Get File Download Binary Success': props<{file:Attachment}>(),
    'Get File Download Binary Error': httpErrorProps(),

    'Empty Action': emptyProps(),
  },
});
