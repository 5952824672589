import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [],
  templateUrl: './coming-soon.page.html',
  styleUrls: ['./coming-soon.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComingSoonPageComponent {}
