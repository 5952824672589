import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { Component, SimpleChanges, OnChanges, inject, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';

import { RequiredValidator } from '@shared/validators';
import { ApplicationFormFieldEnum } from '@shared/enums';
import { ApplicationFormSectionSetting, SelectOption } from '@shared/interfaces';
import { StateInputComponent } from '@shared/components';

@Component({
  standalone: true,
  selector: 'app-address',
  templateUrl: './address.component.html',
  imports: [
    ReactiveFormsModule,
    NgForOf,
    NgSwitch,
    TranslateModule,
    NgSwitchCase,
    NgIf,
    DropdownModule,
    NgClass,
    NgTemplateOutlet,
    StateInputComponent,
  ],
})
export class AddressComponent implements OnChanges {
  sectionSettings = input.required<ApplicationFormSectionSetting>();
  mainForm = input.required<UntypedFormGroup>();
  countries = input.required<SelectOption[]>();
  submitted = input.required<boolean>();

  private readonly formBuilder = inject(UntypedFormBuilder);

  form!: UntypedFormGroup;
  fieldType = ApplicationFormFieldEnum;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mainForm']?.currentValue) {
      this.setForm();
    }
  }

  private setForm() {
    this.form = this.mainForm().get(this.sectionSettings().id.toString()) as UntypedFormGroup;
    this.sectionSettings().fieldSettings.forEach(field => {
      switch (field.type) {
        case ApplicationFormFieldEnum.AddressCountry:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : [])
          );
          break;
        case ApplicationFormFieldEnum.AddressState:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : [])
          );
          break;
        case ApplicationFormFieldEnum.AddressZip:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, Validators.maxLength(10)] : [Validators.maxLength(10)])
          );
          break;
        case ApplicationFormFieldEnum.AddressCity:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, Validators.maxLength(50)] : [Validators.maxLength(50)])
          );
          break;
        case ApplicationFormFieldEnum.AddressStreet:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, Validators.maxLength(50)] : [Validators.maxLength(50)])
          );
          break;
      }
    });
  }
}
