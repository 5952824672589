<div class="response-edit-mode p-0" [formGroup]="form()">
  <div class="field-row"
       [ngSwitch]="customField().itemTypeId"
       [class.invalid]="(form().get(customField().id.toString())!.errors && (form().get(customField().id.toString())!.touched || submitted()))">
    <ng-container *ngSwitchCase="typeEnum.TextBox">
      <div class="field-input">
        <div class="messages validation-messages">
          <div
            *ngIf="form().get(customField().id.toString())!.errors && (form().get(customField().id.toString())!.touched || submitted())">
            <span *ngIf="form().get(customField().id.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form().get(customField().id.toString())!.errors!['maxlength']">
              {{ 'Validations.max_length' | translate : {value: 1000} }}
            </span>
          </div>
        </div>
        <input [formControlName]="customField().id.toString()"
               [placeholder]="'Labels.enter' | translate"
               [id]="customField().id.toString()">
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: customField() }"/>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="typeEnum.TextArea">
      <div class="field-input">
        <div class="messages validation-messages">
          <div
            *ngIf="form().get(customField().id.toString())!.errors && (form().get(customField().id.toString())!.touched || submitted())">
                  <span *ngIf="form().get(customField().id.toString())!.errors!['required']">
                    {{ 'Validations.required' | translate }}
                  </span>
            <span *ngIf="form().get(customField().id.toString())!.errors!['maxlength']">
                    {{ 'Validations.max_length' | translate : {value: 1000} }}
                  </span>
          </div>
        </div>
        <textarea [id]="customField().id.toString()" rows="5"
                  [formControlName]="customField().id.toString()"
                  [placeholder]="'Labels.enter' | translate">
              </textarea>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: customField() }"/>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="typeEnum.MultiSelect">
      <div class="field-input">
        <div class="messages validation-messages">
          <div
            *ngIf="form().get(customField().id.toString())!.errors && (form().get(customField().id.toString())!.touched || submitted())">
                  <span *ngIf="form().get(customField().id.toString())!.errors!['required']">
                    {{ 'Validations.required' | translate }}
                  </span>
          </div>
        </div>
        <p-multiSelect [options]="options"
                       [formControlName]="customField().id.toString()"
                       optionLabel="label"
                       [filter]="false"
                       styleClass="shared-multiselect"
                       dropdownIcon="icon-arrow-down"
                       [autoOptionFocus]="false"
                       panelStyleClass="shared-multiselect-panel"
                       [ngClass]="{'invalid': form().get(customField().id.toString())!.errors && (form().get(customField().id.toString())!.touched || submitted())}"
                       [placeholder]="'Labels.select' | translate" />
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: customField() }"/>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="typeEnum.SingleSelect">
      <div class="field-input">
        <div class="messages validation-messages">
          <div
            *ngIf="form().get(customField().id.toString())!.errors && (form().get(customField().id.toString())!.touched || submitted())">
                  <span *ngIf="form().get(customField().id.toString())!.errors!['required']">
                    {{ 'Validations.required' | translate }}
                  </span>
          </div>
        </div>
        <p-dropdown [options]="options"
                    [inputId]="customField().id.toString()"
                    [showClear]="true"
                    styleClass="shared-dropdown"
                    [autoOptionFocus]="false"
                    dropdownIcon="icon-arrow-down"
                    panelStyleClass="shared-dropdown-panel"
                    [formControlName]="customField().id.toString()"
                    [ngClass]="{'invalid': form().get(customField().id.toString())!.errors && (form().get(customField().id.toString())!.touched || submitted())}"
                    optionLabel="label"
                    optionValue="value"
                    [placeholder]="'Labels.select' | translate"/>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: customField() }"/>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="typeEnum.RadioButton">
      <div class="field-input">
        <div class="messages validation-messages">
          <div
            *ngIf="form().get(customField().id.toString())!.errors && (form().get(customField().id.toString())!.touched || submitted())">
                  <span *ngIf="form().get(customField().id.toString())!.errors!['required']">
                    {{ 'Validations.required' | translate }}
                  </span>
          </div>
        </div>
        <div *ngFor="let option of options" class="radio-btn-wrapper pb-1">
          <p-radioButton [value]="option.value!"
                         [ngClass]="{'invalid': form().get(customField().id.toString())!.errors && (form().get(customField().id.toString())!.touched || submitted())}"
                         [formControlName]="customField().id.toString()"
                         [inputId]="option.value! + option.label!"/>
          <label class="secondary-label" [for]="option.value! + option.label!">{{ option.label! }}</label>
        </div>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: customField() }"/>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="typeEnum.Checkboxes">
      <div class="field-input">
        <div class="messages validation-messages">
          <div
            *ngIf="form().get(customField().id.toString())!.errors && (form().get(customField().id.toString())!.touched || submitted())">
                  <span *ngIf="form().get(customField().id.toString())!.errors!['required']">
                    {{ 'Validations.required' | translate }}
                  </span>
          </div>
        </div>
        <div *ngFor="let option of options; let i = index" class="pb-1">
          <p-checkbox name="group"
                      styleClass="shared-checkbox"
                      [ngClass]="{'invalid': form().get(customField().id.toString())!.errors && (form().get(customField().id.toString())!.touched || submitted())}"
                      [formControl]="$any(form().get(customField().id.toString()))"
                      [label]="option.label"
                      [value]="option.value">
          </p-checkbox>
        </div>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: customField() }"/>
      </div>
    </ng-container>
  </div>
  <ng-template #labelTemplate let-customField()>
          <span class="flex activity-name">
            <span [innerHTML]="activityName() | activityContent:customField().itemTemplate.isRequired"></span>
          </span>
  </ng-template>
</div>
