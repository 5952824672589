<div class="file-wrapper">
  <div class="file-name-inner">
    <i class="icon-file mr-2"></i>
    <span class="file-name">{{ file.file.name }}</span>
    @if (loading) {
      <i class="pi pi-spin pi-spinner-dotted gray-icon ml-3"></i>
    } @else {
      <span class="file-size">{{ file.file.size | fileSize }}</span>
    }
  </div>
  @if (!disabled && !loading) {
    <div class="button-wrapper">
      <i class="pi pi-trash" (click)="deleteFile()"></i>
    </div>
  }
  @if (isMoreThanMaxFileSize && !isAllFilesMoreThanMaxTotalSize) {
    <div class="messages validation-messages">
      {{
        'Validations.file_too_large' | translate : {
          value: maxFileSize < 1 ? 1000 * maxFileSize : maxFileSize,
          unit: maxFileSize < 1 ? 'KB' : 'MB'
        }
      }}
    </div>
  }
  @if (isWrongFormatFile) {
    <div class="messages validation-messages">
      @if (incorrectFormatMessage) {
        {{ incorrectFormatMessage | translate }}
      } @else {
        {{
          wrongFormat2 ? ('Validations.wrong_format2' | translate :{value1: wrongFormat1, value2: wrongFormat2})
            : (wrongFormat1 ? ('Validations.wrong_format1' | translate :{value: wrongFormat1})
              : ('Validations.wrong_format0' | translate))
        }}
      }
    </div>
  }
</div>


