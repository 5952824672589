@if (loading.newsFeedLoading) {
<div class="border-round-xl surface-border p-4 surface-card">
  <div class="flex mb-3">
    <p-skeleton shape="circle" width="3rem" height="3rem" styleClass="mr-2" />
    <div>
      <p-skeleton width="10rem" styleClass="mb-2" />
      <p-skeleton width="5rem" styleClass="mb-2" />
      <p-skeleton height=".5rem" />
    </div>
  </div>
  <p-skeleton width="100%" height="150px" />
  <div class="flex mt-3">
    <p-skeleton width="4rem" height="2rem" class="mr-5" />
    <p-skeleton width="4rem" height="2rem" />
  </div>
</div>
} @else { @if (newsFeed().items.length) { @for(newsFeed of newsFeed().items;
track newsFeed) {
<app-news-feed-card [data]="newsFeed" />
} } @else {
<app-news-feed-empty />
} }
