import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ApplicationForm, ApplicationFormResponse, Survey, Vacancy } from '@shared/interfaces';
import { httpErrorProps } from '@shared/functions';

export const JobApplyPageActions = createActionGroup({
  source: '[JobApplyPage]',
  events: {
    'Job Apply Page init': props<{id: number}>(),
    'Job Apply Page Destroy': emptyProps(),

    'Get Vacancy By Id': props<{id: number}>(),
    'Get Vacancy By Id Success': props<Vacancy>(),
    'Get Vacancy By Id Error': httpErrorProps(),

    'Check Vacancy Status': props<Vacancy>(),

    'Get Form By Id': props<{id: number}>(),
    'Get Form By Id Success': props<ApplicationForm>(),
    'Get Form By Id Error': httpErrorProps(),

    'Get Survey By Id': props<{id: number}>(),
    'Get Survey By Id Success': props<Survey|null>(),
    'Get Survey By Id Error': httpErrorProps(),

    'Submit Form': props<{values: ApplicationFormResponse}>(),
    'Submit Form Success': props<{id: number}>(),
    'Submit Form Error': props<{id: number}>(),

    'empty': emptyProps(),
  },
});
