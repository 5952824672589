import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { Vacancy } from '@shared/interfaces';
import { ListModel } from '@shared/models';
import { VacancyListItemComponent } from '../vacancy-list-item';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-job-opening-list',
  standalone: true,
  imports: [
    RouterLink,
    VacancyListItemComponent,
    TranslateModule,
    NgOptimizedImage,
  ],
  templateUrl: './job-opening-list.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobOpeningListComponent {
  jobOpenings = input.required<ListModel<Vacancy>>();
  jobListType = input.required<string>();
  changeFollowing = output<{id: number; isFollowing: boolean}>();

  onChangeFollowing(data: {id: number; isFollowing: boolean}) {
    this.changeFollowing.emit(data);
  }
}
