import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from '@shared/functions';
import { SocialProviderTypeEnum } from '@shared/enums';
import { User } from '@shared/interfaces';

export const AuthActions = createActionGroup({
  source: '[Auth]',
  events: {
    authorize: emptyProps(),
    'Toggle Login Dialog': props<{visible: boolean}>(),

    'Logout': emptyProps(),

    'Social Login': props<{providerType: SocialProviderTypeEnum}>(),
    'Social Login Success': props<{accessToken: string}>(),
    'Social Login Error': httpErrorProps(),

    'Get Current User': emptyProps(),
    'Get Current User Success': httpSuccessProps<User>(),
    'Get Current User Error': httpErrorProps(),
  },
});
