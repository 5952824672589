<div class="container">
  <p-breadcrumb class="max-w-full" [model]="items">
    <ng-template pTemplate="item" let-item>
      @if (item.route) {
        <a [routerLink]="item.route" class="p-menuitem-link no-underline">
          <span [ngClass]="[item.icon ? item.icon : '', 'gray-text']"></span>
          <span class="text-sm light-gray-text">{{ item.label | translate }}</span>
        </a>
      } @else {
        <span class="light-black-text text-sm">{{ item.label | translate }}</span>
      }
    </ng-template>
    <ng-template pTemplate="separator">
      <span class="icon icon-forward"></span>
    </ng-template>
  </p-breadcrumb>
</div>
