import { Injectable } from '@angular/core';
import { Auth, GoogleAuthProvider, signInWithPopup } from "@angular/fire/auth";
import { from, Observable } from "rxjs";
import { UserCredential } from "@firebase/auth";

import { ISocialAuthProvider } from "@shared/interfaces";
import { SocialProviderTypeEnum } from "@shared/enums";

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService implements ISocialAuthProvider {
  readonly type = SocialProviderTypeEnum.Google;

  constructor(private firebaseAuth: Auth) { }

  signIn(): Observable<UserCredential> {

    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    const promise = signInWithPopup(this.firebaseAuth, provider);
    return from(promise);
  }

  signOut() {
    const promise = this.firebaseAuth.signOut();
    return from(promise);
  }
}
