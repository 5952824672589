import { NavBarItem } from '@shared/interfaces';
import { NavBarItemEnum } from '@shared/enums';
import { AuthActions } from '../../store/actions/auth.actions';

export const NavBarItemsConst: NavBarItem[] = [
  {
    label: 'MenuBar.jobs',
    routerLink: '/job-openings',
    identifier: NavBarItemEnum.Jobs,
    class: '',
  },
  {
    label: 'MenuBar.about',
    routerLink: '/about',
    identifier: NavBarItemEnum.About,
    class: '',
  },
  {
    label: '',
    identifier: NavBarItemEnum.Language,
    class: '',
  },
  {
    label: '',
    routerLink: '/notifications',
    identifier: NavBarItemEnum.Notifications,
    class: '',
  },
  {
    label: '',
    identifier: NavBarItemEnum.User,
    class: '',
    items: [
      {
        id: '1',
        label: 'MenuBar.saved_jobs',
        icon: 'icon icon-mark',
        routerLink: '/saved-jobs',
        identifier: NavBarItemEnum.SavedJobs,
        class: '',
      },
      {
        id: '2',
        label: 'MenuBar.applied_jobs',
        icon: 'icon icon-job',
        routerLink: '/applied-jobs',
        identifier: NavBarItemEnum.AppliedJobs,
        class: '',
      },
      {
        id: '3',
        label: 'MenuBar.settings',
        icon: 'icon icon-settings',
        routerLink: '/settings',
        identifier: NavBarItemEnum.Settings,
        class: '',
      },
      {
        id: '4',
        label: 'MenuBar.logOut',
        command: AuthActions.logout(),
        icon: 'icon icon-signout',
        identifier: NavBarItemEnum.SignOut,
        class: '',
      },
    ],
  },
  {
    label: '',
    identifier: NavBarItemEnum.SignIn,
    class: '',
  },
];
