import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-news-feed-empty',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './news-feed-empty.component.html',
  styleUrl: './news-feed-empty.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsFeedEmptyComponent {}
