import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { CardModule } from 'primeng/card';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { Vacancy } from '@shared/interfaces';
import { PublicSettingsFieldsEnum } from '@shared/enums';
import { authFeature } from '@store/features';

@Component({
  standalone: true,
  selector: 'app-vacancy-card',
  imports: [CardModule, TranslateModule, NgClass],
  templateUrl: './vacancy-card.component.html',
  styleUrls: ['./vacancy-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacancyCardComponent {
  private readonly store = inject(Store);
  vacancy = input.required<Vacancy>();
  onChangeFollowing = output<{id: number; isFollowing: boolean}>();
  fieldsEnum = PublicSettingsFieldsEnum;

  currentUser = this.store.selectSignal(authFeature.selectCurrentUser);

  onFollowing(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.onChangeFollowing.emit({
      id: this.vacancy()!.id,
      isFollowing: this.vacancy()!.isFollowing,
    });
  }
}
