import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'primeDateFormat',
})
export class PrimeDateFormatPipe implements PipeTransform {

  private formatMapping: {[key: string]: string} = {
    'dd MMM yyyy': 'dd MM yy',
    'dd/MM/yyyy': 'dd/mm/yy',
    'MM/dd/yyyy': 'mm/dd/yy',
    'yyyy-MM-dd': 'yy-mm-dd',
  };

  transform(dateFormat: string): string {
    return this.formatMapping[dateFormat] || dateFormat;
  }
}
