<div [formGroup]="form" *ngIf="sectionSettings().isEnabled">
  <div class="sub-title">{{ 'Labels.address' | translate }}</div>
  <div *ngFor="let field of sectionSettings().fieldSettings; let i = index" class="field-row">
    <div [ngSwitch]="field.type" class="field-input">
      <ng-container *ngSwitchCase="fieldType.AddressCountry">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
              {{ 'Validations.required' | translate }}
            </span>
          </div>
        </div>
        <p-dropdown [options]="countries()"
                    [inputId]="field.type.toString()"
                    [showClear]="true"
                    styleClass="shared-dropdown"
                    [autoOptionFocus]="false"
                    dropdownIcon="icon-arrow-down"
                    panelStyleClass="shared-dropdown-panel"
                    [formControlName]="field.type.toString()"
                    [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
                    optionLabel="label"
                    [placeholder]="'Labels.select' | translate"/>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.AddressState">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors?.['state']">
              {{ 'Validations.country_first' | translate }}
            </span>
          </div>
        </div>
        <app-state-input [formControlName]="field.type.toString()"
                         [id]="field.type.toString()"
                         [clearable]="false"
                         [invalid]="(form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())!)!"
                         [placeholder]="'Labels.select' | translate"
                         [countryId]="form.get(fieldType.AddressCountry.toString())!.value?.value">
        </app-state-input>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.AddressZip">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors?.['maxlength']">
              {{ 'Validations.zip_maxlength' | translate : {value: 10} }}
            </span>
          </div>
        </div>
        <input [formControlName]="field.type.toString()"
               [placeholder]="'Labels.enter' | translate"
               [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
               [id]="field.type.toString()">
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.AddressCity">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors?.['maxlength']">
              {{ 'Validations.city_maxlength' | translate : {value: 50} }}
            </span>
          </div>
        </div>
        <input [formControlName]="field.type.toString()"
               [placeholder]="'Labels.enter' | translate"
               [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
               [id]="field.type.toString()">
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.AddressStreet">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors?.['maxlength']">
              {{ 'Validations.max_length' | translate : {value: 50} }}
            </span>
          </div>
        </div>
        <input [formControlName]="field.type.toString()"
               [placeholder]="'Labels.enter' | translate"
               [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
               [id]="field.type.toString()">
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #labelTemplate let-field>
  <label class="field-label">
    {{ 'JobOpeningApplyPage.fields.' + field.type | translate }}
    <span *ngIf="field.isRequired" class="required-sign">*</span>
  </label>
</ng-template>
