<div class="coming-soon-wrapper">
  <div class="texts-wrapper">
    <div class="top-text">
      Opportunity is loading...
    </div>
    <div class="bottom-text">
      <span class="bold">work</span><span class="red-dot">.</span>am launching soon<span class="red-dot">.</span>
    </div>
  </div>
</div>
