import { on, createReducer } from '@ngrx/store';
import { immerReducer } from 'ngrx-immer/shared';

/**
 * Immer wrapper around `on` to mutate state
 */
function immerOn(...args) {
    const reducer = args.pop();
    return on(...args, immerReducer(reducer));
}
/**
 * Immer wrapper around `createReducer` to mutate state
 */
function createImmerReducer(initialState, ...ons) {
    const reducer = createReducer(initialState, ...ons);
    return function reduce(state = initialState, action) {
        return immerReducer(reducer)(state, action);
    };
}

/**
 * Generated bundle index. Do not edit.
 */

export { createImmerReducer, immerOn };

