import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, HostListener, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { MenubarModule } from 'primeng/menubar';
import { ImageModule } from 'primeng/image';
import { AvatarModule } from 'primeng/avatar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MenuItem } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

import { AppActions, AuthActions } from '@store/actions';
import { appFeature, authFeature } from '@store/features';
import { NotificationComponent } from '../notification';
import { throttle } from '@shared/functions';
import { LANGUAGE_ID, LanguageOptions, NavBarItemsConst } from '@shared/constants';
import { Localization, NavBarItemEnum } from '@shared/enums';
import { NavBarItem, SelectOption } from '@shared/interfaces';

@Component({
  selector: 'app-menu-bar',
  standalone: true,
  templateUrl: './menu-bar.component.html',
  styleUrl: './menu-bar.component.scss',
  imports: [
    MenubarModule,
    OverlayPanelModule,
    NotificationComponent,
    ImageModule,
    AvatarModule,
    DropdownModule,
    FormsModule,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenubarComponent {
  private readonly store = inject(Store);
  private readonly cdr = inject(ChangeDetectorRef);
  currentUser = this.store.selectSignal(authFeature.selectCurrentUser);
  companyTheme = this.store.selectSignal(appFeature.selectCompanyTheme);
  systemLanguageId = this.store.selectSignal(appFeature.selectSystemLanguageId);
  navBarItems = NavBarItemsConst as MenuItem[];
  navBarItemEnum = NavBarItemEnum;
  setHeaderShadow = false;
  languageOptions = LanguageOptions;
  selectedLanguage!: SelectOption;
  setLanguage = effect(() => {
    this.selectedLanguage = this.languageOptions.find((option) => option.value === this.systemLanguageId())!;
    this.cdr.detectChanges();
  });

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.checkScrollPosition();
  }

  checkScrollPosition = throttle(() => {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    this.setHeaderShadow = scrollPosition > 50;
    this.cdr.detectChanges();
  }, 200);

  changeLanguage(languageOption: SelectOption) {
    if (languageOption) {
      const selectedLanguageIdFromStorage = localStorage.getItem(LANGUAGE_ID)!;
      if (+selectedLanguageIdFromStorage !== languageOption.value) {
        if (this.currentUser()) {
          this.store.dispatch(AppActions.changeLanguage({systemLanguageId: languageOption.value as Localization}));
        } else {
          this.store.dispatch(AppActions.changeLanguageSuccess({systemLanguageId: languageOption.value as Localization}));
        }
      }
    }
  }

  openSignInDialog() {
    this.store.dispatch(AuthActions.toggleLoginDialog({visible: true}));
  }

  handleCommand(item: NavBarItem) {
    this.store.dispatch(item.command!);
  }
}
