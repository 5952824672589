<header class="fixed w-full" [class.shadow]="setHeaderShadow">
  <div class="container">
    <p-menubar [model]="navBarItems"
               autoDisplay="false"
               class="flex justify-content-between p-0 w-full">
      <ng-template pTemplate="start">
        <a [routerLink]="['/']">
          <p-image [src]="companyTheme()?.logoUrl ?? 'assets/images/Raiser1.svg'"
                   alt="Image"
                   class="logo"/>
        </a>
      </ng-template>
      <ng-template pTemplate="item" let-item>
        @switch (item.identifier) {
          @case (navBarItemEnum.User) {
            @if (currentUser()) {
              <div class="flex align-items-center cursor-pointer ml-5">
                <div>
                  <p-avatar [image]="currentUser()?.picture"
                            styleClass="mr-2"
                            shape="circle"/>
                </div>
                <div class="ml-2">
                  <span class="icon icon-arrow-down inline-block"></span>
                </div>
              </div>
            }
          }
          @case (navBarItemEnum.Notifications) {
            @if (currentUser()) {
              <i class="icon-notification cursor-pointer relative">
                <!--               (click)="notificationOverlayPanel.toggle($event)">-->
                <span class="notif-count-wrapper absolute">
                  <span
                    class="count font-sm font-bold absolute flex justify-content-center align-items-center top-50 left-50">6</span>
                </span>
              </i>
            }
          }
          @case (navBarItemEnum.Language) {
            <p-dropdown [options]="languageOptions"
                        optionLabel="label"
                        dropdownIcon="icon-arrow-down"
                        (ngModelChange)="changeLanguage($event)"
                        styleClass="language-dropdown mr-6"
                        [(ngModel)]="selectedLanguage"
                        [showClear]="false">
              <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2">
                    <div>
                      <img class="flag-icon" [src]="selectedLanguage.info!['icon']!" [alt]="selectedLanguage.label"/>
                      {{ selectedLanguage.label }}
                    </div>
                  </div>
              </ng-template>
              <ng-template let-language pTemplate="item">
                <div class="flex align-items-center gap-2">
                  <div>
                    <img class="flag-icon" [src]="language.info.icon" [alt]="language.label"/>
                    {{ language.label }}
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
          }
          @case (navBarItemEnum.SignIn) {
            @if (!currentUser()) {
              <button class="button-primary" (click)="openSignInDialog()">Sign In</button>
            }
          }
          @default {
            <a [routerLink]="item.routerLink"
               (click)="item.command ? handleCommand(item) : null"
               class="flex align-items-center p-menuitem-link menuitem-link">
              <div class="pr-3 user-navigation">
                <span [class]="item.icon"></span>
              </div>
              <div>
                <span [class]="item.class" class="left-menu-item">{{ item.label | translate }}</span>
              </div>
            </a>
          }
        }
      </ng-template>
    </p-menubar>
  </div>
</header>

<p-overlayPanel appendTo="body"
                [styleClass]="'filter-panel'"
                #notificationOverlayPanel
                (onHide)="notificationOverlayPanel.hide()">
  @if (notificationOverlayPanel.overlayVisible) {
    <app-notification (closePanel)="notificationOverlayPanel.hide()"/>
  }
</p-overlayPanel>
