<p-card class="vacancy-card">
  <ng-template pTemplate="header">
    <div class="flex justify-content-between">
      <div class="px-2">
        <div class="text-md font-semibold line-height-2">
          {{ vacancy().name }}
        </div>
        <div class="text-sm gray-text line-height-3">
          @if ((vacancy().fields[fieldsEnum.Location]); as branchesLocations) {
            {{ branchesLocations }}
          }
        </div>
      </div>
      @if (currentUser() && !vacancy().isApplied) {
        <a (click)="onFollowing($event)">
          <span [ngClass]="vacancy().isFollowing ? 'icon-mark-filled' : 'icon-mark'" class="icon"></span>
        </a>
      }
    </div>
  </ng-template>

  <div class="flex flex-wrap gap-2 p-0 m-0 font-base text-xs">
    @if (vacancy().fields[fieldsEnum.ProfessionalLevel]; as profLevel) {
      <span class="chip">{{ profLevel }}</span>
    }
    @if (vacancy().fields[fieldsEnum.ContractType]; as contractType) {
      <span class="chip">
        {{ contractType }}
      </span>
    }
    @if (vacancy().fields[fieldsEnum.EmploymentType]; as employmentType) {
      <span class="chip">{{ employmentType }}</span>
    }
    @if (vacancy().fields[fieldsEnum.WorkType]; as workType) {
      <span class="chip">{{ workType }}</span>
    }
  </div>

  <ng-template pTemplate="footer">
    <span class="text-xs gray-text">
      @if (vacancy().daysPosted) {
        {{ "PUBLIC_VACANCIES.labels.posted_date"| translate : {days: vacancy().daysPosted} }}
      }
    </span>
  </ng-template>
</p-card>
