import { Component, inject, input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { JsonPipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';

import { CustomFieldItem, SelectOption } from '@shared/interfaces';
import { CustomFieldItemTypeEnum } from '@shared/enums';
import { ActivityContentPipe } from '@shared/pipes';
import { RequiredValidator } from '@shared/validators';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  standalone: true,
  selector: 'app-custom-field-item',
  templateUrl: './custom-field-item.component.html',
  styleUrls: ['./custom-field-item.component.scss'],
  imports: [
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    NgForOf,
    NgSwitch,
    NgSwitchCase,
    CheckboxModule,
    JsonPipe,
    ActivityContentPipe,
    NgTemplateOutlet,
    DropdownModule,
    RadioButtonModule,
    MultiSelectModule,
  ],
})
export class CustomFieldItemComponent implements OnInit {
  submitted = input.required<boolean>();
  activityName = input.required<string>();
  form = input.required<UntypedFormGroup>();
  customField = input.required<CustomFieldItem>();

  private readonly formBuilder = inject(UntypedFormBuilder);

  typeEnum = CustomFieldItemTypeEnum;
  options: SelectOption[] = [];

  ngOnInit() {
    this.setDisplayMode();
  }

  private setDisplayMode() {
    this.setResponseForm();
    if (this.customField().itemTemplate.options) {
      this.setOptions(false);
    }
  }

  private setResponseForm() {
    let control: AbstractControl;
    switch (this.customField().itemTypeId) {
      case this.typeEnum.TextBox:
        control = this.formBuilder.control(null, this.customField().itemTemplate?.isRequired ? [RequiredValidator.validate, Validators.maxLength(1000)] : [Validators.maxLength(1000)]);
        break;
      case this.typeEnum.TextArea:
        control = this.formBuilder.control(null, this.customField().itemTemplate?.isRequired ? [RequiredValidator.validate, Validators.maxLength(4000)] : [Validators.maxLength(4000)]);
        break;
      case this.typeEnum.MultiSelect:
      case this.typeEnum.SingleSelect:
      case this.typeEnum.Checkboxes:
      case this.typeEnum.RadioButton:
        control = this.formBuilder.control(null, this.customField().itemTemplate?.isRequired ? [RequiredValidator.validate] : []);
        break;
    }
    this.form().addControl(this.customField().id.toString(), control);
  }

  private setOptions(disabled: boolean) {
    this.options = this.customField().itemTemplate.options!.map((option) => ({
      label: option.label,
      value: option.value,
      disabled: disabled,
    }));
  }
}
