<div class="field-row" [formGroup]="form" *ngIf="formReady">
  <div class="field-input">
    <div class="validation-messages messages">
      <span *ngIf="form.get('salaryTypeId')?.errors && form.get('salaryTypeId')?.touched">
        {{ 'Validations.required' | translate }}
      </span>
    </div>
    <div class="types">
      <div class="type">
        <p-radioButton [value]="salaryTypeEnum.Negotiable"
                       formControlName="salaryTypeId"
                       inputId="negotiable"/>
        <label class="secondary-label" for="negotiable">
          {{ 'Labels.negotiable' | translate }}
        </label>
      </div>
      <div class="type">
        <p-radioButton [value]="salaryTypeEnum.Fixed"
                       formControlName="salaryTypeId"
                       inputId="amount"/>
        <label class="secondary-label" for="amount">
          {{ 'Labels.amount' | translate }}
        </label>
      </div>
    </div>
    <label class=" field-label">
      {{ 'Labels.type_label' | translate }}
      <span class="required-sign" *ngIf="isRequired()">*</span>
    </label>
  </div>
  <div *ngIf="form.get('salaryTypeId')?.value === salaryTypeEnum.Fixed" class="field-input amount-input">
    <div class="validation-messages messages">
      <span *ngIf="form.get('value')?.errors && form.get('value')?.touched">
        <span *ngIf="form.get('value')?.errors?.['required']">
          {{ 'Validations.required' | translate }}
        </span>
        <span *ngIf="form.get('value')?.errors?.['max']">
          {{ 'Validations.max_length' | translate : {value: 10} }}
        </span>
      </span>
    </div>
    <p-inputNumber type="number"
                   [min]="1"
                   formControlName="value"
                   [placeholder]="'Labels.enter' | translate"
                   [class.invalid]="form.get('value')?.errors && (form.get('value')?.touched || submitted())"/>
    <label class=" field-label">
      {{ 'Labels.amount_label' | translate }} <span class="required-sign">*</span>
    </label>
  </div>
  <div *ngIf="form.get('salaryTypeId')?.value === salaryTypeEnum.Fixed" class="field-input amount-input">
    <div class="validation-messages messages">
      <span *ngIf="form.get('currencyId')?.errors && form.get('currencyId')?.touched">
        <span *ngIf="form.get('currencyId')?.errors?.['required']">
        {{ 'Validations.required' | translate }}
        </span>
      </span>
    </div>
    <p-dropdown [options]="currencies()"
                styleClass="shared-dropdown"
                dropdownIcon="icon-arrow-down"
                [autoOptionFocus]="false"
                panelStyleClass="shared-dropdown-panel"
                [class.invalid]="form.get('currencyId')?.errors && (form.get('currencyId')?.touched || submitted())"
                formControlName="currencyId"
                optionLabel="currencyCode"
                optionValue="id"
                [placeholder]="'Labels.select' | translate">
      <ng-template let-currency pTemplate="item">
        <div>{{ currency.currencyCode }} <small class="">{{ currency.currencySymbol }}</small></div>
      </ng-template>
    </p-dropdown>
    <label class=" field-label">
      {{ 'Labels.currency' | translate }} <span class="required-sign">*</span>
    </label>
  </div>
</div>
