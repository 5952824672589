<div [hidden]="singleFile && files.length === 1" class="image-uploader-inner">
  @if (!disabled && showInput) {
    <div class="file-input-wrapper">
      <label class="file-input-label" [class.disabled]="disableInput">
        <i class="pi pi-upload mr-2"></i>
        <span class="upload-label">
          @if (isForCV) {
            {{ 'Labels.upload_cv' | translate }}
          } @else {
            {{ 'Labels.upload_file' | translate }}
          }
      </span>
        <input #input
               type="file"
               class="hidden"
               [multiple]="false"
               [(ngModel)]="_files"
               [accept]="supportedExtensions"
               (change)="onFileChange(input.files!)">
      </label>
    </div>
  }
  <div class="messages validation-messages">
    @if (isFilesCountMoreThanMax) {
      <span>{{ 'Validations.max_count_file' | translate : {value: max} }}</span>
    }
    @if (isFileRequired) {
      <span>{{ requiredError | translate }}</span>
    }
    @if (isFileEmpty) {
      <span>{{ 'Validations.file_is_empty' | translate }}</span>
    }
  </div>
</div>


