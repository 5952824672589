import { ListModel } from '@shared/models';
import { Vacancy } from '@shared/interfaces';

export type SavedJobsPageState = {
  vacancyList: ListModel<Vacancy>;
  loading: boolean;
};

export const initialSavedJobPageState: SavedJobsPageState = {
  vacancyList: ListModel.empty<Vacancy>(),
  loading: false,
};
