@if (vm().loading.vacancy) {
  <div class="container w-5">
    <p-skeleton height="4rem" styleClass="mb-4"/>
    <p-skeleton height="4rem" styleClass="mb-4"/>
    <p-skeleton height="4rem" styleClass="mb-4"/>
  </div>
} @else {
  <div class="container">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbs">
      <ng-template pTemplate="item" let-item>
        @if (item.route) {
          <a [routerLink]="item.route" class="p-menuitem-link no-underline">
            <span [ngClass]="[item.icon ? item.icon : '', 'gray-text']"></span>
            <span class="text-sm light-gray-text">{{ item.label | translate }}</span>
          </a>
        } @else {
          @if (item.label === 'name') {
            <a routerLink="/job-openings/{{vm().vacancy?.id}}" class="p-menuitem-link no-underline">
              <span [ngClass]="[item.icon ? item.icon : '', 'gray-text']"></span>
              <span class="text-sm light-gray-text">{{ vm().vacancy?.name }}</span>
            </a>
          } @else {
            <span class="light-black-text text-sm">
            {{ item.label | translate }}
        </span>
          }
        }
      </ng-template>
      <ng-template pTemplate="separator">
        <span class="icon icon-forward"></span>
      </ng-template>
    </p-breadcrumb>
  </div>

  <div class="job-apply-form-wrapper container mb-8">
    @if (vm().vacancy; as vacancy) {
      <div class="job-apply-form-card">
        <h1 class="card-title">{{ vacancy.name }}</h1>
        @if (form()) {
          <app-main-fields-section [mainForm]="mainForm"
                                   [skills]="options().skills"
                                   [isCustomSection]="false"
                                   [currencies]="options().currencies"
                                   [genders]="options().genders"
                                   [sourceTypes]="options().sourceTypes"
                                   [submitted]="submitted"
                                   [dateFormat]="dateFormat"
                                   (attachmentLoading)="attachmentLoading = $event"
                                   [sectionSettings]="formTemplate!.sectionSettings[0]">
          </app-main-fields-section>
          @for (sectionSetting of formTemplate!.sectionSettings; track sectionSetting) {
            @switch (sectionSetting.type) {
              @case (sectionType.Address) {
                <app-address [mainForm]="mainForm"
                             [submitted]="submitted"
                             [countries]="options().countries"
                             [sectionSettings]="sectionSetting">
                </app-address>
              }
              @case (sectionType.WorkExperience) {
                <app-work-experience [mainForm]="mainForm"
                                     [dateFormat]="dateFormat"
                                     [countries]="options().countries"
                                     [sectionSettings]="sectionSetting">
                </app-work-experience>
              }
              @case (sectionType.Education) {
                <app-education [mainForm]="mainForm"
                               [dateFormat]="dateFormat"
                               [countries]="options().countries"
                               [degrees]="options().educationalDegrees"
                               [sectionSettings]="sectionSetting">
                </app-education>
              }
              @case (sectionType.Certificates) {
                <app-certificates [mainForm]="mainForm"
                                  [dateFormat]="dateFormat"
                                  [sectionSettings]="sectionSetting">
                </app-certificates>
              }
              @case (sectionType.Languages) {
                <app-languages [mainForm]="mainForm"
                               [languages]="options().languages"
                               [proficiencies]="options().proficiencies"
                               [sectionSettings]="sectionSetting">
                </app-languages>
              }
              @case (sectionType.Custom) {
                <app-main-fields-section [isCustomSection]="true"
                                         [mainForm]="mainForm"
                                         [skills]="options().skills"
                                         [currencies]="options().currencies"
                                         [sourceTypes]="options().sourceTypes"
                                         [submitted]="submitted"
                                         [genders]="options().genders"
                                         [dateFormat]="dateFormat"
                                         [sectionSettings]="sectionSetting"
                                         (attachmentLoading)="attachmentLoading = $event">
                </app-main-fields-section>
              }
            }
          }
          @if (vm().survey) {
            <app-survey [submitted]="submitted"
                        [mainForm]="mainForm"
                        [loading]="vm().loading.survey"
                        [survey]="vm().survey">
            </app-survey>
          }
        }
        <div class="mt-3 flex justify-content-center">
          <button class="button-primary lg w-6" (click)="onApply()" [disabled]="attachmentLoading">
            {{ "Buttons.submit_application" | translate }}
          </button>
        </div>
      </div>
    }
  </div>
}
