import { Component, SimpleChanges, OnChanges, inject, input } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DatePipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';

import { ApplicationFormSectionSetting, SelectOption } from '@shared/interfaces';
import { ApplicationFormFieldEnum } from '@shared/enums';
import { RequiredValidator } from '@shared/validators';
import { PrimeDateFormatPipe } from '@shared/pipes';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  standalone: true,
  selector: 'app-work-experience',
  templateUrl: './work-experience.component.html',
  imports: [
    TranslateModule,
    NgIf,
    DialogModule,
    ReactiveFormsModule,
    NgClass,
    NgForOf,
    NgSwitchCase,
    NgSwitch,
    DatePipe,
    DropdownModule,
    NgTemplateOutlet,
    CalendarModule,
    PrimeDateFormatPipe,
    InputSwitchModule,
    CheckboxModule,
  ],
})
export class WorkExperienceComponent implements OnChanges {

  sectionSettings = input.required<ApplicationFormSectionSetting>();
  mainForm = input.required<UntypedFormGroup>();
  countries = input.required<SelectOption[]>();
  dateFormat = input.required<string>();

  private readonly formBuilder = inject(UntypedFormBuilder);

  formArray!: UntypedFormArray;
  form!: UntypedFormGroup;
  savedExperiences: any[] = [];
  showAddEditDialog = false;
  submitted = false;
  showPresentSwitcher: boolean = false;
  selectedFormIndex: number|null = null;
  isEdit = false;
  fieldType = ApplicationFormFieldEnum;
  presentSwitcherControl = this.formBuilder.control(false);
  private isSaving = false;
  private originalFormValue = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sectionSettings']?.currentValue) {
      this.setForm();
    }
  }

  private setForm() {
    this.formArray = this.mainForm().get(this.sectionSettings().id.toString()) as UntypedFormArray;
    this.showPresentSwitcher = this.sectionSettings().fieldSettings.find(field => field.type === this.fieldType.WorkExperienceEndDate)?.isShow ?? false;
    this.watchPresentSwitcher();
  }

  onAddExperience() {
    this.formArray.push(this.formBuilder.group({
      dateGroup: this.formBuilder.group({}, {validator: this.validateDateGroup}),
    }));
    this.form = this.formArray.at(this.formArray!.length - 1) as UntypedFormGroup;
    this.sectionSettings().fieldSettings.forEach(field => {
      switch (field.type) {
        case ApplicationFormFieldEnum.CompanyName:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, Validators.maxLength(100)] : [Validators.maxLength(100)]),
          );
          break;
        case ApplicationFormFieldEnum.Position:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, Validators.maxLength(100)] : [Validators.maxLength(100)]),
          );
          break;
        case ApplicationFormFieldEnum.WorkExperienceCountry:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.WorkExperienceCity:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, Validators.maxLength(100)] : [Validators.maxLength(100)]),
          );
          break;
        case ApplicationFormFieldEnum.WorkExperienceStartDate:
          (this.form.get('dateGroup') as UntypedFormGroup).addControl(
            field.type.toString(),
            // @ts-ignore
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, this.biggerThenCurrentDateValidation] : [this.biggerThenCurrentDateValidation]),
          );
          break;
        case ApplicationFormFieldEnum.WorkExperienceEndDate:
          (this.form.get('dateGroup') as UntypedFormGroup).addControl(
            field.type.toString(),
            // @ts-ignore
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, this.biggerThenCurrentDateValidation] : [this.biggerThenCurrentDateValidation]),
          );
          break;
      }
    });
    this.presentSwitcherControl.setValue(false);
    this.selectedFormIndex = this.formArray!.length - 1;
    this.isEdit = false;
    this.showAddEditDialog = true;
  }

  private watchPresentSwitcher() {
    this.presentSwitcherControl.valueChanges
      .subscribe((value) => {
        if (this.showPresentSwitcher) {
          const isEndDateRequired = this.sectionSettings().fieldSettings.find(field => field.type === this.fieldType.WorkExperienceEndDate)!.isRequired;
          const endDateControl = this.form.get('dateGroup')?.get(this.fieldType.WorkExperienceEndDate.toString())!;
          if (value) {
            endDateControl.clearValidators();
            endDateControl.setValue(null);
            endDateControl.disable();
          } else {
            endDateControl.enable();
            // @ts-ignore
            endDateControl.setValidators(isEndDateRequired ? [RequiredValidator.validate, this.biggerThenCurrentDateValidation] : [this.biggerThenCurrentDateValidation]);
          }
          endDateControl.updateValueAndValidity();
        }
      });
  }

  private validateDateGroup(formGroup: UntypedFormGroup): {[key: string]: boolean}|null {
    const start = (<UntypedFormControl>formGroup.controls[ApplicationFormFieldEnum.WorkExperienceStartDate.toString()])?.value;
    const end = (<UntypedFormControl>formGroup.controls[ApplicationFormFieldEnum.WorkExperienceEndDate.toString()])?.value;
    if (start && end) {
      const startDateVal = new Date(start).valueOf();
      const endDateVal = new Date(end).valueOf();
      if (endDateVal < startDateVal) {
        return {range: true};
      }
    }
    return null;
  }

  private biggerThenCurrentDateValidation(control: UntypedFormControl): {[key: string]: boolean}|null {
    const date = control.value;
    if (date) {
      const dateVal = new Date(date).valueOf();
      const nowVal = new Date().setHours(0, 0, 0, 0).valueOf();
      if (dateVal >= nowVal) {
        return {biggerThenCurrent: true};
      }
    }
    return null;
  }

  onHideAddEditDialog() {
    this.showAddEditDialog = false;
    if (this.savedExperiences.length !== this.formArray!.length) {
      this.formArray!.removeAt(this.selectedFormIndex!);
    }
    if (this.isEdit && !this.isSaving) {
      this.formArray.at(this.selectedFormIndex!).patchValue(this.originalFormValue);
    }
    this.selectedFormIndex = null;
    this.isSaving = false;
    this.isEdit = false;
    this.submitted = false;
  }

  onSaveExperience() {
    this.submitted = true;
    if (this.form.valid) {
      if (this.isEdit) {
        this.isSaving = true;
        this.savedExperiences[this.selectedFormIndex!] = this.form.getRawValue();
      } else {
        this.savedExperiences.push(this.form.getRawValue());
      }
      this.selectedFormIndex = null;
      this.showAddEditDialog = false;
    }
  }

  onEditExperience(index: number) {
    this.form = this.formArray!.at(index) as UntypedFormGroup;
    this.originalFormValue = this.form.getRawValue();
    this.presentSwitcherControl.setValue(this.form.get('dateGroup')!.get(this.fieldType.WorkExperienceEndDate.toString())?.value);
    this.selectedFormIndex = index;
    this.isEdit = true;
    this.showAddEditDialog = true;
  }

  onDeleteExperience(index: number) {
    this.formArray.removeAt(index);
    this.savedExperiences.splice(index, 1);
  }
}
