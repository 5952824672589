import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { Features } from '../features.enum';
import { initialJobOpeningsPageState } from '../states';
import { PublicSettingsFieldsEnum } from '@shared/enums';
import { JobOpeningsPageActions } from '@store/actions';
import { ListModel } from '@shared/models';
import { Vacancy } from '@shared/interfaces';


export const jobOpeningsPageReducer = createReducer(
  initialJobOpeningsPageState,
  immerOn(JobOpeningsPageActions.getAllVacancies, (state) => {
    state.loading = true;
  }),
  immerOn(JobOpeningsPageActions.getVacanciesSuccess, (state, payload) => {
    const mappedVacancies = payload.items.map((item) => {
      const openingDate = item.fields[PublicSettingsFieldsEnum.OpeningDate];
      const daysPosted = openingDate ? Math.floor((new Date().getTime() - new Date(openingDate).getTime()) / (1000 * 60 * 60 * 24))
        : null;
      return ({...item, daysPosted});
    });
    state.vacancyList = {...payload, items: mappedVacancies};
    state.loading = false;
  }),
  immerOn(JobOpeningsPageActions.getVacanciesError, (state) => {
      state.loading = false;
    }
  ),
  immerOn(
    JobOpeningsPageActions.vacancyFollowSuccess,
    JobOpeningsPageActions.vacancyUnFollowSuccess,
    (state, payload) => {
      state.vacancyList.items = state.vacancyList.items.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            isFollowing: !item.isFollowing,
          };
        }
        return item;
      });
    }
  ),
  immerOn(JobOpeningsPageActions.jobOpeningsPageDestroy, (state) => {
    state.vacancyList = ListModel.empty<Vacancy>();
  }),
);

export const jobOpeningsPageFeature = createFeature({
  name: Features.JobOpeningsPage,
  reducer: jobOpeningsPageReducer,
});
