import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { FileHolder } from '../file-holder';

@Component({
  standalone: true,
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  imports: [TranslateModule, FormsModule],
})
export class FileInputComponent implements OnInit {
  @Input() files!: FileHolder[];
  @Input() isFileEmpty = false;
  @Input() submitted = false;
  @Input() disableInput = false;
  @Input() isForCV = false;
  @Input() fileFormats: string[] = [];
  @Input() noFilePlaceholder = '';
  @Input() requiredError = '';
  @Input() disabled = false;
  @Input() required = false;
  @Input() showInput = true;
  @Input() singleFile = false;
  @Input() max = 5;
  @Input() maxCountFilesMessage!: string;
  @Output() fileUploaded: EventEmitter<FileHolder> = new EventEmitter<FileHolder>();
  _files: FileHolder[] = [];
  supportedExtensions = '';

  ngOnInit() {
    this.supportedExtensions = this.fileFormats.join(',');
  }

  onFileChange(files: FileList) {
    this.uploadFiles(files);
    this._files = [];
  }

  private uploadFiles(files: FileList|null) {
    if (!files) return;
    const fileUploadObservables: Observable<FileHolder>[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileUploadObservable = new Observable<FileHolder>((observer) => {
        file.arrayBuffer().then((arrayBuffer: ArrayBuffer) => {
          const fileHolder: FileHolder = new FileHolder('', file, undefined);
          fileHolder.blob = arrayBuffer;
          observer.next(fileHolder);
          observer.complete();
        });
      });
      fileUploadObservables.push(fileUploadObservable);
    }

    forkJoin(fileUploadObservables).subscribe({
      next: (res) => {
        res.forEach(fileHolder => {
          this.fileUploaded.emit(fileHolder);
        });
      },
    });
  }

  get isFilesCountMoreThanMax(): boolean {
    return this.files.length > this.max;
  }

  get isFileRequired(): boolean {
    return this.required && this.files.length < 1 && this.submitted;
  }
}
