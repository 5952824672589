import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { JobOpeningListComponent, VacancyListItemComponent } from '../../components';
import { Store } from '@ngrx/store';
import { savedJobsPageFeature } from '@store/features';
import { SavedJobsPageActions } from '@store/actions';
import { JobListTypeEnum } from '@shared/enums';

@Component({
  standalone: true,
  selector: 'app-saved-jobs',
  templateUrl: './saved-jobs.page.html',
  imports: [
    BreadcrumbModule,
    NgOptimizedImage,
    SharedModule,
    SkeletonModule,
    TranslateModule,
    VacancyListItemComponent,
    NgClass,
    JobOpeningListComponent,
  ],
})
export class SavedJobsPageComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  readonly jobListTypeEnum = JobListTypeEnum;
  loading = this.store.selectSignal(savedJobsPageFeature.selectLoading);
  vacancyList = this.store.selectSignal(savedJobsPageFeature.selectVacancyList);
  breadcrumbs = [
    {label: 'JobOpeningsPage.navigation.home', route: '/'},
    {label: 'JobOpeningsPage.navigation.saved-jobs'},
  ];

  ngOnInit() {
    this.store.dispatch(SavedJobsPageActions.getAllSavedVacancies());
  }

  ngOnDestroy() {
    this.store.dispatch(SavedJobsPageActions.savedVacanciesPageDestroy());
  }

  onChangeFollowing(data: {id: number; isFollowing: boolean}) {
    if (data.isFollowing) {
      this.store.dispatch(SavedJobsPageActions.vacancyUnFollow(data));
    } else {
      this.store.dispatch(SavedJobsPageActions.vacancyFollow(data));
    }
  }
}
