import { Component, forwardRef, inject, input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validator,
} from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';

import { RequiredValidator } from '@shared/validators';
import { CountryMask } from '@shared/interfaces';
import { countryMasksConst } from '@shared/constants';

@Component({
  standalone: true,
  selector: 'app-phone-by-country-mask',
  templateUrl: './phone-by-country-mask.component.html',
  styleUrls: ['./phone-by-country-mask.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhoneByCountryMaskComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneByCountryMaskComponent),
      multi: true,
    },
  ],
  imports: [
    DropdownModule,
    FormsModule,
    NgIf,
    NgxMaskDirective,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class PhoneByCountryMaskComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  defaultCountryCode = input.required<string>();
  isRequired = input.required<boolean>();
  inputId = input.required<string>();

  private readonly fromBuilder = inject(UntypedFormBuilder);

  countries: CountryMask[] = countryMasksConst;
  selectedCountry: CountryMask|undefined = this.countries.find((country) => country.iso === 'AM');
  form: UntypedFormGroup = this.fromBuilder.group({phone: [], country: [this.selectedCountry]});
  private touchedFn: () => void  = () => {
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isRequired']?.currentValue) {
      this.form.get('phone')!.setValidators([RequiredValidator.validate]);
      if (this.defaultCountryCode()) {
        this.form.get('country')!.setValue(this.countries.find(country => country.code === this.defaultCountryCode()));
      }
    }
  }

  ngOnInit() {
    this.watchCountryChanges();
  }

  registerOnChange(fn: (arg0: {countryCode: string; phone: string;}) => void) {
    this.form.valueChanges.subscribe(value => fn({countryCode: value.country.code, phone: value.phone}));
  }

  registerOnTouched(fn: () => void) {
    this.touchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate() {
    return {...this.form.get('phone')!.errors};
  }

  writeValue(obj: {countryCode: string, phone: string}) {
    if (!obj) return;
    this.selectedCountry = this.countries.find(country => country.code === obj.countryCode);
    this.form.get('phone')!.patchValue(obj.phone);
    this.form.get('country')!.patchValue(this.selectedCountry);
  }

  private watchCountryChanges() {
    this.form.get('country')!.valueChanges.subscribe((country: CountryMask) => this.selectedCountry = country);
  }

  onPhoneInputBlur() {
    this.touchedFn!();
  }
}
