import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ListModel } from '@shared/models';
import { NewsFeed, Vacancy } from '@shared/interfaces';
import { httpErrorProps } from '@shared/functions';


export const HomePageActions = createActionGroup({
  source: '[HomePageComponent]',
  events: {
    'HomePageComponent Init': emptyProps(),
    'HomePageComponent Destroy': emptyProps(),

    'Get News Feed': props<{ page: number; size: number }>(),
    'Get News Feed Success': props<ListModel<NewsFeed>>(),
    'Get News Feed Error': httpErrorProps(),

    'Get Paged Vacancies': props<{ page: number; size: number }>(),
    'Get Paged Vacancies Success': props<ListModel<Vacancy>>(),
    'Get Paged Vacancies Error': httpErrorProps(),

    'Vacancy Follow': props<{ id: number }>(),
    'Vacancy Follow Success': props<{ id: number}>(),
    'Vacancy Follow Error': httpErrorProps(),

    'Vacancy UnFollow': props<{ id: number }>(),
    'Vacancy UnFollow Success': props<{id: number}>(),
    'Vacancy UnFollow Error': httpErrorProps(),
  },
});
