import { CompanyTheme, OrganizationShortInfo } from '@shared/interfaces';
import { Localization } from '@shared/enums';

export type AppState = {
  companyTheme: CompanyTheme|null;
  themeLoading: boolean;
  companyShortInfo: OrganizationShortInfo|null;
  systemLanguageId: Localization|null;
};

export const initialAppState: AppState = {
  companyTheme: null,
  themeLoading: false,
  companyShortInfo: null,
  systemLanguageId: null,
};
