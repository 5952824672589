import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FileHolder } from '../file-holder';
import { FileListItemComponent } from '../file-list-item/file-list-item.component';

@Component({
  standalone: true,
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss'],
  imports: [FileListItemComponent, TranslateModule],
})
export class FilesListComponent {
  @Input() isAllFilesMoreThanMaxTotalSize = false;
  @Input() showDeleteButton = true;
  @Input() files: FileHolder[] = [];
  @Input() allFilesTotalSize!: number;
  @Input() maxVisibleCount!: number;
  @Input() maxFileSize!: number;
  @Input() requiredExtensions: string[] = [];
  @Input() fileFormats: string[] = [];
  @Input() fileTooLargeMessage!: string;
  @Input() incorrectFormatMessage!: string;
  @Input() disabled = false;
  @Input() attachmentLoading = false;
  @Input() checkByExtension = false;
  @Input() isShowOnlyList = false;
  @Output() removeButtonClick: EventEmitter<FileHolder> = new EventEmitter<FileHolder>();
  isExpanded = false;

  toggleExpandedState() {
    this.isExpanded = !this.isExpanded;
  }
}
