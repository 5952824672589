import { InjectionToken, inject, Provider } from "@angular/core";

import { ISocialAuthProvider } from "@shared/interfaces";
import { SocialProviderTypeEnum } from '@shared/enums';
import { FacebookAuthService, GoogleAuthService, InstagramAuthService, LinkedinAuthService } from "@shared/services";

const AuthProvider = new InjectionToken<ISocialAuthProvider>('AuthProvider');

export const injectAuthProvider = (type: SocialProviderTypeEnum) => {
  const provider = (inject(AuthProvider) as unknown as ISocialAuthProvider[])
    .find(
      provider => provider.type === type
    );

  if (!provider) {
    throw new ReferenceError(`Auth provider by the type ${type} was not found`)
  }

  return provider;
}

export const provideSocialAuth = (): Provider[] => [
  { provide: AuthProvider, useClass: GoogleAuthService, multi: true },
  { provide: AuthProvider, useClass: FacebookAuthService, multi: true },
  { provide: AuthProvider, useClass: InstagramAuthService, multi: true },
  { provide: AuthProvider, useClass: LinkedinAuthService, multi: true },
];
