import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { BaseHttp } from './base';
import { User, CompanyTheme, OrganizationShortInfo, HttpResponse } from '@shared/interfaces';
import { Localization } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class AuthAccountService extends BaseHttp {
  getCurrentUser(): Observable<HttpResponse<User>> {
    const url = `${this.baseUrl}/api/users/signed-in`;
    return this.http.get<HttpResponse<User>>(url);
  }

  getCompanyTheme(): Observable<HttpResponse<CompanyTheme>> {
    const url = `${this.baseUrl}/api/companies/theme`;
    return this.http.get<CompanyTheme>(url).pipe(
      map((response) => ({
        code: '',
        data: response,
        message: '',
      }))
    );
  }

  getCompanyShortInfo(): Observable<HttpResponse<OrganizationShortInfo>> {
    const url = `${this.baseUrl}/api/companies/info`;
    return this.http.get<HttpResponse<OrganizationShortInfo>>(url);
  }

  changeLanguage(
    systemLanguageId: Localization
  ): Observable<{ isSuccess: boolean }> {
    const url = `${this.baseUrl}/api/users/system-language`;
    return this.http.put<{ isSuccess: boolean }>(url, { systemLanguageId });
  }
}
