@if (loading()) {
  <div class="mt-4 container">
    <p-skeleton width="100%" height="150px" borderRadius="15px" styleClass="mb-4"/>
    <p-skeleton width="100%" height="150px" borderRadius="15px" styleClass="mb-4"/>
    <p-skeleton width="100%" height="150px" borderRadius="15px"/>
  </div>
} @else {
  <div class="container">
    <p-breadcrumb class="max-w-full" [model]="breadcrumbs">
      <ng-template pTemplate="item" let-item>
        @if (item.route) {
          <a [routerLink]="item.route" class="p-menuitem-link no-underline">
            <span [ngClass]="[item.icon ? item.icon : '', 'gray-text']"></span>
            <span class="text-sm light-gray-text">{{ item.label | translate }}</span>
          </a>
        } @else {
          <span class="light-black-text text-sm">{{ item.label | translate }}</span>
        }
      </ng-template>
      <ng-template pTemplate="separator">
        <span class="icon icon-forward"></span>
      </ng-template>
    </p-breadcrumb>
  </div>
  <app-job-opening-list [jobOpenings]="vacancyList()"
                        (changeFollowing)="onChangeFollowing($event)"
                        [jobListType]="jobListTypeEnum.Applied">
  </app-job-opening-list>
}
