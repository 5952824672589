<div class="border-round-sm surface-0 border-round-xl mb-2 p-4 shadow-3">
  <div class="flex justify-content-center align-items-center h-screen">
    <div class="text-center">
      <div>
        <img
          src="assets/private/empty-news-feed.png"
          alt="Image"
          width="230"
          height="230"
          priority
        />
      </div>
      <div class="message-text">
        <h1 class="text-xl">
          {{ "NewsFeedSection.emptyNewsTexts.noMedia" | translate }}
        </h1>
        <p class="text-500">
          {{ "NewsFeedSection.emptyNewsTexts.newsIsComingSoon" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
