<p-dialog
  header="Header"
  [(visible)]="visible"
  modal="modal"
  [closable]="true"
  [style]="{ width: '50vw' }"
  draggable="false"
  (onHide)="closeDialog()"
>
  <div class="flex justify-content-center">
    <p-carousel
      [value]="images()"
      [numVisible]="1"
      [numScroll]="1"
      [circular]="false"
      [responsiveOptions]="responsiveOptions"
    >
      <ng-template let-image pTemplate="item">
        <div class="border-1 surface-border border-round m-2 p-3">
          <div class="mb-3">
            <div class="relative mx-auto">
              <img [src]="image" alt="images" class="w-full border-round" />
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>
  </div>
</p-dialog>
