<div class="border-light-color border-bottom-1 mb-3" *ngIf="sectionSettings()?.isEnabled">
  <div class="sub-title flex justify-content-between align-items-center mt-1 mb-3">
    <span>{{ 'JobOpeningApplyPage.sections.' + sectionSettings().type | translate }}</span>
    <span (click)="onAddExperience()" class="cursor-pointer accent-text flex align-items-center">
       <i class="pi pi-plus text-xs font-semibold mr-1"></i>
       <span>{{ 'Buttons.add' | translate }}</span>
    </span>
  </div>
  <ng-container *ngIf="savedExperiences.length">
    <div class="section-item-card" *ngFor="let experience of savedExperiences; let i = index">
      <div class="item-card-header">
        <div class="titles">
          <div class="secondary-label">
            {{ 'Labels.work_experience' | translate }}&nbsp;{{ i + 1 }}
          </div>
          <div class="title">{{ experience[fieldType.Position] }}</div>
        </div>
        <div class="actions">
          <span class="cursor-pointer" (click)="onEditExperience(i)">
          <i class="pi pi-pen-to-square "></i>
          </span>
          <span class="cursor-pointer" (click)="onDeleteExperience(i)">
          <i class="pi pi-trash"></i>
          </span>
        </div>
      </div>
      <div class="item-card-body">
        <div class="info-row" *ngIf="experience[fieldType.CompanyName]">
          <i class="pi pi-building"></i>
          <div class="secondary-label">{{ experience[fieldType.CompanyName] }}</div>
        </div>
        <div class="info-row" *ngIf="experience[fieldType.WorkExperienceCountry]">
          <i class="pi pi-map-marker"></i>
          <div class="secondary-label">
            <span
              *ngIf="experience[fieldType.WorkExperienceCountry]">{{ experience[fieldType.WorkExperienceCountry].label }}
              <span *ngIf="experience[fieldType.WorkExperienceCity]">,&nbsp;</span>
            </span>
            <span *ngIf="experience[fieldType.WorkExperienceCity]">{{ experience[fieldType.WorkExperienceCity] }}</span>
          </div>
        </div>
        <div class="info-row" *ngIf="experience.dateGroup[fieldType.WorkExperienceStartDate]">
          <i class="pi pi-calendar"></i>
          <div class="secondary-label">
            <span>{{ experience.dateGroup[fieldType.WorkExperienceStartDate] | date: dateFormat() }}</span>
            <span>&nbsp;-&nbsp;</span>
            <span *ngIf="experience.dateGroup[fieldType.WorkExperienceEndDate]; else noEndDate">
              {{ experience.dateGroup[fieldType.WorkExperienceEndDate] | date: dateFormat() }}
            </span>
            <ng-template #noEndDate>
              <span *ngIf="showPresentSwitcher; else na">{{ 'Labels.present' | translate }}</span>
              <ng-template #na>
                <span class="no-data">{{ 'Labels.na' | translate }}</span>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<p-dialog [header]="'Buttons.work_experience_' + (isEdit ? 'edit' : 'add') | translate"
          [draggable]="false"
          (onHide)="onHideAddEditDialog()"
          [(visible)]="showAddEditDialog"
          [style]="{width: '500px'}"
          styleClass="shared-dialog"
          [modal]="true"
          [resizable]="false">
  <div *ngIf="form" [formGroup]="form!">
    <div *ngFor="let field of sectionSettings().fieldSettings; let i = index"
         class="field-row"
         [class.m-r-4-per]="field.type === fieldType.WorkExperienceStartDate"
         [class.half-width]="field.type === fieldType.WorkExperienceStartDate || field.type === fieldType.WorkExperienceEndDate">
      <div [ngSwitch]="field.type"
           class="field-input">
        <ng-container *ngSwitchCase="fieldType.CompanyName">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
              <span *ngIf="form.get(field.type.toString())!.errors?.['maxlength']">
                  {{ 'Validations.company_maxlength' | translate : {value: 100} }}
                </span>
            </div>
          </div>
          <input [formControlName]="field.type.toString()"
                 [placeholder]="'Labels.enter' | translate"
                 [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                 [id]="field.type.toString()">
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.Position">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
              <span *ngIf="form.get(field.type.toString())!.errors?.['maxlength']">
                  {{ 'Validations.position_maxlength' | translate : {value: 100} }}
                </span>
            </div>
          </div>
          <input [formControlName]="field.type.toString()"
                 [placeholder]="'Labels.enter' | translate"
                 [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                 [id]="field.type.toString()">
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.WorkExperienceCountry">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
            </div>
          </div>
          <p-dropdown [options]="countries()"
                      [inputId]="field.type.toString()"
                      [showClear]="true"
                      styleClass="shared-dropdown"
                      [autoOptionFocus]="false"
                      dropdownIcon="icon-arrow-down"
                      panelStyleClass="shared-dropdown-panel"
                      [formControlName]="field.type.toString()"
                      [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                      optionLabel="label"
                      [placeholder]="'Labels.select' | translate"/>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.WorkExperienceCity">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
              <span *ngIf="form.get(field.type.toString())!.errors?.['maxlength']">
                  {{ 'Validations.city_maxlength' | translate : {value: 100} }}
                </span>
            </div>
          </div>
          <input [formControlName]="field.type.toString()"
                 [placeholder]="'Labels.enter' | translate"
                 [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                 [id]="field.type.toString()">
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.WorkExperienceStartDate">
          <div class="messages validation-messages">
            <div
              *ngIf="(form.get('dateGroup')!.get(field.type.toString()))!.errors && ((form.get('dateGroup')!.get(field.type.toString()))!.touched || submitted)">
                <span *ngIf="(form.get('dateGroup')!.get(field.type.toString()))!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
              <span *ngIf="(form.get('dateGroup')!.get(field.type.toString()))!.errors?.['biggerThenCurrent']">
                  {{ 'Validations.less_than_today' | translate }}
                </span>
            </div>
            <div *ngIf="form.get('dateGroup')!.errors && (form.get('dateGroup')!.touched || submitted)">
                <span *ngIf="form.get('dateGroup')!.errors?.['range']">
                  {{ 'Validations.start_date_greater_than_end' | translate }}
                </span>
            </div>
          </div>
          <div formGroupName="dateGroup">
            <p-calendar [defaultDate]="(form.get('dateGroup')!.get(field.type.toString()))?.value"
                        styleClass="shared-datepicker fw"
                        appendTo="body"
                        [placeholder]="dateFormat()"
                        [showClear]="true"
                        [showIcon]="true"
                        [iconDisplay]="'input'"
                        [dateFormat]="dateFormat() | primeDateFormat"
                        [formControlName]="field.type.toString()"
                        [class.invalid]="form.get('dateGroup')!.errors && (form.get('dateGroup')!.touched || submitted)"
                        [ngClass]="{'invalid': (form.get('dateGroup')!.get(field.type.toString()))!.errors && ((form.get('dateGroup')!.get(field.type.toString()))!.touched || submitted)}"
                        [inputId]="field.type.toString()"/>
          </div>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.WorkExperienceEndDate">
          <div class="messages validation-messages">
            <div
              *ngIf="(form.get('dateGroup')!.get(field.type.toString()))!.errors && ((form.get('dateGroup')!.get(field.type.toString()))!.touched || submitted)">
                <span *ngIf="(form.get('dateGroup')!.get(field.type.toString()))!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
              <span *ngIf="(form.get('dateGroup')!.get(field.type.toString()))!.errors?.['biggerThenCurrent']">
                  {{ 'Validations.less_than_today' | translate }}
                </span>
            </div>
          </div>
          <div formGroupName="dateGroup">
            <p-calendar
              [defaultDate]="(form.get('dateGroup')!.get(field.type.toString()))?.value ? (form.get('dateGroup')!.get(field.type.toString()))?.value : (form.get('dateGroup')!.get(fieldType.WorkExperienceStartDate.toString()))?.value"
              styleClass="shared-datepicker fw"
              appendTo="body"
              [placeholder]="dateFormat()"
              [showClear]="true"
              [showIcon]="true"
              [iconDisplay]="'input'"
              [dateFormat]="dateFormat() | primeDateFormat"
              [formControlName]="field.type.toString()"
              [class.invalid]="(form.get('dateGroup')!.get(field.type.toString()))!.errors && ((form.get('dateGroup')!.get(field.type.toString()))!.touched || submitted)"
              [inputId]="field.type.toString()"/>
          </div>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
      </div>
    </div>
    <div class="flex align-items-center" *ngIf="showPresentSwitcher">
      <p-checkbox styleClass="shared-checkbox"
                  [binary]="true"
                  [formControl]="presentSwitcherControl"
                  [label]="'Labels.present' | translate"
                  [value]="presentSwitcherControl.value">
      </p-checkbox>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-center">
      <button class="button-primary w-5 lg" (click)="onSaveExperience()">
        {{ 'Buttons.save' | translate }}
      </button>
    </div>
  </ng-template>
</p-dialog>

<ng-template #labelTemplate let-field>
  <label class="field-label">
    <ng-container *ngIf="field.type === fieldType.WorkExperienceEndDate; else defaultName">
      {{ 'JobOpeningApplyPage.fields.end_date' | translate }}
      <span *ngIf="field.isRequired && !presentSwitcherControl.value" class="required-sign">*</span>
    </ng-container>
    <ng-template #defaultName>
      {{ 'JobOpeningApplyPage.fields.' + field.type | translate }}
      <span *ngIf="field.isRequired" class="required-sign">*</span>
    </ng-template>
  </label>
</ng-template>
