import { ApplicationForm, Vacancy, Survey } from '@shared/interfaces';

export type JobApplyPageState = {
  pageActive: boolean;
  vacancy: Vacancy | null,
  form: ApplicationForm | null,
  survey: Survey | null,
  loading: {
    vacancy: boolean;
    survey: boolean;
    submit: boolean;
  };
};

export const initialJobApplyPageState: JobApplyPageState = {
  pageActive: false,
  vacancy: null,
  form: null,
  survey: null,
  loading: {
    vacancy: false,
    survey: false,
    submit: false,
  },
};
