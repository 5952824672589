import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { JobListTypeEnum } from '@shared/enums';
import { appliedJobsPageFeature } from '@store/features';
import { AppliedJobsPageActions } from '@store/actions';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { JobOpeningListComponent } from '../../components';
import { SharedModule } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-applied-jobs',
  standalone: true,
  imports: [
    BreadcrumbModule,
    JobOpeningListComponent,
    SharedModule,
    SkeletonModule,
    TranslateModule,
    NgClass,
  ],
  templateUrl: './applied-jobs.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppliedJobsPageComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  readonly jobListTypeEnum = JobListTypeEnum;
  loading = this.store.selectSignal(appliedJobsPageFeature.selectLoading);
  vacancyList = this.store.selectSignal(appliedJobsPageFeature.selectVacancyList);
  breadcrumbs = [
    {label: 'JobOpeningsPage.navigation.home', route: '/'},
    {label: 'JobOpeningsPage.navigation.applied-jobs'},
  ];

  ngOnInit(): void {
    this.store.dispatch(AppliedJobsPageActions.getAllAppliedVacancies());
  }

  ngOnDestroy() {
    this.store.dispatch(AppliedJobsPageActions.appliedVacanciesPageDestroy());
  }

  onChangeFollowing(data: {id: number; isFollowing: boolean}) {
    if (data.isFollowing) {
      this.store.dispatch(AppliedJobsPageActions.vacancyUnFollow(data));
    } else {
      this.store.dispatch(AppliedJobsPageActions.vacancyFollow(data));
    }
  }
}
