<p-card class="vacancy-card">
  <ng-template pTemplate="header">
    <div class="flex justify-content-between">
      <div class="flex align-items-center text-md font-semibold line-height-2">
        <div class="mr-2 capitalize font-md">
          {{ vacancy().name }}
        </div>
        <div>
          @if (vacancy().fields[fieldsEnum.ProfessionalLevel]; as profLevel) {
            <span class="chip">{{ profLevel }}</span>
          }
        </div>
      </div>
      <div class="col-2 text-right">
        @if (!vacancy().isApplied && currentUser()) {
          <a (click)="onFollowing($event)">
            <span [ngClass]="vacancy().isFollowing ? 'icon-mark-filled' : 'icon-mark'" class="icon"></span>
          </a>
        } @else {
          @if (currentUser()) {
            <button class="button-primary sm">
              {{ 'Labels.check_progress' | translate }}
            </button>
          }
        }
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="flex pt-3">
      <div class="col-12 lg:col-10 p-0">
        <div class="flex">
          <div class="flex p-0 m-0 font-base text-xs">
            <div class="{{ vacancy().fields[fieldsEnum.Location] ? 'mr-4' : 'mr-0' }} white-space-nowrap font-normal">
              @if ((vacancy().fields[fieldsEnum.Location]); as branchesLocations) {
                <i class="icon icon-location gray-icon mr-1"></i> {{ branchesLocations }}
              }
              @if (vacancy().isApplied) {
                Recruitment Status
              }
            </div>
            <div
              class="{{vacancy().fields[fieldsEnum.ContractType] ? 'mr-4' : 'mr-0' }} white-space-nowrap font-normal">
              @if (vacancy().fields[fieldsEnum.ContractType]; as contractType) {
                <span class="icon icon-job gray-icon mr-1"></span>
                <span>
                    {{ contractType }}
                  </span>
              }
            </div>
            <div
              class="{{ vacancy().fields[fieldsEnum.EmploymentType] ? 'mr-4' : 'mr-0' }} white-space-nowrap font-normal">
              @if (vacancy().fields[fieldsEnum.EmploymentType]; as employmentType) {
                <span class="icon icon-clock gray-icon mr-1"></span>
                <span>{{ employmentType }}</span>
              }
            </div>
            <div class="{{vacancy().fields[fieldsEnum.WorkType] ? 'mr-4' : 'mr-0' }} white-space-nowrap font-normal">
              @if (vacancy().fields[fieldsEnum.WorkType]; as workType) {
                <span class="icon icon-laptop gray-icon mr-1"></span>
                <span>{{ workType }}</span>
              }
            </div>
            <div>
              @if ((vacancy()!.fields[fieldsEnum.SalaryType]! | jsonParse); as salary) {
                @switch (salary.salaryTypeId) {
                  @case (salaryTypeEnum.Negotiable) {
                    <div class="mr-3 white-space-nowrap font-normal">
                      <span class="icon icon-dollar gray-icon mr-1"></span>
                      <span>
                            {{ "Enum_Translations.salaryType.negotiable" | translate }}
                          </span>
                    </div>
                  }
                  @case (salaryTypeEnum.Range) {
                    <div class="mr-3 white-space-nowrap font-normal">
                      <span class="icon icon-dollar gray-icon mr-1"></span>
                      <span>
                            {{ salary.currency.currencySymbol }}{{ salary.salaryFrom }}
                        - {{ salary.currency.currencySymbol }}{{ salary.salaryTo }}
                        </span>
                    </div>
                  }
                  @case (salaryTypeEnum.Fixed) {
                    <div class="white-space-nowrap font-normal">
                      <span class="icon icon-dollar gray-icon mr-1"></span>
                      <span>
                          {{ salary.currency.currencySymbol }}{{ salary.fixedSalary }}
                        </span>
                    </div>
                  }
                }
              }
            </div>
          </div>

          <div class="mr-3 white-space-nowrap font-normal">
            @if (vacancy().isApplied && currentUser()) {
              Applied: 05/16/2024
            }
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-2 lg:text-right">
        <span class="text-xs gray-text">
          @if (vacancy().daysPosted) {
            {{ "PUBLIC_VACANCIES.labels.posted_date"| translate : {days: vacancy().daysPosted} }}
          }
        </span>
      </div>
    </div>
  </ng-template>
</p-card>
