export enum Features {
  App = 'App',
  Auth = 'Auth',
  HomePageComponent = 'HomePage',
  JobOpeningsPage = 'JobOpeningsPage',
  JobDetailPage = 'JobDetailPage',
  JobApply = 'JobApply',
  NewsFeed = 'NewsFeed',
  SavedJobs = 'SavedJobs',
  AppliedJobs = 'AppliedJobs',
  SocialAuthConnect = 'SocialAuthConnect',
  DataList = 'DataList',
}
