import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { Features } from '../features.enum';
import { AppActions } from '../actions';
import { initialAppState } from '../states';

export const appReducer = createReducer(
  initialAppState,
  immerOn(AppActions.getCompanyTheme, (state) => {
    state.themeLoading = true;
  }),
  immerOn(AppActions.getCompanyThemeSuccess, (state, {data}) => {
    state.companyTheme = data;
    state.themeLoading = false;
  }),
  immerOn(AppActions.getCompanyShortInfoSuccess, (state, payload) => {
    state.companyShortInfo = payload;
  }),
  immerOn(AppActions.getCompanyThemeError, (state) => {
    state.themeLoading = false;
  }),
  immerOn(AppActions.changeLanguageSuccess, (state, {systemLanguageId}) => {
    state.systemLanguageId = systemLanguageId;
  }),
);

export const appFeature = createFeature({
  name: Features.App,
  reducer: appReducer,
});
