import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { Features } from '../features.enum';
import { initialDataListState } from '../states';
import { DataListActions } from '../actions';


export const DataListReducer = createReducer(
  initialDataListState,
  immerOn(DataListActions.getSkillsSuccess, (state, {options}) => {
    state.skills = options;
  }),
  immerOn(DataListActions.getSourceTypesSuccess, (state, {options}) => {
    state.sourceTypes = options;
  }),
  immerOn(DataListActions.getGendersSuccess, (state, {options}) => {
    state.genders = options;
  }),
  immerOn(DataListActions.getCountriesSuccess, (state, {options}) => {
    state.countries = options;
  }),
  immerOn(DataListActions.getEducationalDegreesSuccess, (state, {options}) => {
    state.educationalDegrees = options;
  }),
  immerOn(DataListActions.getProficienciesSuccess, (state, {options}) => {
    state.proficiencies = options.map(item => {
      return ({label: item.text, value: Number(item.value)});
    }).sort((a, b) => a.value - b.value);
  }),
  immerOn(DataListActions.getLanguagesSuccess, (state, {options}) => {
    state.languages = options;
  }),
  immerOn(DataListActions.getCurrenciesSuccess, (state, {options}) => {
    state.currencies = options;
  }),
  immerOn(DataListActions.getStatesSuccess, (state, {options}) => {
    state.states = options;
  }),
);

export const dataListFeature = createFeature({
  name: Features.DataList,
  reducer: DataListReducer,
});
