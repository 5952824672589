@if (files.length) {
  <div data-testId="attachment_list_inner" class="list" [class.m-t-0]="isShowOnlyList">
    @for (file of files; track file; let i = $index; let last = $last) {
      <div [class.d-none]="maxVisibleCount && !(i < maxVisibleCount || isExpanded)"
           class="file-list-item-wrapper">
        @if (!maxVisibleCount || i < maxVisibleCount || isExpanded) {
          <app-file-list-item
            data-testId="attachment_list_item"
            class="list_item"
            [file]="file"
            [checkByExtension]="checkByExtension"
            [maxFileSize]="maxFileSize"
            [loading]="last && attachmentLoading"
            [isAllFilesMoreThanMaxTotalSize]="isAllFilesMoreThanMaxTotalSize"
            [fileTooLargeMessage]="fileTooLargeMessage"
            [incorrectFormatMessage]="incorrectFormatMessage"
            [fileFormats]="fileFormats"
            [requiredExtensions]="requiredExtensions"
            [disabled]="disabled"
            [showDeleteButton]="showDeleteButton"
            (removeButtonClick)="removeButtonClick.emit($event)">
          </app-file-list-item>
        }
      </div>
    }
    @if (maxVisibleCount && files.length > maxVisibleCount) {
      <div>
        <a class="new-link show-more"
           [class.d-none]="isExpanded"
           (click)="toggleExpandedState()">
          {{ '+' }}{{ files.length - maxVisibleCount }} {{ 'Labels.More' | translate }}
          <i class="icon-ti-angle-down"></i>
        </a>
        <a class="new-link show-less"
           [class.d-none]="!isExpanded"
           (click)="toggleExpandedState()">
          {{ 'Labels.Less' | translate }}
          <i class="icon-ti-angle-down rotate-180"></i>
        </a>
      </div>
    }
  </div>
}

@if (isAllFilesMoreThanMaxTotalSize) {
  <div class="messages validation-messages">
    {{
      'Validations.files_too_large' | translate :{
        value: allFilesTotalSize < 1 ? 1000 * allFilesTotalSize : allFilesTotalSize,
        unit: allFilesTotalSize < 1 ? 'KB' : 'MB'
      }
    }}
  </div>
}

