import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@shared/interfaces';

@Pipe({
  standalone: true,
  name: 'selectOptionLabel',
})
export class SelectOptionLabelPipe implements PipeTransform {

  transform<T>(value: T, options: SelectOption<T>[]): string|undefined {
    const foundOption = options.find(option => option.value === value);
    return foundOption ? foundOption.label : undefined;
  }
}
