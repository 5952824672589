<p-card>
  <ng-template pTemplate="header">
    <div class="flex justify-content-between align-items-center">
      <div class="font-semibold text-left text-lg">
        {{ "JobSectionInfo.titles.jobOpenings" | translate }}
      </div>
      @if (vacancyList().items.length) {
        <div class="font-normal text-center text-sm">
          <a [routerLink]="['/job-openings']" class="button-trnsp p-0">
            <span class="text-sm accent-text">{{ "JobSectionInfo.buttons.viewAllJobs" | translate }}</span>
            <span class="icon icon-forward font-sm blue-text"></span>
          </a>
        </div>
      }
    </div>
  </ng-template>

  @if (loading().vacanciesLoading) {
    <div class="mt-4">
      <p-skeleton width="100%" height="150px" borderRadius="15px" styleClass="mb-4"/>
      <p-skeleton width="100%" height="150px" borderRadius="15px" styleClass="mb-4"/>
      <p-skeleton width="100%" height="150px" borderRadius="15px"/>
    </div>
  } @else {
    @if (vacancyList().items.length && !loading().vacanciesLoading) {
      <div class="vacancy-card-container">
        @for (job of vacancyList().items; track job) {
          <a [routerLink]="['/job-openings', job.id]" class="no-underline block mb-3">
            <app-vacancy-card
              [vacancy]="job"
              (onChangeFollowing)="onChangeFollowing($event)"/>
          </a>
        }
      </div>
    } @else {
      <div class="flex flex-column align-items-center justify-content-center text-center h-23rem">
        <img ngSrc="assets/private/search-image.png" width="87" height="87" alt="No Job openings"/>
        <div class="mt-2">
          <span class="text-400">
            {{ "JobSectionInfo.noJopOpeningsText" | translate }}
          </span>
        </div>
      </div>
    }
  }
</p-card>
