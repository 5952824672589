<div class="border-light-color border-bottom-1 mb-3" *ngIf="sectionSettings()?.isEnabled">
  <div class="sub-title flex justify-content-between align-items-center mt-1 mb-3">
    <span>{{ 'JobOpeningApplyPage.sections.' + sectionSettings().type | translate }}</span>
    <span (click)="onAddLanguage()" class="cursor-pointer accent-text flex align-items-center">
       <i class="pi pi-plus text-xs font-semibold mr-1"></i>
       <span>{{ 'Buttons.add' | translate }}</span>
    </span>
  </div>
  <div class="section-item-card language-item-card" *ngFor="let language of savedLanguages; let i = index">
    <div class="item-card-header">
      <div class="titles-wrapper">
        <div class="titles">
          <div class="secondary-label">{{ 'Labels.language' | translate }}</div>
          <div class="title">
            <span>{{ language[fieldType.LanguageLanguage].label }}</span>
          </div>
        </div>
        <div class="titles">
          <div class="secondary-label">{{ 'Labels.level' | translate }}</div>
          <div class="title">
            <span>{{ language[fieldType.LanguageLevel].label }}</span>
          </div>
        </div>
      </div>
      <div class="actions">
          <span class="cursor-pointer" (click)="onEditLanguage(i)">
          <i class="pi pi-pen-to-square "></i>
          </span>
        <span class="cursor-pointer" (click)="onDeleteLanguage(i)">
          <i class="pi pi-trash"></i>
          </span>
      </div>
    </div>
  </div>
</div>

<p-dialog [header]="'Buttons.language_' + (isEdit ? 'edit' : 'add') | translate"
          [draggable]="false"
          (onHide)="onHideAddEditDialog()"
          [(visible)]="showAddEditDialog"
          [style]="{width: '500px'}"
          styleClass="shared-dialog"
          [modal]="true"
          [resizable]="false">
  <div [formGroup]="form" *ngIf="form">
    <div *ngFor="let field of sectionSettings().fieldSettings; let i = index" class="field-row">
      <div [ngSwitch]="field.type"
           class="field-input">
        <ng-container *ngSwitchCase="fieldType.LanguageLanguage">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
            </div>
          </div>
          <p-dropdown [options]="languageOptions"
                      [inputId]="field.type.toString()"
                      [showClear]="true"
                      styleClass="shared-dropdown"
                      appendTo="body"
                      [autoOptionFocus]="false"
                      dropdownIcon="icon-arrow-down"
                      panelStyleClass="shared-dropdown-panel"
                      [formControlName]="field.type.toString()"
                      [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                      optionLabel="label"
                      [placeholder]="'Labels.select' | translate"/>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.LanguageLevel">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
            </div>
          </div>
          <p-dropdown [options]="proficiencies()"
                      [inputId]="field.type.toString()"
                      [showClear]="true"
                      styleClass="shared-dropdown"
                      appendTo="body"
                      [autoOptionFocus]="false"
                      dropdownIcon="icon-arrow-down"
                      panelStyleClass="shared-dropdown-panel"
                      [formControlName]="field.type.toString()"
                      [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                      optionLabel="label"
                      [placeholder]="'Labels.select' | translate"/>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.LanguageWriting">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
            </div>
          </div>
          <p-dropdown [options]="proficiencies()"
                      [inputId]="field.type.toString()"
                      [showClear]="true"
                      styleClass="shared-dropdown"
                      appendTo="body"
                      [autoOptionFocus]="false"
                      dropdownIcon="icon-arrow-down"
                      panelStyleClass="shared-dropdown-panel"
                      [formControlName]="field.type.toString()"
                      [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                      optionLabel="label"
                      [placeholder]="'Labels.select' | translate"/>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.LanguageReading">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
            </div>
          </div>
          <p-dropdown [options]="proficiencies()"
                      [inputId]="field.type.toString()"
                      [showClear]="true"
                      styleClass="shared-dropdown"
                      appendTo="body"
                      [autoOptionFocus]="false"
                      dropdownIcon="icon-arrow-down"
                      panelStyleClass="shared-dropdown-panel"
                      [formControlName]="field.type.toString()"
                      [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                      optionLabel="label"
                      [placeholder]="'Labels.select' | translate"/>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.LanguageSpeaking">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
            </div>
          </div>
          <p-dropdown [options]="proficiencies()"
                      [inputId]="field.type.toString()"
                      [showClear]="true"
                      styleClass="shared-dropdown"
                      appendTo="body"
                      [autoOptionFocus]="false"
                      dropdownIcon="icon-arrow-down"
                      panelStyleClass="shared-dropdown-panel"
                      [formControlName]="field.type.toString()"
                      [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                      optionLabel="label"
                      [placeholder]="'Labels.select' | translate"/>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.LanguageListening">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
            </div>
          </div>
          <p-dropdown [options]="proficiencies()"
                      [inputId]="field.type.toString()"
                      [showClear]="true"
                      styleClass="shared-dropdown"
                      appendTo="body"
                      [autoOptionFocus]="false"
                      dropdownIcon="icon-arrow-down"
                      panelStyleClass="shared-dropdown-panel"
                      [formControlName]="field.type.toString()"
                      [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                      optionLabel="label"
                      [placeholder]="'Labels.select' | translate"/>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-center">
      <button class="button-primary w-5 lg" (click)="onSaveLanguage()">
        {{ 'Buttons.save' | translate }}
      </button>
    </div>
  </ng-template>
</p-dialog>

<ng-template #labelTemplate let-field>
  <label class="field-label">
    {{ 'JobOpeningApplyPage.fields.' + field.type | translate }}
    <span *ngIf="field.isRequired" class="required-sign">*</span>
  </label>
</ng-template>
