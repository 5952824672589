export class ListModel<T> {
  page: number = 0;
  pageSize: number = 0;
  totalCount: number = 0;
  pageCount: number = 0;
  items: T[] = [];

  static mock<T>(list: T[]): ListModel<T> {
    const lsm = new ListModel<T>();

    lsm.totalCount = 100;
    lsm.page = 156;
    lsm.items = list;
    lsm.pageCount = 160;
    lsm.pageSize = 156789;

    return lsm;
  }

  static empty<T>(): ListModel<T> {
    const lsm = new ListModel<T>();

    lsm.items = [];

    return lsm;
  }
}
