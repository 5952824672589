import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AvatarModule } from 'primeng/avatar';
import { SkeletonModule } from 'primeng/skeleton';
import { CardModule } from 'primeng/card';

import { VacancyCardComponent } from '../vacancy-card';
import { homePageFeature  } from '@store/features';
import { HomePageActions } from '@store/actions';

@Component({
  selector: 'app-job-openings-column',
  standalone: true,
  imports: [
    RouterLink,
    AvatarModule,
    NgOptimizedImage,
    SkeletonModule,
    TranslateModule,
    CardModule,
    VacancyCardComponent,
  ],
  templateUrl: './job-openings-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobOpeningsColumnComponent {
  private readonly store = inject(Store);
  vacancyList = this.store.selectSignal(homePageFeature.selectVacancyList);
  loading = this.store.selectSignal(homePageFeature.selectLoading);

  onChangeFollowing(data: { id: number; isFollowing: boolean }) {
    if (data.isFollowing) {
      this.store.dispatch(HomePageActions.vacancyUnFollow(data));
    } else {
      this.store.dispatch(HomePageActions.vacancyFollow(data));
    }
  }
}
