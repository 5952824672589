import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { Features } from '../features.enum';
import { initialAuthState } from '../states';
import { AuthActions } from '../actions';

export const authReducer = createReducer(
  initialAuthState,
  immerOn(AuthActions.toggleLoginDialog, (state, {visible}) => {
    state.loginDialogVisible = visible;
  }),
  immerOn(AuthActions.socialLogin, (state) => {
    state.loginDialogVisible = false;
  }),
  immerOn(AuthActions.getCurrentUserSuccess, (state, {data}) => {
    state.currentUser = data;
  }),
  immerOn(AuthActions.logout, (state) => {
    state.currentUser = null;
  }),
);

export const authFeature = createFeature({
  name: Features.Auth,
  reducer: authReducer,
});
