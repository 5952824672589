import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

import { CompanyTheme } from '@shared/interfaces';
import { hexToRgb } from '@shared/functions';
import { COMPANY_THEME } from '@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly document = inject(DOCUMENT);

  setTheme(theme: CompanyTheme): void {
    localStorage.setItem(COMPANY_THEME, JSON.stringify(theme));
    const accentHoverColor = this.adjustColorBrightness(theme.accentColor, -10);
    const rgbAccent = hexToRgb(theme.accentColor);

    this.document.documentElement.style.setProperty(
      '--accent-color',
      theme.accentColor
    );
    this.document.documentElement.style.setProperty(
      '--primary-bg-color',
      theme.primaryColor
    );
    this.document.documentElement.style.setProperty(
      '--accent-hover-color',
      accentHoverColor
    );

    this.document.documentElement.style.setProperty(
      '--accent-color-rgb',
      rgbAccent
    );
    // (this.document.getElementById('favicon') as HTMLLinkElement).href = theme.logoUrl;
  }

  getTheme(): CompanyTheme|null {
    return localStorage.getItem(COMPANY_THEME) ? JSON.parse(localStorage.getItem(COMPANY_THEME)!) : null;
  }

  adjustColorBrightness(hex:string, amount:number) {
    hex = hex.replace(/^#/, '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    r = Math.min(255, Math.max(0, r + amount));
    g = Math.min(255, Math.max(0, g + amount));
    b = Math.min(255, Math.max(0, b + amount));
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  }
}
