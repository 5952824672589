import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { CardModule } from 'primeng/card';

import { authFeature } from '@store/features';

@Component({
  standalone: true,
  selector: 'app-profile-card',
  imports: [AvatarModule, TranslateModule, RouterLink, CardModule],
  templateUrl: './profile-card.component.html',
})
export class ProfileCardComponent {
  private readonly store = inject(Store);
  currentUser = this.store.selectSignal(authFeature.selectCurrentUser);
}
