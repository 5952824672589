import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { NgIf } from '@angular/common';

import { FileHolder } from './components/file-holder';
import { Settings } from '@shared/constants';
import { AttachmentModel } from '@shared/models';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FilesListComponent } from './components/files-list/files-list.component';

@Component({
  standalone: true,
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  imports: [FileInputComponent, FilesListComponent, NgIf],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploaderComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FileUploaderComponent),
    multi: true,
  }]
})
export class FileUploaderComponent implements OnInit, ControlValueAccessor, Validator {
  @Output() removeFileIndexEvent = new EventEmitter<number>();
  @Output() fileChangeEvent = new EventEmitter<AttachmentModel[]>();
  @Input() maxFileSize = 5;
  @Input() allFilesTotalSize = 5;
  @Input() submitted = false;
  @Input() isForCV = false;
  @Input() max!: number;
  @Input() singleFile = false;
  @Input() maxVisibleCount!: number;
  @Input() noFilePlaceholder = 'Labels.choose_file_to_upload';
  @Input() requiredError = 'Validations.required';
  @Input() requiredExtensions: string[] = Settings.documentExtensions;
  @Input() requiredFormats: string[] = Settings.documentFormats;
  @Input() fileTooLargeMessage = '';
  @Input() incorrectFormatMessage = '';
  @Input() maxCountFilesMessage = '';
  @Input() id = '';
  @Input() disableInput = false;
  @Input() showDeleteButton = true;
  @Input() showInput = true;
  @Input() checkByExtension = false;
  @Input() attachmentLoading = false;
  @Input() hideInput = false;
  @Input() required = false;

  _files: FileHolder[] = [];
  inProcess = false;
  downloads: AttachmentModel[] = [];
  _disabled = false;
  isFileEmpty = false;
  private propagateChange = (files: AttachmentModel[]) => {
  };

  ngOnInit() {
    this.defaultSetup();
  }

  onFileUploaded(file: FileHolder) {
    if (file && file.file && file.file.size) {
      const duplicateItem = this._files.find(item => item.file.name === file.file.name && item.file.size === file.file.size);
      if (!duplicateItem) {
        this._files.push(file);
        this.filesChanged();
      }
      this.isFileEmpty = false;
      return;
    }
    this.isFileEmpty = true;
  }

  private handleEvent(obs$: Observable<[]>) {
    if (!this.inProcess) {
      this.inProcess = true;

      obs$.pipe(delay(200))
        .subscribe(() => {
          this.fileChangeEvent.emit(this.downloads);
          this.inProcess = false;
        });
    }
  }

  removeFile(file: FileHolder) {
    const index = this._files.indexOf(file);
    if (!this.singleFile) {
      this._files.splice(index, 1);
    }
    this.filesChanged();
    this.removeFileIndexEvent.emit(index);
  }

  private filesChanged() {
    this.downloads = [];
    this._files.forEach(file => {
      const extension: string = file.file.name.substring(file.file.name.lastIndexOf('.'));
      const download = new AttachmentModel(file.downloadId ?? 0, Array.from(new Uint8Array(file.blob as ArrayBuffer)), file.file.type, '', file.file.name, '', extension, '', null, '', file.file);
      this.downloads.push(download);
    });
    this.propagateChange(this.downloads);
    this.handleEvent(of([]));
  }

  private defaultSetup() {
    if (!this.fileTooLargeMessage) {
      this.fileTooLargeMessage = `An image was too large and was not uploaded. The maximum file size is ${this.maxFileSize} MB.`;
    }
    if (!this.maxCountFilesMessage) {
      this.maxCountFilesMessage = `Max allowable number of attachments is ${this.max}.`;
    }
    this.requiredFormats = this.requiredFormats && this.requiredFormats.length > 0 ? this.requiredFormats : Settings.documentFormats;
  }

  writeValue(downloads: AttachmentModel[]) {
    this._files = this.downloadsToFileArray(downloads);
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  setDisabledState?(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  validate() {
    const error: Record<string, boolean> = {};

    if (this.isFilesCountMoreThanMax) {
      error['maxCountError'] = true;
    }

    if (this.isAnyFileMoreThanMaxFileSize) {
      error['maxSizeError'] = true;
    }
    if (this.isAllFilesMoreThanMaxTotalSize) {
      error['allFilesMaxSizeError'] = true;
    }

    return Object.keys(error).length ? error : null;
  }

  get isFilesCountMoreThanMax(): boolean {
    return this._files.length > this.max;
  }

  get isAnyFileMoreThanMaxFileSize(): boolean {
    return this._files.some(_ => this.isFileMoreThanMaxFileSize(_));
  }

  get isAllFilesMoreThanMaxTotalSize(): boolean {
    const allSize = this._files.reduce((a, b) => a + b.file.size, 0);
    return allSize > this.allFilesTotalSize * 1024 * 1024;
  }

  isFileMoreThanMaxFileSize(file: FileHolder): boolean {
    return file.file.size > this.maxFileSize * 1024 * 1024;
  }

  private downloadsToFileArray(downloads: Array<AttachmentModel>): Array<FileHolder> {
    const filesHolder = new Array<FileHolder>();
    if (downloads && downloads.length) {
      downloads.forEach(download => {
        if (download && download.contentType) {
          const binary = download.downloadBinary ? download.downloadBinary.toString() : '';
          const imageName = download.userFriendlyFileName ? download.userFriendlyFileName + download.extension : download.fileName;
          const file = new File([binary], imageName, {type: download.contentType || ''});
          const pictureUrl = `data:${download.contentType};base64,${download.downloadBinary}`;
          const fileHolder = new FileHolder(pictureUrl, file as File, download.id, download.downloadAccessToken || null);
          filesHolder.push(fileHolder);
        }
      });
    }
    return filesHolder && filesHolder.length ? filesHolder : new Array<FileHolder>();
  }
}

