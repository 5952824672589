import { Localization } from '@shared/enums';
import { SelectOption } from '@shared/interfaces';

export const LanguageOptions: SelectOption[] = [
  {
    label: 'ENG',
    value: Localization['en-US'],
    info: {icon: 'assets/images/flags/eng.png'},
  },
  {
    label: 'ՀԱՅ',
    value: Localization['hy-AM'],
    info: {
      icon: 'assets/images/flags/arm.png',
    },
  },
  {
    label: 'DEU',
    value: Localization['de-DE'],
    info: {icon: 'assets/images/flags/german-flag.svg'},
  },
  // {
  //   label: 'РУС',
  //   value: Localization['ru-RU'],
  //   info: { icon: 'assets/images/flags/rus.png' }
  // },
];
