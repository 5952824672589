<span [formGroup]="form" class="number-input-wrapper" *ngIf="selectedCountry">
    <span class="prefix">
      <span class="mask-dd-wrapper">
        <p-dropdown [options]="countries"
                    formControlName="country"
                    optionLabel="name"
                    [autoOptionFocus]="false"
                    dropdownIcon="icon-arrow-down"
                    [emptyFilterMessage]="'GENERAL_LABEL.no_data' | translate"
                    panelStyleClass="mask-dd-panel"
                    styleClass="mask-dd"
                    [filter]="true"
                    filterBy="name">
          <ng-template pTemplate="selectedItem">
              <div class="selected-country-value" *ngIf="selectedCountry">
                  <img [src]="selectedCountry.flag" class="flag" [alt]="selectedCountry.iso"/>
              </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
              <div class="country-item">
                  <img [src]="country.flag" class="flag" [alt]="country.iso"/>
                  <span>{{ country.name }}<span class="code">{{ country.code }}</span></span>
              </div>
          </ng-template>
        </p-dropdown>
      </span>
      <span class="prefix-code">{{ selectedCountry.code }}</span>
    </span>
    <input type="text"
           [id]="inputId()"
           (blur)="onPhoneInputBlur()"
           [mask]="selectedCountry.mask"
           [class]="'padding-left-' + selectedCountry.code.length"
           [placeholder]="selectedCountry.mask"
           formControlName="phone">
  </span>
