import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'jsonParse',
})
export class JsonParsePipe implements PipeTransform {
  transform(value: string|null) {
    if (typeof value !== 'string') {
      return '';
    }
    try {
      return JSON.parse(value);
    } catch (error) {
      return '';
    }
  }
}
