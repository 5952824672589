import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { CurrencyOption, SelectOption } from '@shared/interfaces';
import { httpErrorProps } from '@shared/functions';


export const DataListActions = createActionGroup({
  source: '[DataList]',
  events: {
    'Get Skills': emptyProps(),
    'Get Skills Success': props<{options: SelectOption[]}>(),
    'Get Skills Error': httpErrorProps(),

    'Get Source Types': emptyProps(),
    'Get Source Types Success': props<{options: SelectOption[]}>(),
    'Get Source Types Error': httpErrorProps(),

    'Get Genders': emptyProps(),
    'Get Genders Success': props<{options: SelectOption[]}>(),
    'Get Genders Error': httpErrorProps(),

    'Get Countries': emptyProps(),
    'Get Countries Success': props<{options: SelectOption[]}>(),
    'Get Countries Error': httpErrorProps(),

    'Get Educational Degrees': emptyProps(),
    'Get Educational Degrees Success': props<{options: SelectOption[]}>(),
    'Get Educational Degrees Error': httpErrorProps(),

    'Get Proficiencies': emptyProps(),
    'Get Proficiencies Success': props<{options: {text: string, value: number}[]}>(),
    'Get Proficiencies Error': httpErrorProps(),

    'Get Languages': emptyProps(),
    'Get Languages Success': props<{options: SelectOption[]}>(),
    'Get Languages Error': httpErrorProps(),

    'Get States': props<{countryId: number}>(),
    'Get States Success': props<{options: SelectOption[]}>(),
    'Get States Error': httpErrorProps(),

    'Get Currencies': emptyProps(),
    'Get Currencies Success': props<{options: CurrencyOption[]}>(),
    'Get Currencies Error': httpErrorProps(),
  },
});
