import { Injectable } from '@angular/core';
import { from, Observable } from "rxjs";
import { Auth, signInWithPopup, FacebookAuthProvider } from "@angular/fire/auth";
import { UserCredential } from "@firebase/auth";

import { ISocialAuthProvider } from "@shared/interfaces";
import { SocialProviderTypeEnum } from "@shared/enums";

@Injectable({
  providedIn: 'root'
})
export class FacebookAuthService implements ISocialAuthProvider {
  readonly type = SocialProviderTypeEnum.Facebook;

  constructor(private firebaseAuth: Auth) { }

  signIn(): Observable<UserCredential> {
    const promise = signInWithPopup(this.firebaseAuth, new FacebookAuthProvider());

    return from(promise);
  }

  signOut() {
    this.firebaseAuth.signOut();
  }
}
