import {AbstractControl} from '@angular/forms';

export class SocialNetWorksValidator {

  public static facebookValidate(c: AbstractControl) {

    const FACEBOOK_REGEXP = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(.?)]/;

    return (c.value && !FACEBOOK_REGEXP.test(c.value)) ? {
      validateFacebook: {
        valid: false
      }
    } : null;
    }

  public static linkedInValidate(c: AbstractControl) {

    const LINKEDIN_REGEXP = /^(https?:\/\/)?(www\.)?linkedin.com\/[a-zA-Z0-9(.?)]/;

    return (c.value && !LINKEDIN_REGEXP.test(c.value)) ? {
      validateLinkedIn: {
        valid: false
      }
    } : null;
  }
  public static twitterValidate(c: AbstractControl) {

    const TWITTER_REGEXP = /^(https?:\/\/)?(www\.)?twitter.com\/[a-zA-Z0-9(.?)]/;

    return (c.value && !TWITTER_REGEXP.test(c.value)) ? {
      validateTwitter: {
        valid: false
      }
    } : null;
  }
}
