import { Component, OnInit, OnChanges, forwardRef, inject, SimpleChanges, input } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator, UntypedFormBuilder, ReactiveFormsModule,
} from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { InputNumberModule } from 'primeng/inputnumber';

import { SalaryType } from '@shared/enums';
import { CurrencyOption, Salary } from '@shared/interfaces';
import { RequiredValidator } from '@shared/validators';

@Component({
  standalone: true,
  selector: 'app-salary-input',
  templateUrl: './salary-input.component.html',
  styleUrls: ['./salary-input.component.scss'],
  imports: [
    ReactiveFormsModule,
    NgIf,
    TranslateModule,
    RadioButtonModule,
    NgClass,
    DropdownModule,
    InputNumberModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SalaryInputComponent),
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SalaryInputComponent),
      multi: true,
    },
  ],
})
export class SalaryInputComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  currencies = input.required<CurrencyOption[]>();
  submitted = input.required<boolean>();
  isRequired = input.required<boolean>();


  private readonly fromBuilder = inject(UntypedFormBuilder);

  salaryTypeEnum = SalaryType;
  form: UntypedFormGroup = this.fromBuilder.group({});
  formReady = false;

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges(change: SimpleChanges) {
    if (change['isRequired']?.currentValue) {
      this.initForm();
      this.form.get('salaryTypeId')!.setValidators([RequiredValidator.validate]);
    }
  }

  writeValue(obj: Salary) {
    if (obj) {
      this.form.get('salaryTypeId')?.patchValue(obj.salaryTypeId);
      this.form.get('currencyId')?.patchValue(obj.currencyId);
      this.form.get('value')?.patchValue(obj.value);
    }
  }

  registerOnChange(fn: (arg0: string) => void) {
    this.form.valueChanges
      .subscribe(value => fn(value));
  }

  registerOnTouched(fn: () => void) {
    this.form.valueChanges
      .subscribe(() => fn());
  }

  setDisabledState?(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  validate() {
    return this.form.invalid ? {invalid: true} : {};
  }

  private initForm() {
    if (!this.form || !this.formReady) {
      this.form = this.fromBuilder.group({
        salaryTypeId: [SalaryType.Negotiable],
        currencyId: [null],
        value: [null],
      });
      this.salaryTypeIdWatcher();
      this.formReady = true;
    }
  }

  private salaryTypeIdWatcher() {
    this.form.get('salaryTypeId')!.valueChanges
      .subscribe(value => {
        const valueControl = this.form.get('value')!;
        const currencyControl = this.form.get('currencyId')!;
        if (value === SalaryType.Negotiable) {
          valueControl.setValidators([]);
          currencyControl.setValidators([]);
        } else {
          valueControl.setValidators([RequiredValidator.validate, Validators.max(9999999999)]);
          currencyControl.setValidators([RequiredValidator.validate]);
        }
        valueControl.updateValueAndValidity();
        currencyControl.updateValueAndValidity();
      });
  }
}

