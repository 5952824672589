<div class="border-light-color border-bottom-1 mb-3" *ngIf="sectionSettings()?.isEnabled">
  <div class="sub-title flex justify-content-between align-items-center mt-1 mb-3">
    <span>{{ 'JobOpeningApplyPage.sections.' + sectionSettings().type | translate }}</span>
    <span (click)="onAddCertificate()" class="cursor-pointer accent-text flex align-items-center">
       <i class="pi pi-plus text-xs font-semibold mr-1"></i>
       <span>{{ 'Buttons.add' | translate }}</span>
    </span>
  </div>
  <ng-container *ngIf="savedCertificates.length;">
    <div class="section-item-card" *ngFor="let certificate of savedCertificates; let i = index">
      <div class="item-card-header">
        <div class="titles">
          <div class="secondary-label">
            {{ 'Labels.certificate' | translate }}&nbsp;{{ i + 1 }}
          </div>
          <div class="title">{{ certificate[fieldType.Title] }}</div>
        </div>
        <div class="actions">
          <span class="cursor-pointer" (click)="onEditCertificate(i)">
          <i class="pi pi-pen-to-square "></i>
          </span>
          <span class="cursor-pointer" (click)="onDeleteCertificate(i)">
          <i class="pi pi-trash"></i>
          </span>
        </div>
      </div>
      <div class="item-card-body">
        <div class="info-row"
             *ngIf="certificate.dateGroup[fieldType.IssuanceDate] || certificate.dateGroup[fieldType.ExpirationDate]">
          <i class="pi pi-calendar"></i>
          <div class="secondary-label">
            <span *ngIf="certificate.dateGroup[fieldType.IssuanceDate]; else na">
              {{ certificate.dateGroup[fieldType.IssuanceDate] | date: dateFormat() }}
            </span>
            <span>&nbsp;-&nbsp;</span>
            <span *ngIf="certificate.dateGroup[fieldType.ExpirationDate]; else na">
              {{ certificate.dateGroup[fieldType.ExpirationDate] | date: dateFormat() }}
            </span>
            <ng-template #na>
              <span class="no-data">{{ 'Labels.na' | translate }}</span>
            </ng-template>
          </div>
        </div>
        <div class="info-row" *ngIf="certificate[fieldType.CertificateAttachments].length">
          <i class="pi pi-file"></i>
          <div class="secondary-label">
            {{ certificate[fieldType.CertificateAttachments].length }}
            {{ 'Labels.attachments' | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<p-dialog [header]="'Buttons.certificate_' + (isEdit ? 'edit' : 'add') | translate"
          [draggable]="false"
          (onHide)="onHideAddEditDialog()"
          [(visible)]="showAddEditDialog"
          [style]="{width: '500px'}"
          styleClass="shared-dialog"
          [modal]="true"
          [resizable]="false">
  <div [formGroup]="form" *ngIf="form">
    <div *ngFor="let field of sectionSettings().fieldSettings; let i = index"
         class="field-row"
         [class.m-r-4-per]="field.type === fieldType.IssuanceDate"
         [class.half-width]="field.type === fieldType.IssuanceDate || field.type === fieldType.ExpirationDate">
      <div [ngSwitch]="field.type"
           class="field-input">
        <ng-container *ngSwitchCase="fieldType.Title">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
              <span *ngIf="form.get(field.type.toString())!.errors?.['maxlength']">
                  {{ 'Validations.title_maxlength' | translate : {value: 100} }}
                </span>
            </div>
          </div>
          <input [formControlName]="field.type.toString()"
                 [placeholder]="'Labels.enter' | translate"
                 [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                 [id]="field.type.toString()">
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.CertifyingAuthority">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
              <span *ngIf="form.get(field.type.toString())!.errors?.['maxlength']">
                  {{ 'Validations.authority_maxlength' | translate : {value: 100} }}
                </span>
            </div>
          </div>
          <input [formControlName]="field.type.toString()"
                 [placeholder]="'Labels.enter' | translate"
                 [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                 [id]="field.type.toString()">
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.CertificateNumber">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                 <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                   {{ 'Validations.required' | translate }}
                 </span>
              <span *ngIf="form.get(field.type.toString())!.errors?.['maxlength']">
                  {{ 'Validations.certificate_number_maxlength' | translate : {value: 100} }}
                </span>
            </div>
          </div>
          <input [formControlName]="field.type.toString()"
                 [placeholder]="'Labels.enter' | translate"
                 [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                 [id]="field.type.toString()">
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.ExamCode">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
              <span *ngIf="form.get(field.type.toString())!.errors?.['maxlength']">
                  {{ 'Validations.exam_code_maxlength' | translate : {value: 100} }}
                </span>
            </div>
          </div>
          <input [formControlName]="field.type.toString()"
                 [placeholder]="'Labels.enter' | translate"
                 [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)"
                 [id]="field.type.toString()">
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.CertificateAttachments">
          <div class="messages validation-messages">
            <div
              *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted)">
                <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
            </div>
          </div>
          <div class="image-uploader-new">
            <app-file-uploader
              [maxFileSize]="5"
              [max]="5"
              [singleFile]="false"
              [id]="field.type.toString()"
              [formControlName]="field.type.toString()"
              [requiredFormats]="fileFormats"
              (fileChangeEvent)="onFileChange($event, field.type.toString())"
              (removeFileIndexEvent)="onRemoveAttachment($event, field.type.toString())"
              [attachmentLoading]="attachmentLoading()"
              [requiredExtensions]="fileExtensions">
            </app-file-uploader>
          </div>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.IssuanceDate">
          <div class="messages validation-messages">
            <div
              *ngIf="(form.get('dateGroup')!.get(field.type.toString()))!.errors && ((form.get('dateGroup')!.get(field.type.toString()))!.touched || submitted)">
                <span *ngIf="(form.get('dateGroup')!.get(field.type.toString()))!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
              <span *ngIf="(form.get('dateGroup')!.get(field.type.toString()))!.errors?.['biggerThenCurrent']">
                  {{ 'Validations.issuance_date_greater_than_current_date' | translate }}
                </span>
            </div>
            <div *ngIf="form.get('dateGroup')!.errors && (form.get('dateGroup')!.touched || submitted)">
                <span *ngIf="form.get('dateGroup')!.errors?.['range']">
                  {{ 'Validations.expiration_greater_issuance' | translate }}
                </span>
            </div>
          </div>
          <div formGroupName="dateGroup">
            <p-calendar [defaultDate]="(form.get('dateGroup')!.get(field.type.toString()))?.value"
                        appendTo="body"
                        styleClass="shared-datepicker fw"
                        [placeholder]="dateFormat()"
                        [showIcon]="true"
                        [iconDisplay]="'input'"
                        [maxDate]="maxAllowedDateForIssuance"
                        [showClear]="true"
                        [dateFormat]="dateFormat() | primeDateFormat"
                        [formControlName]="field.type.toString()"
                        [class.invalid]="form.get('dateGroup')!.errors && (form.get('dateGroup')!.touched || submitted)"
                        [ngClass]="{'invalid': (form.get('dateGroup')!.get(field.type.toString()))!.errors && ((form.get('dateGroup')!.get(field.type.toString()))!.touched || submitted)}"
                        [inputId]="field.type.toString()"/>
          </div>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.ExpirationDate">
          <div class="messages validation-messages">
            <div
              *ngIf="(form.get('dateGroup')!.get(field.type.toString()))!.errors && ((form.get('dateGroup')!.get(field.type.toString()))!.touched || submitted)">
                <span *ngIf="(form.get('dateGroup')!.get(field.type.toString()))!.errors?.['required']">
                  {{ 'Validations.required' | translate }}
                </span>
            </div>
          </div>
          <div formGroupName="dateGroup">
            <p-calendar
              [defaultDate]="(form.get('dateGroup')!.get(field.type.toString()))?.value ? (form.get('dateGroup')!.get(field.type.toString()))?.value : (form.get('dateGroup')!.get(fieldType.IssuanceDate.toString()))?.value"
              appendTo="body"
              styleClass="shared-datepicker fw"
              [placeholder]="dateFormat()"
              [showIcon]="true"
              [iconDisplay]="'input'"
              [showClear]="true"
              [dateFormat]="dateFormat() | primeDateFormat"
              [formControlName]="field.type.toString()"
              [class.invalid]="(form.get('dateGroup')!.get(field.type.toString()))!.errors && ((form.get('dateGroup')!.get(field.type.toString()))!.touched || submitted)"
              [inputId]="field.type.toString()"/>
          </div>
          <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-center">
      <button class="button-primary w-5 lg" [disabled]="attachmentLoading()" (click)="onSaveCertificate()">
        {{ 'Buttons.save' | translate }}
      </button>
    </div>
  </ng-template>
</p-dialog>

<ng-template #labelTemplate let-field>
  <label class="field-label">
    {{ 'JobOpeningApplyPage.fields.' + field.type | translate }}
    <span *ngIf="field.isRequired" class="required-sign">*</span>
  </label>
</ng-template>
