export const mockNewsFeed = [
  {
    id: 1,
    images: [
      'https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg',
      'https://hips.hearstapps.com/hmg-prod/images/nature-quotes-landscape-1648265299.jpg',
      'https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014_640.jpg',
      'https://static.vecteezy.com/system/resources/previews/013/976/430/non_2x/teamwork-concept-group-of-people-climbing-a-mountain-company-employees-working-together-mutual-achievement-of-the-goal-team-building-people-helping-and-supporting-each-other-at-work-vector.jpg',
    ],
    companyLogo:
      'https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg',
    title: 'Company is with Alice Smith and 20 others',
    companyInfo: 'Software Development Company',
    sections: [
      {
        id: 1,
        title:
          'Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML. Your team\'s expertise and professionalism have exceeded our expectations.',
        tags: ['VoloClients', 'HappyClients', 'VoloTeam'],
      },
      {
        id: 2,
        title: 'Helbert Ackehurst, CEO of Helbert & Co',
        tags: [],
      },
    ],
    likedCount: '6.2K',
    viewsCount: '5.8K',
  },
  {
    id: 2,
    images: [
      'https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg',
    ],
    companyLogo:
      'https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg',
    title: 'Company is with Alice Smith and 20 others',
    companyInfo: 'Software Development Company',
    sections: [
      {
        id: 1,
        title:
          'Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML. Your team\'s expertise and professionalism have exceeded our expectations.',
        tags: ['VoloClients', 'HappyClients', 'VoloTeam'],
      },
      {
        id: 2,
        title: 'Helbert Ackehurst, CEO of Helbert & Co',
        tags: [],
      },
    ],
    likedCount: '6.2K',
    viewsCount: '5.8K',
  },
  {
    id: 3,
    images: [],
    companyLogo:
      'https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg',
    title: 'Company is with Alice Smith and 20 others',
    companyInfo: 'Software Development Company',
    sections: [
      {
        id: 1,
        title:
          'Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML. Your team\'s expertise and professionalism have exceeded our expectations.',
        tags: ['VoloClients', 'HappyClients', 'VoloTeam'],
      },
      {
        id: 2,
        title: 'Helbert Ackehurst, CEO of Helbert & Co',
        tags: [],
      },
    ],
    likedCount: '6.2K',
    viewsCount: '5.8K',
  },
  {
    id: 4,
    images: [
      'https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg',
      'https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014_640.jpg',
      'https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg',
    ],
    companyLogo:
      'https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg',
    title: 'Company is with Alice Smith and 20 others',
    companyInfo: 'Software Development Company',
    sections: [
      {
        id: 1,
        title:
          'Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML. Your team\'s expertise and professionalism have exceeded our expectations.',
        tags: ['VoloClients', 'HappyClients', 'VoloTeam'],
      },
      {
        id: 2,
        title: 'Helbert Ackehurst, CEO of Helbert & Co',
        tags: [],
      },
    ],
    likedCount: '6.2K',
    viewsCount: '5.8K',
  },
  {
    id: 4,
    images: [
      'https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg',
      'https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg',
    ],
    companyLogo:
      'https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg',
    title: 'Company is with Alice Smith and 20 others',
    companyInfo: 'Software Development Company',
    sections: [
      {
        id: 1,
        title:
          'Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML. Your team\'s expertise and professionalism have exceeded our expectations.',
        tags: ['VoloClients', 'HappyClients', 'VoloTeam'],
      },
      {
        id: 2,
        title: 'Helbert Ackehurst, CEO of Helbert & Co',
        tags: [],
      },
    ],
    likedCount: '6.2K',
    viewsCount: '5.8K',
  },
  {
    id: 4,
    images: [
      'https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg',
      'https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014_640.jpg',
      'https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg',
      'https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg',
      'https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014_640.jpg',
      'https://primefaces.org/cdn/primeng/images/galleria/galleria10.jpg',
    ],
    companyLogo:
      'https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg',
    title: 'Company is with Alice Smith and 20 others',
    companyInfo: 'Software Development Company',
    sections: [
      {
        id: 1,
        title:
          'Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML Using dangerouslySetInnerHTML. Your team\'s expertise and professionalism have exceeded our expectations.',
        tags: ['VoloClients', 'HappyClients', 'VoloTeam'],
      },
      {
        id: 2,
        title: 'Helbert Ackehurst, CEO of Helbert & Co',
        tags: [],
      },
    ],
    likedCount: '6.2K',
    viewsCount: '5.8K',
  },
];
