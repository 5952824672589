import { AbstractControl } from '@angular/forms';

export class EmailValidator {

  public static validate(c: AbstractControl) {
    const EMAIL_REGEXP = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,20}(?:\.[a-z]{2})?)$/;
    if (c.value) {
      return EMAIL_REGEXP.test(String(c.value).toLowerCase()) ? null : {email: true};
    } else {
      return null;
    }
  }
}
