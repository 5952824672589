import { OrganizationShortInfo } from '../interfaces';


export class AttachmentModel  {
  constructor(public id: number| string,
              public downloadBinary: number[],
              public contentType: string,
              public userFriendlyFileName: string,
              public fileName: string,
              public filePath: string,
              public extension: string,
              public previewGuid?: string,
              public organization?: OrganizationShortInfo | null,
              public downloadAccessToken?: string,
              public file?: File,
              public size?: string,
              ) {
  }
}
