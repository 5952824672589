import { ValidatorErrorMessage } from '@shared/interfaces';


export function validatorErrorMessage(massageKey: string): ValidatorErrorMessage {
  return {
    [massageKey]: {
      valid: false,
    },
  };
}
