import { ListModel } from '@shared/models';
import { Vacancy } from '@shared/interfaces';

export type AppliedJobsPageState = {
  vacancyList: ListModel<Vacancy>;
  loading: boolean;
};

export const initialAppliedJobPageState: AppliedJobsPageState = {
  vacancyList: ListModel.empty<Vacancy>(),
  loading: false,
};
