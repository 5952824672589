import { Component, inject, input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { DatePipe, NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import dayjs from 'dayjs';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { finalize } from 'rxjs';

import { ApplicationFormSectionSetting } from '@shared/interfaces';
import { PrimeDateFormatPipe } from '@shared/pipes';
import { Settings } from '@shared/constants';
import { ApplicationFormAttachmentTypeEnum, ApplicationFormFieldEnum } from '@shared/enums';
import { RequiredValidator } from '@shared/validators';
import { JobApplyService } from '@shared/services';
import { AttachmentModel } from '@shared/models';
import { FileUploaderComponent } from '../../file-uploader';

@Component({
  standalone: true,
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  imports: [
    TranslateModule,
    NgIf,
    DialogModule,
    ReactiveFormsModule,
    NgClass,
    NgForOf,
    NgSwitchCase,
    NgSwitch,
    DatePipe,
    CalendarModule,
    DropdownModule,
    NgTemplateOutlet,
    PrimeDateFormatPipe,
    FileUploaderComponent,
  ],
})
export class CertificatesComponent implements OnChanges {
  sectionSettings = input.required<ApplicationFormSectionSetting>();
  mainForm = input.required<UntypedFormGroup>();
  dateFormat = input.required<string>();

  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly jobApplyService = inject(JobApplyService);


  formArray!: UntypedFormArray;
  form!: UntypedFormGroup;
  savedCertificates: any[] = [];
  showAddEditDialog = false;
  fileFormats = Settings.documentFormats;
  fileExtensions = Settings.documentExtensions;
  submitted = false;
  selectedFormIndex!: number | null;
  isEdit = false;
  fieldType = ApplicationFormFieldEnum;
  attachmentLoading = signal(false);
  maxAllowedDateForIssuance = dayjs().toDate();
  private isSaving = false;
  private originalFormValue: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mainForm']?.currentValue) {
      this.setForm();
    }
  }

  private setForm() {
    this.formArray = this.mainForm().get(this.sectionSettings().id.toString()) as UntypedFormArray;
  }

  onAddCertificate() {
    this.formArray.push(this.formBuilder.group({
      dateGroup: this.formBuilder.group({}, {validator: this.validateDateGroup})
    }));
    this.form = this.formArray.at(this.formArray.length - 1) as UntypedFormGroup;
    this.sectionSettings().fieldSettings.forEach(field => {
      switch (field.type) {
        case ApplicationFormFieldEnum.Title:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, Validators.maxLength(100)] : [Validators.maxLength(100)])
          );
          break;
        case ApplicationFormFieldEnum.CertifyingAuthority:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, Validators.maxLength(100)] : [Validators.maxLength(100)])
          );
          break;
        case ApplicationFormFieldEnum.CertificateNumber:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, Validators.maxLength(100)] : [Validators.maxLength(100)])
          );
          break;
        case ApplicationFormFieldEnum.ExamCode:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, Validators.maxLength(100)] : [Validators.maxLength(100)])
          );
          break;
        case ApplicationFormFieldEnum.CertificateAttachments:
          this.form.addControl(
            field.type.toString(),
            // @ts-ignore
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, this.fileTypeValidation.bind(this)] : [this.fileTypeValidation.bind(this)])
          );
          break;
        case ApplicationFormFieldEnum.IssuanceDate:
          (this.form.get('dateGroup') as UntypedFormGroup).addControl(
            field.type.toString(),
            // @ts-ignore
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, this.biggerThenCurrentDateValidation] : [this.biggerThenCurrentDateValidation])
          );
          break;
        case ApplicationFormFieldEnum.ExpirationDate:
          (this.form.get('dateGroup') as UntypedFormGroup).addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : [])
          );
          break;
      }
    });
    this.selectedFormIndex = this.formArray.length - 1;
    this.isEdit = false;
    this.showAddEditDialog = true;
  }

  onHideAddEditDialog() {
    this.showAddEditDialog = false;
    if (this.savedCertificates.length !== this.formArray.length) {
      this.formArray.removeAt(this.selectedFormIndex!);
    }
    if (this.isEdit && !this.isSaving) {
      this.formArray.at(this.selectedFormIndex!).patchValue(this.originalFormValue);
    }
    this.selectedFormIndex = null;
    this.isSaving = false;
    this.isEdit = false;
    this.submitted = false;
  }

  onSaveCertificate() {
    this.submitted = true;
    if (this.form.valid) {
      if (this.isEdit) {
        this.isSaving = true;
        this.savedCertificates[this.selectedFormIndex!] = this.form.getRawValue();
      } else {
        this.savedCertificates.push(this.form.getRawValue());
      }
      this.selectedFormIndex = null;
      this.showAddEditDialog = false;
    }
  }

  onEditCertificate(index: number) {
    this.form = this.formArray.at(index) as UntypedFormGroup;
    this.originalFormValue = this.form.getRawValue();
    this.selectedFormIndex = index;
    this.isEdit = true;
    this.showAddEditDialog = true;
  }

  onDeleteCertificate(index: number) {
    this.formArray.removeAt(index);
    this.savedCertificates.splice(index, 1);
  }

  onFileChange(files: AttachmentModel[], formControlName: string) {
    if (this.form.get(formControlName)!.valid) {
      files.forEach((file, idx) => {
        if (!file.id) {
          this.uploadAttachment(file, idx, formControlName);
        }
      });
    }
  }

  onRemoveAttachment(removedIndex: number, formControlName: string) {
    const attachments = this.form.get(formControlName)!;
    attachments.value.splice(removedIndex, 1);
    attachments.setValue(attachments.value);
  }

  private validateDateGroup(formGroup: UntypedFormGroup): { [key: string]: boolean } | null {
    const start = (<UntypedFormControl>formGroup.controls[ApplicationFormFieldEnum.IssuanceDate.toString()])?.value;
    const end = (<UntypedFormControl>formGroup.controls[ApplicationFormFieldEnum.ExpirationDate.toString()])?.value;
    if (start && end) {
      const startDateVal = new Date(start).valueOf();
      const endDateVal = new Date(end).valueOf();
      if (endDateVal < startDateVal) {
        return {range: true};
      }
    }
    return null;
  }

  private biggerThenCurrentDateValidation(control: UntypedFormControl): { [key: string]: boolean } | null {
    const date = control.value;
    if (date) {
      const dateVal = new Date(date).valueOf();
      const nowVal = new Date().setHours(0, 0, 0, 0).valueOf();
      if (dateVal >= nowVal) {
        return {biggerThenCurrent: true};
      }
    }
    return null;
  }

  private fileTypeValidation(formGroup: UntypedFormGroup) {
    let result = false;
    if (this.form && formGroup.value) {
      formGroup.value.forEach((item: { contentType: string }) => {
        if (!this.fileFormats.includes(item.contentType)) {
          result = true;
        }
      });
    }
    return result ? {wrongFileFormat: true} : null;
  }

  private uploadAttachment(file: AttachmentModel, fileIndex: number, formControlName: string) {
    this.attachmentLoading.set(true);
    this.jobApplyService.uploadAttachment(file, ApplicationFormAttachmentTypeEnum.Certificate)
      .pipe(finalize(() => this.attachmentLoading.set(false)))
      .subscribe({
        next: (res) => {
          const attachments = this.form.get(formControlName)!;
          if (res && res.data) {
            file.id = res.data;
            attachments.setValue([...attachments.value.slice(0, fileIndex), file]);
          } else {
            attachments.setValue(attachments.value.slice(0, fileIndex));
          }
        },
        error: () => {
          const attachments = this.form.get(formControlName)!;
          attachments.setValue(attachments.value.slice(0, fileIndex));
        },
      });
  }
}
