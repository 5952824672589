import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { RouterLink } from '@angular/router';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';

import { jobOpeningsPageFeature } from '@store/features';
import { JobOpeningsPageActions } from '@store/actions';
import { VacancyListItemComponent, JobOpeningListComponent } from '../../components';
import { JobListTypeEnum } from '@shared/enums';
import { throttle } from '@shared/functions';

@Component({
  selector: 'app-job-openings-page',
  standalone: true,
  imports: [
    RouterLink,
    NgOptimizedImage,
    SkeletonModule,
    TranslateModule,
    VacancyListItemComponent,
    JobOpeningListComponent,
    BreadcrumbModule,
    NgClass,
  ],
  templateUrl: './job-openings.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobOpeningsPageComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private readonly cdRef = inject(ChangeDetectorRef);
  readonly jobListTypeEnum = JobListTypeEnum;
  loading = this.store.selectSignal(jobOpeningsPageFeature.selectLoading);
  vacancyList = this.store.selectSignal(jobOpeningsPageFeature.selectVacancyList);
  setTopBlockShadow = false;
  breadcrumbs = [
    {label: 'JobOpeningsPage.navigation.home', route: '/'},
    {label: 'JobOpeningsPage.navigation.job-openings'},
  ];
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.checkScrollPosition();
  }
  ngOnInit() {
    this.store.dispatch(JobOpeningsPageActions.jobOpeningsPageInit());
  }
  ngOnDestroy() {
    this.store.dispatch(JobOpeningsPageActions.jobOpeningsPageDestroy());
  }

  private checkScrollPosition = throttle(() => {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    this.setTopBlockShadow = scrollPosition > 50;
    this.cdRef.detectChanges();
  }, 200);

  onChangeFollowing(data: {id: number; isFollowing: boolean}) {
    if (data.isFollowing) {
      this.store.dispatch(JobOpeningsPageActions.vacancyUnFollow(data));
    } else {
      this.store.dispatch(JobOpeningsPageActions.vacancyFollow(data));
    }
  }
}
