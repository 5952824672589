import { Component, SimpleChanges, OnChanges, inject, input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  DatePipe,
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';

import { ApplicationFormSectionSetting, SelectOption } from '@shared/interfaces';
import { ApplicationFormFieldEnum } from '@shared/enums';
import { RequiredValidator } from '@shared/validators';

@Component({
  standalone: true,
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  imports: [
    NgIf,
    TranslateModule,
    NgForOf,
    DialogModule,
    ReactiveFormsModule,
    NgSwitch,
    NgSwitchCase,
    NgClass,
    TableModule,
    CalendarModule,
    DatePipe,
    DropdownModule,
    NgTemplateOutlet,
    NgSwitchDefault,
  ],
})
export class LanguagesComponent implements OnChanges {
  sectionSettings = input.required<ApplicationFormSectionSetting>();
  mainForm = input.required<UntypedFormGroup>();
  proficiencies = input.required<SelectOption[]>();
  languages = input.required<SelectOption[]>();

  private readonly formBuilder = inject(UntypedFormBuilder);

  formArray!: UntypedFormArray;
  form!: UntypedFormGroup;
  savedLanguages: any[] = [];
  showAddEditDialog = false;
  submitted = false;
  selectedFormIndex!: number | null;
  isEdit = false;
  fieldType = ApplicationFormFieldEnum;
  private isSaving = false;
  private originalFormValue: string = '';
  languageOptions: SelectOption[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mainForm']?.currentValue) {
      this.setForm();
    }
    if (changes['languages']?.currentValue) {
      this.languageOptions = [...this.languages()];
    }
  }

  private setForm() {
    this.formArray = this.mainForm().get(this.sectionSettings().id.toString()) as UntypedFormArray;
  }

  onAddLanguage() {
    this.formArray.push(this.formBuilder.group({}));
    this.form = this.formArray.at(this.formArray.length - 1) as UntypedFormGroup;
    this.sectionSettings().fieldSettings.forEach(field => {
      switch (field.type) {
        case ApplicationFormFieldEnum.LanguageLanguage:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.LanguageLevel:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.LanguageWriting:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.LanguageReading:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.LanguageSpeaking:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.LanguageListening:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
      }
    });
    this.selectedFormIndex = this.formArray.length - 1;
    this.isEdit = false;
    this.showAddEditDialog = true;
  }

  onHideAddEditDialog() {
    this.showAddEditDialog = false;
    if (this.savedLanguages.length !== this.formArray.length) {
      this.formArray.removeAt(this.selectedFormIndex!);
    }
    if (this.isEdit && !this.isSaving) {
      this.formArray.at(this.selectedFormIndex!).patchValue(this.originalFormValue);
    }
    this.selectedFormIndex = null;
    this.isSaving = false;
    this.isEdit = false;
    this.submitted = false;
  }

  onSaveLanguage() {
    this.submitted = true;
    if (this.form.valid) {
      if (this.isEdit) {
        this.isSaving = true;
        this.savedLanguages[this.selectedFormIndex!] = this.form.getRawValue();
      } else {
        this.savedLanguages.push(this.form.getRawValue());
        this.checkDisabledLanguageOptions();
      }
      this.selectedFormIndex = null;
      this.showAddEditDialog = false;
    }
  }

  onEditLanguage(index: number) {
    this.form = this.formArray.at(index) as UntypedFormGroup;
    this.originalFormValue = this.form.getRawValue();
    this.form.get(this.fieldType.LanguageLanguage.toString())!.disable();
    this.selectedFormIndex = index;
    this.isEdit = true;
    this.showAddEditDialog = true;
  }

  onDeleteLanguage(index: number) {
    this.formArray.removeAt(index);
    this.savedLanguages.splice(index, 1);
    this.checkDisabledLanguageOptions();
  }

  private checkDisabledLanguageOptions() {
    const savedLanguages = this.savedLanguages.map(lang => lang[this.fieldType.LanguageLanguage.toString()].value);
    this.languageOptions = this.languageOptions.map(lang => ({
      ...lang,
      disabled: savedLanguages.includes(lang.value)
    }));
  }
}
