<p-card>
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <div class="justify-content-center align-items-center">
        <p-avatar
          [image]="currentUser()?.picture"
          styleClass="mr-2 overflow-hidden border-white shadow-5 border-double border-2 border-circle"
          size="xlarge"
          shape="circle"
          class="home-page-avatar"
        />
      </div>
      <div class="px-2">
        <div class="text-xl font-semibold line-height-3 fontLexand">
          {{ currentUser()?.fullName }}
        </div>
        <div class="text-sm font-normal gray-text">
          {{ currentUser()?.email }}
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="button-wrapper">
      <a [routerLink]="['/saved-jobs']" class="button-primary-outline">
        <div class="flex align-items-center justify-content-center">
          <div class="px-2">
            <span class="icon icon-mark-filled"></span>
          </div>
          <div class="text-sm ont-normal">
            {{ "Home.savedJobs" | translate }}
          </div>
        </div>
      </a>
      <a [routerLink]="['/applied-jobs']" class="button-primary">
        <div class="flex align-items-center justify-content-center">
          <div class="px-2"><span class="icon icon-work-fill"></span></div>
          <div class="text-sm font-normal">
            {{ "Home.appliedJobs" | translate }}
          </div>
        </div>
      </a>
    </div>
  </ng-template>
</p-card>
