export function copyToClipboard(text: string) {
  const textarea = document.createElement('textarea');
  textarea.style.position = 'fixed';
  textarea.style.left = '0';
  textarea.style.top = '0';
  textarea.style.opacity = '0';
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();

  navigator.clipboard.writeText(text)
    .then(() => {
      document.body.removeChild(textarea);
    });
}
