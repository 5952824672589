import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from 'primeng/image';

import { appFeature, authFeature } from '@store/features';
import { AuthActions } from '@store/actions';
import { SocialProviderTypeEnum } from '@shared/enums';
import { SocialProvidersConst } from '@shared/constants';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-social-login',
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    NgOptimizedImage,
    AsyncPipe,
    ImageModule,
    DialogModule,
  ],
  templateUrl: './social-login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialLoginComponent {
  private readonly store = inject(Store);

  dialogVisible = false;
  readonly socialProviders: SocialProviderTypeEnum[] = SocialProvidersConst;
  companyTheme = this.store.selectSignal(appFeature.selectCompanyTheme);
  loading = this.store.selectSignal(appFeature.selectThemeLoading);
  loginDialogVisible = this.store.selectSignal(authFeature.selectLoginDialogVisible);
  setVisible = effect(() => this.dialogVisible = this.loginDialogVisible());


  onLogin(providerType: SocialProviderTypeEnum) {
    this.store.dispatch(AuthActions.socialLogin({providerType}));
  }

  hideDialog() {
    this.store.dispatch(AuthActions.toggleLoginDialog({visible: false}));
  }
}
