@if (loading()) {
  <div class="mt-4 container">
    <p-skeleton width="100%" height="150px" borderRadius="15px" styleClass="mb-4"/>
    <p-skeleton width="100%" height="150px" borderRadius="15px" styleClass="mb-4"/>
    <p-skeleton width="100%" height="150px" borderRadius="15px"/>
  </div>
} @else {
  <div class="vacancy-details top-fixed-part" [class.shadow]="setTopBlockShadow">
    <div class="breadcrumb-section p-4">
      <div class="container">
        <p-breadcrumb class="max-w-full" [model]="breadcrumbs">
          <ng-template pTemplate="item" let-item>
            @if (item.route) {
              <a [routerLink]="item.route" class="p-menuitem-link no-underline">
                <span [ngClass]="[item.icon ? item.icon : '', 'gray-text']"></span>
                <span class="text-sm light-gray-text">{{ item.label | translate }}</span>
              </a>
            } @else {
              <span class="light-black-text text-sm">{{ item.label | translate }}</span>
            }
          </ng-template>
          <ng-template pTemplate="separator">
            <span class="icon icon-forward"></span>
          </ng-template>
        </p-breadcrumb>
      </div>
    </div>
    <div class="container flex justify-content-center">
      <p class="text-4x light-black-text font-semibold capitalize name">Job Openings</p>
    </div>
  </div>
  <app-job-opening-list [jobOpenings]="vacancyList()"
                        (changeFollowing)="onChangeFollowing($event)"
                        [jobListType]="jobListTypeEnum.All">
  </app-job-opening-list>
}
