import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseHttp } from './base';
import { ApplicationForm, ApplicationFormResponse, HttpResponse, Survey } from '@shared/interfaces';
import { AttachmentModel } from '@shared/models';
import { ApplicationFormAttachmentTypeEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class JobApplyService extends BaseHttp {

  getFormById(id: number): Observable<HttpResponse<ApplicationForm>> {
    const url = `${this.baseUrl}/api/applicationForm/${id}`;
    return this.http.get<HttpResponse<ApplicationForm>>(url);
  }

  getSurveyById(id: number): Observable<HttpResponse<Survey>> {
    const url = `${this.baseUrl}/api/applicationForm/survey/${id}`;
    return this.http.get<HttpResponse<Survey>>(url);
  }

  applyForJob(model: ApplicationFormResponse): Observable<HttpResponse<number>> {
    const url = `${this.baseUrl}/api/applicationForm/response/job-opening`;
    return this.http.post<HttpResponse<number>>(url, model);
  }

  uploadAttachment(attachment: AttachmentModel, type: ApplicationFormAttachmentTypeEnum): Observable<HttpResponse<number>> {
    const url = `${this.baseUrl}/api/ApplicationForm/attachment/${type}`;
    return this.http.post<HttpResponse<number>>(url, attachment);
  }

}
