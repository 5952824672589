<div class="grid mt-3 container">
  @for (job of jobOpenings().items; track job) {
    <div class="col-12 pl-0">
      <a [routerLink]="['/' + jobListType(), job.id]" class="no-underline">
        <app-vacancy-list-item
          [vacancy]="job"
          (onChangeFollowing)="onChangeFollowing($event)"/>
      </a>
    </div>
  }
</div>
@if (!jobOpenings().items.length) {
  <div class="flex flex-column align-items-center justify-content-center text-center h-23rem">
    <div>
      <img ngSrc="assets/private/search-image.png" width="87" height="87" alt="No Job openings"/>
    </div>
    <div class="mt-2">
      <span class="text-400">{{ "JobSectionInfo.noJopOpeningsText" | translate }}</span>
    </div>
  </div>
}
