import { Component, SimpleChanges, OnChanges, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf, NgIf } from '@angular/common';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { SkeletonModule } from 'primeng/skeleton';

import { Survey } from '@shared/interfaces';
import { CustomFieldItemComponent } from '../custom-field-item';

@Component({
  standalone: true,
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  imports: [
    TranslateModule,
    NgIf,
    NgForOf,
    SkeletonModule,
    CustomFieldItemComponent,
  ],
})
export class SurveyComponent implements OnChanges {
  mainForm = input.required<UntypedFormGroup>();
  loading = input.required<boolean>();
  submitted = input.required<boolean>();
  survey = input.required<Survey|null>();

  form!: FormGroup;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['survey']?.currentValue) {
      this.form = this.mainForm().get('survey') as FormGroup;
    }
  }
}
