import { Injectable, Injector, runInInjectionContext } from '@angular/core';
import { Observable } from "rxjs";
import { UserCredential } from "@firebase/auth";

import { ISocialAuthProvider } from "@shared/interfaces";
import { SocialProviderTypeEnum } from "@shared/enums";
import { injectAuthProvider } from "@shared/providers";

@Injectable({
  providedIn: 'root'
})
export class SocialAuthService {

  private providerStrategy!: ISocialAuthProvider;

  constructor(private injector: Injector) { }

  setProviderStrategy(providerType: SocialProviderTypeEnum): void {
    runInInjectionContext(this.injector, () => {
      this.providerStrategy = injectAuthProvider(providerType);
    });
  }

  firebaseSignIn(): Observable<UserCredential> {
    return this.providerStrategy.signIn();
  }

  firebaseSignOut(): void {
    this.providerStrategy.signOut();
  }
}
