import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@Component({
  standalone: true,
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  imports: [BreadcrumbModule, NgClass, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPageComponent {
  items = [
    {
      label: 'SettingsPage.navigation.home',
      route: '/',
    },
    {
      label: 'SettingsPage.navigation.settings',
    },
  ];
}
