import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ListModel } from '@shared/models';
import { Vacancy } from '@shared/interfaces';
import { httpErrorProps } from '@shared/functions';

export const JobOpeningsPageActions = createActionGroup({
  source: '[JobOpeningsPage]',
  events: {
    'Job Openings Page init': emptyProps(),
    'Job Openings Page Destroy': emptyProps(),

    'Get Paged Vacancies': props<{ page: number; size: number }>(),
    'Get All Vacancies': emptyProps(),
    'Get Vacancies Success': props<ListModel<Vacancy>>(),
    'Get Vacancies Error': httpErrorProps(),

    'Vacancy Follow': props<{ id: number }>(),
    'Vacancy Follow Success': props<{ id: number}>(),
    'Vacancy Follow Error': httpErrorProps(),

    'Vacancy UnFollow': props<{ id: number }>(),
    'Vacancy UnFollow Success': props<{id: number}>(),
    'Vacancy UnFollow Error': httpErrorProps(),
  },
});
