import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ListModel } from '@shared/models';
import { Vacancy } from '@shared/interfaces';
import { httpErrorProps } from '@shared/functions';

export const AppliedJobsPageActions = createActionGroup({
  source: '[AppliedJobs]',
  events: {
    'Applied Vacancies Page init': emptyProps(),
    'Applied Vacancies Page Destroy': emptyProps(),

    'Get All Applied Vacancies': emptyProps(),
    'Get All Applied Vacancies Success': props<ListModel<Vacancy>>(),
    'Get All Applied Vacancies Error': httpErrorProps(),

    'Vacancy Follow': props<{id: number}>(),
    'Vacancy Follow Success': props<{id: number}>(),
    'Vacancy Follow Error': httpErrorProps(),

    'Vacancy UnFollow': props<{id: number}>(),
    'Vacancy UnFollow Success': props<{id: number}>(),
    'Vacancy UnFollow Error': httpErrorProps(),
  },
});
