import { CurrencyOption, SelectOption } from '@shared/interfaces';

export type DataListState = {
  skills: SelectOption[],
  sourceTypes: SelectOption[],
  genders: SelectOption[],
  countries: SelectOption[],
  educationalDegrees: SelectOption[],
  proficiencies: SelectOption[],
  languages: SelectOption[],
  states: SelectOption[],
  currencies: CurrencyOption[],
};

export const initialDataListState: DataListState = {
  skills: [],
  sourceTypes: [],
  genders: [],
  countries: [],
  states: [],
  educationalDegrees: [],
  proficiencies: [],
  languages: [],
  currencies: [],
};
