import { createActionGroup, props } from "@ngrx/store";

import { httpErrorProps, httpSuccessProps } from '@shared/functions';
import { NewsFeed } from '@shared/interfaces';


export const NewsFeedActions = createActionGroup({
  source: '[NessFeed]',
  events: {
    'Get Data': props<{ page: number, size:number }>(),
    'Get Data Success': httpSuccessProps<NewsFeed[]>(),
    'Get Data Error': httpErrorProps(),
  },
});
