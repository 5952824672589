export enum NavBarItemEnum {
  Jobs = 1,
  About = 2,
  Notifications = 3,
  User = 4,
  SavedJobs = 5,
  AppliedJobs = 6,
  Settings = 7,
  SignOut = 8,
  Language = 9,
  SignIn = 10,
}
