import { createFeature, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

import { Features } from '../features.enum';
import { initialHomePageState } from '../states';
import { HomePageActions } from '../actions';
import { PublicSettingsFieldsEnum } from '@shared/enums';
import { ListModel } from '@shared/models';
import { Vacancy } from '@shared/interfaces';


export const homePageReducer = createReducer(
  initialHomePageState,
  immerOn(HomePageActions.getPagedVacancies, (state) => {
    state.loading.vacanciesLoading = true;
  }),
  immerOn(HomePageActions.getPagedVacanciesSuccess, (state, payload) => {
      const mappedVacancies = payload.items.map((item) => {
        const openingDate = item.fields[PublicSettingsFieldsEnum.OpeningDate];
        const daysPosted = openingDate ? Math.floor((new Date().getTime() - new Date(openingDate).getTime()) / (1000 * 60 * 60 * 24))
          : null;
        return ({...item, daysPosted});
      });
      state.vacancyList = {...payload, items: mappedVacancies};
      state.loading.vacanciesLoading = false;
    }
  ),
  immerOn(HomePageActions.getPagedVacanciesError, (state) => {
    state.loading.vacanciesLoading = false;
  }),
  immerOn(
    HomePageActions.vacancyFollowSuccess,
    HomePageActions.vacancyUnFollowSuccess,
    (state, payload) => {
      state.vacancyList.items = state.vacancyList.items.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            isFollowing: !item.isFollowing,
          };
        }
        return item;
      });
    }
  ),
  immerOn(HomePageActions.getNewsFeedSuccess, (state, payload) => {
    state.newsFeed = payload;
  }),
  immerOn(HomePageActions.homePageComponentDestroy, (state) => {
    state.vacancyList = ListModel.empty<Vacancy>();
  }),
);

export const homePageFeature = createFeature({
  name: Features.HomePageComponent,
  reducer: homePageReducer,
});
