import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {

  transform(size: number, decimals: number = 2): string {
    if (size === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    const fileSize = parseFloat((size / Math.pow(k, i)).toFixed(decimals));

    return `${fileSize} ${sizes[i]}`;
  }

}
