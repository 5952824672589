import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { dataListFeature } from '@store/features';
import { DataListActions } from '@store/actions';

@Component({
  standalone: true,
  selector: 'app-state-input',
  templateUrl: './state-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StateInputComponent),
    multi: true,
  }],
  imports: [
    DropdownModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateInputComponent implements OnChanges, ControlValueAccessor {
  countryId = input.required<number>();
  clearable = input.required<boolean>();
  invalid = input.required<boolean>();
  placeholder = input.required<string>();

  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly store = inject(Store);
  states = this.store.selectSignal(dataListFeature.selectStates);

  form: UntypedFormGroup = this.formBuilder.group({state: null});
  onTouched!: () => void;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['countryId']?.currentValue) {
      if (changes['countryId']?.previousValue != null && changes['countryId']?.previousValue != changes['countryId']?.currentValue) {
        this.form.get('state')!.reset();
      }
      this.store.dispatch(DataListActions.getStates({countryId: this.countryId()}));
    }
  }

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(state: number): void {
    if (state && state !== this.form.get('state')!.value) {
      this.form.get('state')!.patchValue(state);
    }
  }

  registerOnChange(fn: (value: number) => void): void {
    this.form.get('state')!.valueChanges.subscribe(() => {
      fn(this.form.get('state')!.value);
    });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
