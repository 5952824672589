export enum PublicSettingsFieldsEnum {
  OpeningDate = '10',
  ExpirationDate = '20',
  Position = '30',
  ProfessionalLevel = '40',
  Location = '50',
  ContractType = '60',
  EmploymentType = '70',
  SalaryType = '80',
  Project = '90',
  Skills = '100',
  Attachments = '110',
  JODescription = '120',
  WorkType = '130',
}
