import { NewsFeed, Vacancy } from '@shared/interfaces';
import { ListModel } from '@shared/models';

export type HomePageState = {
  newsFeed: ListModel<NewsFeed>;
  vacancyList: ListModel<Vacancy>;
  loading: {
    vacanciesLoading: boolean;
    newsFeedLoading: boolean;
  };
};

export const initialHomePageState: HomePageState = {
  newsFeed: ListModel.empty<NewsFeed>(),
  vacancyList: ListModel.empty<Vacancy>(),
  loading: {
    vacanciesLoading: false,
    newsFeedLoading: false,
  },
};
