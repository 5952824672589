import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseHttp } from './base';
import { CurrencyOption, HttpResponse, SelectOption } from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DataListService extends BaseHttp {

  getSkills(): Observable<HttpResponse<SelectOption[]>> {
    const url = `${this.baseUrl}/api/applicationForm/technologies`;
    return this.http.get<HttpResponse<SelectOption[]>>(url);
    // return of(mockSkills);
  }

  getSourceTypes(): Observable<HttpResponse<SelectOption[]>> {
    const url = `${this.baseUrl}/api/applicationForm/applicantSources/external`;
    return this.http.get<HttpResponse<SelectOption[]>>(url);
    // return of(mockSourceTypes);

  }

  getGenders(): Observable<HttpResponse<SelectOption[]>> {
    const url = `${this.baseUrl}/api/applicationForm/genders`;
    return this.http.get<HttpResponse<SelectOption[]>>(url);
    // return of(mockGenders);
  }

  getCountries(): Observable<HttpResponse<SelectOption[]>> {
    const url = `${this.baseUrl}/api/ApplicationForm/countries`;
    return this.http.get<HttpResponse<SelectOption[]>>(url);
    // return of(mockCountries);
  }

  getEducationalDegrees(): Observable<HttpResponse<SelectOption[]>> {
    const url = `${this.baseUrl}/api/ApplicationForm/educationaldegree`;
    return this.http.get<HttpResponse<SelectOption[]>>(url);
    // return of(mockEducationalDegrees);
  }

  getProficiencies(): Observable<{text: string, value: number}[]> {
    const url = `${this.baseUrl}/api/ApplicationForm/values/enum/ProficienciesEnum`;
    return this.http.get<{text: string, value: number}[]>(url);
    // return of(mockProficiencies);
  }

  getLanguages(): Observable<HttpResponse<SelectOption[]>> {
    const url = `${this.baseUrl}/api/ApplicationForm/languages`;
    return this.http.get<HttpResponse<SelectOption[]>>(url);
    // return of(mockLanguages);
  }

  getStates(countryId :number): Observable<HttpResponse<SelectOption[]>> {
    const url = `${this.baseUrl}/api/ApplicationForm/states?countryId=${countryId}`;
    return this.http.get<HttpResponse<SelectOption[]>>(url);
    // return of(mockLanguages);
  }

  getCurrencies(): Observable<HttpResponse<CurrencyOption[]>> {
    const url = `${this.baseUrl}/api/ApplicationForm/currencies`;
    return this.http.get<HttpResponse<CurrencyOption[]>>(url);
    // return of(mockCurrencies);
  }
}
