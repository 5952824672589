import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activityContent',
  standalone: true,
})
export class ActivityContentPipe implements PipeTransform {

  transform(content: string, isRequired: boolean): string {
    if (isRequired) {
      return content + '<span class="red-text"> *</span>';
    }

    return content;
  }
}
