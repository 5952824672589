import { ChangeDetectionStrategy, Component, OnInit, inject, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import { JobOpeningsColumnComponent, NewsFeedComponent, ProfileCardComponent } from '../../components';
import { HomePageActions } from '@store/actions';
import { authFeature } from '@store/features';

@Component({
  standalone: true,
  selector: 'app-home',
  templateUrl: './home.page.html',
  imports: [
    NewsFeedComponent,
    JobOpeningsColumnComponent,
    ProfileCardComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  currentUser = this.store.selectSignal(authFeature.selectCurrentUser);

  ngOnInit() {
    this.store.dispatch(HomePageActions.homePageComponentInit());
  }

  ngOnDestroy() {
    this.store.dispatch(HomePageActions.homePageComponentDestroy());
  }
}
