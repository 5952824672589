@if (!loading() && survey()) {
  <div class="survey-answer-card" *ngIf="form">
    <ng-container *ngFor="let activity of survey()!.surveyActivities">
      <app-custom-field-item
        [submitted]="submitted()"
        [form]="form"
        [activityName]="activity.name"
        [customField]="activity.surveyActivityFlows[0].surveyActivityItems[0]">
      </app-custom-field-item>
    </ng-container>
  </div>
} @else {
  <div class="mt-4 container">
    <p-skeleton width="100%" height="50px" borderRadius="15px" styleClass="mb-4"></p-skeleton>
    <p-skeleton width="100%" height="50px" borderRadius="15px" styleClass="mb-4"></p-skeleton>
    <p-skeleton width="100%" height="50px" borderRadius="15px"></p-skeleton>
  </div>
}
