import { Injectable } from '@angular/core';
import { Auth, signInWithCustomToken } from "@angular/fire/auth";
import { from, Observable } from "rxjs";
import { UserCredential } from "@firebase/auth";

import { SocialProviderTypeEnum } from "@shared/enums";
import { ISocialAuthProvider } from "@shared/interfaces";

@Injectable({
  providedIn: 'root'
})
export class InstagramAuthService implements ISocialAuthProvider {
  readonly type = SocialProviderTypeEnum.Instagram;

  constructor(private firebaseAuth: Auth) { }

  signIn(): Observable<UserCredential> {
    const promise = signInWithCustomToken(this.firebaseAuth, 'CUSTOM_INSTAGRAM_TOKEN');

    return from(promise);
  }

  signOut() {
    this.firebaseAuth.signOut();
  }
}
