<div [formGroup]="form" *ngIf="sectionSettings()?.isEnabled && form">
  <div *ngFor="let field of sectionSettings().fieldSettings; let i = index"
       class="field-row">
    <div [ngSwitch]="field.type" class="field-input">
      <ng-container *ngSwitchCase="fieldType.FirstName">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors!['maxlength']">
              {{ 'Validations.first_maxlength' | translate : {value: 30} }}
            </span>
          </div>
        </div>
        <input [formControlName]="field.type.toString()"
               [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
               [placeholder]="'Labels.enter' | translate"
               [id]="field.type.toString()">
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.LastName">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors!['maxlength']">
              {{ 'Validations.last_maxlength' | translate : {value: 30} }}
            </span>
          </div>
        </div>
        <input [id]="field.type.toString()"
               [placeholder]="'Labels.enter' | translate"
               [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
               [formControlName]="field.type.toString()">
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.Email">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors!['email']">
              {{ 'Validations.incorrect_format' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors!['maxlength']">
              {{ 'Validations.email_maxlength' | translate : {value: 100} }}
            </span>
          </div>
        </div>
        <input type="email"
               [id]="field.type.toString()"
               [placeholder]="'Labels.enter' | translate"
               [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
               [formControlName]="field.type.toString()">
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.MiddleName">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors!['maxlength']">
              {{ 'Validations.middle_maxlength' | translate : {value: 30} }}
            </span>
          </div>
        </div>
        <input [id]="field.type.toString()"
               [placeholder]="'Labels.enter' | translate"
               [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
               [formControlName]="field.type.toString()">
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.DateOfBirth">
        <div class="messages validation-messages">
          <div
            *ngIf="(form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())) || showAgeWarning">
            <span *ngIf="form.get(field.type.toString())!.errors?.['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors?.['date']">
              {{ 'Validations.greater_than_today' | translate }}
            </span>
            <span class="text-warning d-block" *ngIf="showAgeWarning">
              {{ 'Validations.age_less_16' | translate }}
            </span>
          </div>
        </div>
        <p-calendar [defaultDate]="form.get(field.type.toString())?.value"
                    styleClass="shared-datepicker"
                    [placeholder]="dateFormat()"
                    [showIcon]="true"
                    [iconDisplay]="'input'"
                    [showClear]="true"
                    appendTo="body"
                    [dateFormat]="dateFormat() | primeDateFormat"
                    [formControlName]="field.type.toString()"
                    [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
                    [inputId]="field.type.toString()"/>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.Gender">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
          </div>
        </div>
        <p-dropdown [options]="genders()"
                    [inputId]="field.type.toString()"
                    [showClear]="true"
                    styleClass="shared-dropdown"
                    [autoOptionFocus]="false"
                    dropdownIcon="icon-arrow-down"
                    panelStyleClass="shared-dropdown-panel"
                    [formControlName]="field.type.toString()"
                    [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
                    optionLabel="label"
                    [placeholder]="'Labels.select' | translate"/>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.CV">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
              <span *ngIf="form.get(field.type.toString())!.errors!['required']">
                {{ 'Validations.required' | translate }}
              </span>
          </div>
        </div>
        <div class="image-uploader-new">
          <app-file-uploader
            [maxFileSize]="5"
            [max]="1"
            [singleFile]="true"
            [isForCV]="true"
            [id]="field.type.toString()"
            [formControlName]="field.type.toString()"
            [requiredFormats]="fileFormats"
            (fileChangeEvent)="onFileChange($event, field.type.toString(), attachmentType.CV, i)"
            [attachmentLoading]="attachmentsLoadings[i]"
            (removeFileIndexEvent)="onRemoveAttachment($event, field.type.toString())"
            [requiredExtensions]="fileExtensions">
          </app-file-uploader>
        </div>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.CustomAttachment">
        <div class="messages validation-messages">
          <div *ngIf="form.controls[field.name!].errors && (form.controls[field.name!].touched || submitted())">
              <span *ngIf="form.controls[field.name!!].errors!['required']">
                {{ 'Validations.no_file_selected' | translate }}
              </span>
          </div>
        </div>
        <div class="image-uploader-new">
          <app-file-uploader
            [maxFileSize]="5"
            [max]="1"
            [singleFile]="true"
            [id]="field.name!"
            [formControlName]="field.name!"
            [requiredFormats]="fileFormats"
            (fileChangeEvent)="onFileChange($event, field.name!, attachmentType.CustomAttachment, i)"
            (removeFileIndexEvent)="onRemoveAttachment($event, field.name!)"
            [attachmentLoading]="attachmentsLoadings[i]"
            [requiredExtensions]="fileExtensions">
          </app-file-uploader>
        </div>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.Skills">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
          </div>
        </div>
        <p-autoComplete [formControlName]="field.type.toString()"
                        [suggestions]="filteredSkills"
                        styleClass="shared-multiple-autocomplete"
                        panelStyleClass="shared-autocomplete-panel"
                        [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
                        [multiple]="true"
                        [placeholder]="'Labels.write_a_skill' | translate"
                        [inputId]="field.type.toString()"
                        (completeMethod)="filterSkills($event)"/>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.Source">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
          </div>
        </div>
        <p-dropdown [options]="sourceTypes()"
                    [inputId]="field.type.toString()"
                    [showClear]="true"
                    styleClass="shared-dropdown"
                    [autoOptionFocus]="false"
                    dropdownIcon="icon-arrow-down"
                    panelStyleClass="shared-dropdown-panel"
                    [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
                    [formControlName]="field.type.toString()"
                    optionLabel="label"
                    [placeholder]="'Labels.select' | translate"/>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.Phone">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors!['mask']">
              {{ 'Validations.incorrect_format' | translate }}
            </span>
          </div>
        </div>
        <app-phone-by-country-mask [inputId]="field.type.toString()"
                                   [isRequired]="field.isRequired"
                                   [defaultCountryCode]="'+374'"
                                   [formControlName]="field.type.toString()">
        </app-phone-by-country-mask>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.SocialServiceNumber">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors!['maxlength']">
              {{ 'Validations.max_length' | translate : {value: 50} }}
            </span>
          </div>
        </div>
        <input [id]="field.type.toString()"
               [placeholder]="'Labels.enter' | translate"
               [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
               [formControlName]="field.type.toString()">
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.Attachments">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
              <span *ngIf="form.get(field.type.toString())!.errors!['required']">
                {{ 'Validations.required' | translate }}
              </span>
          </div>
        </div>
        <div class="image-uploader-new">
          <app-file-uploader
            [maxFileSize]="5"
            [max]="1"
            [singleFile]="false"
            [id]="field.type.toString()"
            [formControlName]="field.type.toString()"
            [requiredFormats]="fileFormats"
            (fileChangeEvent)="onFileChange($event, field.type.toString(), attachmentType.Attachment, i)"
            (removeFileIndexEvent)="onRemoveAttachment($event, field.type.toString())"
            [attachmentLoading]="attachmentsLoadings[i]"
            [requiredExtensions]="fileExtensions">
          </app-file-uploader>
        </div>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.Notes">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors!['maxlength']">
              {{ 'Validations.note_maxlength' | translate : {value: 1000} }}
            </span>
          </div>
        </div>
        <textarea [id]="field.type.toString()" rows="5"
                  [placeholder]="'Labels.enter' | translate"
                  [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
                  [formControlName]="field.type.toString()">
        </textarea>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.Salary">
        <app-salary-input [formControlName]="field.type.toString()"
                          [submitted]="submitted()"
                          [currencies]="currencies()"
                          [isRequired]="field.isRequired">
        </app-salary-input>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.LinkedIn">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors!['validateLinkedIn']">
              {{ 'Validations.incorrect_format' | translate }}
            </span>
          </div>
        </div>
        <i class="pi pi-link"></i>
        <input [id]="field.type.toString()"
               class="with-prefix-icon"
               [placeholder]="'Labels.add_link' | translate"
               [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
               [formControlName]="field.type.toString()">
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.Facebook">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
            <span *ngIf="form.get(field.type.toString())!.errors!['validateFacebook']">
              {{ 'Validations.incorrect_format' | translate }}
            </span>
          </div>
        </div>
        <input [id]="field.type.toString()"
               class="with-prefix-icon"
               [placeholder]="'Labels.add_link' | translate"
               [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
               [formControlName]="field.type.toString()">
        <i class="pi pi-link"></i>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
      <ng-container *ngSwitchCase="fieldType.Skype">
        <div class="messages validation-messages">
          <div
            *ngIf="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())">
            <span *ngIf="form.get(field.type.toString())!.errors!['required']">
              {{ 'Validations.required' | translate }}
            </span>
          </div>
        </div>
        <input [id]="field.type.toString()"
               class="with-prefix-icon"
               [placeholder]="'Labels.add_link' | translate"
               [class.invalid]="form.get(field.type.toString())!.errors && (form.get(field.type.toString())!.touched || submitted())"
               [formControlName]="field.type.toString()">
        <i class="pi pi-link"></i>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: field }"/>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #labelTemplate let-field>
  <label class="field-label ">
    <ng-container *ngIf="!field.isSystem; else systemLabel">
      {{ field.name }}:<span *ngIf="field.isRequired" class="required-sign">*</span>
    </ng-container>
    <ng-template #systemLabel>
      {{ 'JobOpeningApplyPage.fields.' + field.type | translate }}
      <span *ngIf="field.isRequired" class="required-sign">*</span>
      <ng-container *ngIf="field.type === fieldType.Source">
        <i class="pi pi-info-circle"
           tooltipPosition="bottom"
           [pTooltip]="'JobOpeningApplyPage.messages.source_info' | translate">
        </i>
      </ng-container>
    </ng-template>
  </label>
</ng-template>
