import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { Component, inject, input, OnChanges, output, SimpleChanges } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';

import { ApplicationFormSectionSetting, CurrencyOption, SelectOption } from '@shared/interfaces';
import { ApplicationFormAttachmentTypeEnum, ApplicationFormFieldEnum } from '@shared/enums';
import { BirthdayValidator, EmailValidator, RequiredValidator, SocialNetWorksValidator } from '@shared/validators';
import { PrimeDateFormatPipe, SelectOptionLabelPipe } from '@shared/pipes';
import { PhoneByCountryMaskComponent, SalaryInputComponent } from '@shared/components';
import { Settings } from '@shared/constants';
import { AttachmentModel } from '@shared/models';
import { finalize } from 'rxjs';
import { JobApplyService } from '@shared/services';
import { FileUploaderComponent } from '../../file-uploader';

@Component({
  standalone: true,
  selector: 'app-main-fields-section',
  templateUrl: './main-fields-section.component.html',
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    NgSwitch,
    NgSwitchCase,
    TranslateModule,
    NgTemplateOutlet,
    CalendarModule,
    NgClass,
    DropdownModule,
    AutoCompleteModule,
    TooltipModule,
    PrimeDateFormatPipe,
    PhoneByCountryMaskComponent,
    SalaryInputComponent,
    SelectOptionLabelPipe,
    FileUploaderComponent,
  ],
})
export class MainFieldsSectionComponent implements OnChanges {
  attachmentLoading = output<boolean>();
  submitted = input.required<boolean>();
  isCustomSection = input.required<boolean>();
  dateFormat = input.required<string>();
  skills = input.required<SelectOption[]>();
  currencies = input.required<CurrencyOption[]>();
  sourceTypes = input.required<SelectOption[]>();
  genders = input.required<SelectOption[]>();
  mainForm = input.required<UntypedFormGroup>();
  sectionSettings = input.required<ApplicationFormSectionSetting>();
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly jobApplyService = inject(JobApplyService);

  form!: UntypedFormGroup;
  fieldType = ApplicationFormFieldEnum;
  attachmentType = ApplicationFormAttachmentTypeEnum;
  fileFormats = Settings.documentFormats;
  fileExtensions = Settings.documentExtensions;
  attachmentsLoadings: boolean[] = [];
  filteredSkills: SelectOption[] = [];
  showAgeWarning = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mainForm']?.currentValue) {
      this.setForm();
    }
  }

  private setForm() {
    this.form = this.mainForm().get(this.sectionSettings().id.toString()) as UntypedFormGroup;
    this.sectionSettings().fieldSettings.forEach((field, index) => {
      switch (field.type) {
        case ApplicationFormFieldEnum.FirstName:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', [RequiredValidator.validate, Validators.maxLength(30)]),
          );
          break;
        case ApplicationFormFieldEnum.LastName:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', [RequiredValidator.validate, Validators.maxLength(30)]),
          );
          break;
        case ApplicationFormFieldEnum.Email:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', [RequiredValidator.validate, Validators.maxLength(100), EmailValidator.validate]),
          );
          break;
        case ApplicationFormFieldEnum.MiddleName:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, Validators.maxLength(30)] : [Validators.maxLength(30)]),
          );
          break;
        case ApplicationFormFieldEnum.DateOfBirth:
          this.form.addControl(
            field.type.toString(),
            // @ts-ignore
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, BirthdayValidator.futureDateValidate, this.adulthoodValidate.bind(this)] : [BirthdayValidator.futureDateValidate, this.adulthoodValidate.bind(this)]),
          );
          break;
        case ApplicationFormFieldEnum.Gender:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.CV:
          this.attachmentsLoadings[index] = false;
          this.form.addControl(
            field.type.toString(),
            // @ts-ignore
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, this.fileTypeValidation.bind(this)] : [this.fileTypeValidation.bind(this)]),
          );
          break;
        case ApplicationFormFieldEnum.Skills:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.Source:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.Phone:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.SocialServiceNumber:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, Validators.maxLength(50)] : [Validators.maxLength(50)]),
          );
          break;
        case ApplicationFormFieldEnum.Attachments:
          this.attachmentsLoadings[index] = false;
          this.form.addControl(
            field.type.toString(),
            // @ts-ignore
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, this.fileTypeValidation.bind(this)] : [this.fileTypeValidation.bind(this)]),
          );
          break;
        case ApplicationFormFieldEnum.Notes:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, Validators.maxLength(1000)] : [Validators.maxLength(1000)]),
          );
          break;
        case ApplicationFormFieldEnum.Salary:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control({salaryTypeId: 10}, field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.LinkedIn:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, SocialNetWorksValidator.linkedInValidate] : [SocialNetWorksValidator.linkedInValidate]),
          );
          break;
        case ApplicationFormFieldEnum.Facebook:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate, SocialNetWorksValidator.facebookValidate] : [SocialNetWorksValidator.facebookValidate]),
          );
          break;
        case ApplicationFormFieldEnum.Skype:
          this.form.addControl(
            field.type.toString(),
            this.formBuilder.control('', field.isRequired ? [RequiredValidator.validate] : []),
          );
          break;
        case ApplicationFormFieldEnum.CustomAttachment:
          this.attachmentsLoadings[index] = false;
          this.form?.addControl(
            field.name!.toString(),
            // @ts-ignore
            this.formBuilder.control(null, field.isRequired ? [RequiredValidator.validate, this.fileTypeValidation.bind(this)] : [this.fileTypeValidation.bind(this)]),
          );
          break;
      }
    });
  }

  private adulthoodValidate(c: UntypedFormControl) {
    const result = BirthdayValidator.adulthoodValidate(c);
    this.showAgeWarning = result !== null;
    return null;
  }

  private fileTypeValidation(formGroup: UntypedFormGroup): {wrongFileFormat: boolean}|null {
    let result = false;
    if (this.mainForm() && formGroup.value && !!formGroup.value.length) {
      formGroup.value.forEach((item: {contentType: string;}) => {
        if (item && !this.fileFormats.includes(item.contentType)) {
          result = true;
        }
      });
    }
    return result ? {wrongFileFormat: true} : null;
  }

  onFileChange(files: AttachmentModel[], formControlName: string, attachmentType: ApplicationFormAttachmentTypeEnum, index: number) {
    if (this.form.controls[formControlName].valid) {
      files.forEach((file, fileIndex) => {
        if (!file.id) {
          this.uploadAttachment(file, fileIndex, formControlName, attachmentType, index);
        }
      });
    }
  }

  onRemoveAttachment(removedIndex: number, formControlName: string) {
    const attachments = this.form.controls[formControlName];
    attachments.value.splice(removedIndex, 1);
    attachments.setValue(attachments.value);
  }

  private uploadAttachment(file: AttachmentModel, fileIndex: number, formControlName: string, attachmentType: ApplicationFormAttachmentTypeEnum, fieldIndex: number) {
    this.attachmentsLoadings[fieldIndex] = true;
    this.attachmentLoading.emit(true);
    this.jobApplyService.uploadAttachment(file, attachmentType)
      .pipe(finalize(() => {
        this.attachmentsLoadings[fieldIndex] = false;
        this.attachmentLoading.emit(this.attachmentsLoadings.some(loading => loading));
      }))
      .subscribe({
        next: (res) => {
          const attachments = this.form.controls[formControlName];
          if (res && res.data) {
            file.id = res.data;
            attachments.setValue([...attachments.value.slice(0, fileIndex), file]);
          } else {
            attachments.setValue(attachments.value.slice(0, fileIndex));
          }
        },
        error: () => {
          const attachments = this.form.controls[formControlName];
          attachments.setValue(attachments.value.slice(0, fileIndex));
        },
      });
  }

  filterSkills($event: AutoCompleteCompleteEvent) {
    const query = $event.query;
    this.filteredSkills = this.skills().filter(skill => skill.label!.toLowerCase().includes(query.toLowerCase()));
  }

  removeSkill(skill: SelectOption) {
    const skills = this.form!.controls[ApplicationFormFieldEnum.Skills.toString()].value;
    skills.splice(skills.indexOf(skill), 1);
    this.form.controls[ApplicationFormFieldEnum.Skills.toString()].setValue(skills);
  }
}
